<div class="form-group">
  <label class="control-label col-form-label-sm">{{label}}</label>
  <div class="input-group input-group-sm">
    <input class="form-control {{ (errorText !== '' ? 'is-invalid' : '') }}" placeholder="{{placeHolder}}"
      [minDate]="minDate" [maxDate]="maxDate" name="dp" [(ngModel)]="model" ngbDatepicker (ngModelChange)="change()"
      #d="ngbDatepicker" readonly="readonly" [disabled]="disabled">
    <div class="input-group-append">
      <button class="btn btn-outline-secondary btn-sm" (click)="d.toggle()" type="button"><i
          class="fa fa-calendar"></i></button>
    </div>
    <div class="invalid-feedback" [hidden]="errorText === ''">
      {{errorText | translate}}
    </div>
  </div>
  <small class="form-text text-muted" [hidden]="helpText === ''">
    {{helpText}}
  </small>
</div>
