import { HttpHeaders } from '@angular/common/http';
import { LOCAL_STORAGE } from '../core/constants';

export class HttpUtils {

  public static createHeaders(): HttpHeaders {
    const accessToken = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
    return new HttpHeaders({
      'X-Access-Token': accessToken
    });
  }
}
