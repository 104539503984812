import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';

export type DataFiltersButtonType = 'primary' | 'danger' | 'default' | 'success' | 'info' | 'warning';

@Component({
  selector: 'app-data-filter-button',
  templateUrl: './data-filter-button.component.html',
  styleUrls: ['./data-filter-button.component.css']
})
export class DataFilterButtonComponent implements OnInit {

  private _toRight = true;

  @Input()
  get toRight() {
    return this._toRight;
  }

  set toRight(value: boolean) {
    this._toRight  = value;
    this.pullRight = this._toRight;
    this.pullLeft  = !this._toRight;
  }

  @Input()
  labelKey: string;

  @Input()
  type: DataFiltersButtonType = 'default';

  @Output()
  onClick = new EventEmitter<void>();

  @HostBinding('class.pull-right')
  pullRight = true;

  @HostBinding('class.pull-left')
  pullLeft = false;

  constructor() { }

  ngOnInit() {
  }

  buttonClicked() {
    this.onClick.emit();
  }


}
