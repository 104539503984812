import { PrinterType } from './PrinterType';
import { Event } from './Event';

export class RequestedPrinterType {
    public id: number;
    public quantity: number;
    public parentRequestId: number;
    public printerType: PrinterType;
    public event: Event;

    static fromDto(r): RequestedPrinterType {
        const requestedPrinterType = new RequestedPrinterType();

        if (!r) {
            return null;
        }

        requestedPrinterType.id = r.id;
        requestedPrinterType.quantity = r.quantity;
        requestedPrinterType.parentRequestId = r.parentRequestId;
        requestedPrinterType.event = r.event;
        requestedPrinterType.printerType = r.printerType;

        return r;
    }
}
