import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-time-range-select',
  templateUrl: './time-range-select.component.html',
  styleUrls: ['./time-range-select.component.css']
})
export class TimeRangeSelectComponent implements OnInit, OnChanges {

  private _timeFormat = 'hh:mm';
  private _formatStartTime;
  private _formatEndTime;
  public timeRange = new Array();

  @Input()
  label: string;

  @Input()
  model: any;

  @Input()
  disabled = false;

  @Input()
  startTime = '09:00';

  @Input()
  endTime = '16:30';

  @Output()
  modelChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled && this.disabled) {
      this.model = undefined;
      this.change();
    }

    this.timeRange = new Array();

    this._formatStartTime = moment(this.startTime, this._timeFormat);
    this._formatEndTime = moment(this.endTime, this._timeFormat);

    while (this._formatStartTime <= this._formatEndTime) {
      this.timeRange.push(moment(this._formatStartTime, this._timeFormat).format('HH:mm').toString());
      this._formatStartTime = moment(this._formatStartTime, this._timeFormat).add('30', 'minute');
    }
  }

  change() {
    this.modelChange.emit(this.model);
  }

}
