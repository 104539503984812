import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataFiltersService {

  private resetEmitter = new Subject<void>();
  public resetObservable = this.resetEmitter.asObservable();

  private applyEmitter = new Subject<void>();
  public applyObservable = this.applyEmitter.asObservable();

  constructor() { }

  triggerReset() {
    this.resetEmitter.next();
  }


  triggerApply() {
    this.applyEmitter.next();
  }
}


