import { Event } from './Event';
import { DeviceType } from './DeviceType';

export class RequestedDevice {
    public id: number;
    public quantity: number;
    public parentRequestId: number;
    public event: Event;
    public deviceTypes: DeviceType;

    static fromDto(r): RequestedDevice {

        const requestedDevice = new RequestedDevice();

        if (!r) {
            return null;
        }

        requestedDevice.id = r.id;
        requestedDevice.quantity = r.quantity;
        requestedDevice.parentRequestId = r.parentRequestId;
        requestedDevice.event = r.event;
        requestedDevice.deviceTypes = r.deviceTypes;


        return r;
    }
}
