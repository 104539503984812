import { Routes } from '@angular/router';
import { AuthGuard } from '../core/guards/auth.guard';
import { AccountComponent } from './account.component';
import { DetailsComponent } from './details/details.component';

export const ACCOUNT_ROUTES: Routes = [
  {
    path: 'account', component: AccountComponent, canActivate: [AuthGuard], children: [
      { path: '', component: DetailsComponent}
    ]
  }
];
