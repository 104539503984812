import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LOCAL_STORAGE } from 'src/app/core/constants';
import { JwtHelperService } from '@auth0/angular-jwt';
import { EventService } from '../shared/event.service';
import { Event } from 'src/app/shared/models/Event';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.css']
})
export class EventDetailsComponent implements OnInit {

  public event: Event = new Event();
  public activeSubMenu = 'advertising';
  public disableDeviceMenuItem = false;
  public warningMissingPriceList = false;
  public warningMissingCategoryPrinterAssign = false;
  public disableMissingCategoryPrinterAssign = false;
  public isEventFinish = false;
  public currentLastRouteParam: string;
  public enableSubMenu = true;
  public enableEventHeader = true;
  public enableEventFooter = true;

  constructor(private _route: ActivatedRoute, private _jwtHelper: JwtHelperService, private _eventService: EventService,
    private _router: Router) { }

  ngOnInit() {
    const accessToken = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
    const tokenData = this._jwtHelper.decodeToken(accessToken).data;

    this._eventService.currentActiveSubPage.subscribe(result => {
      this.activeSubMenu = result;
    });
    this._route.params.subscribe(params => {
      const routeArray = this._router.url.split('/');
      this.currentLastRouteParam = routeArray[routeArray.length - 1];
      forkJoin([
        this._eventService.getEventForClient(params['eventId'], tokenData.id),
        this._eventService.getPrintersCategoriesByEventId(params['eventId'])
      ]).subscribe({
        next: (result) => {
          this.event = result[0];
          this.event.printersCategories = result[1];
          this._eventService.updateEventObject(this.event);

          const momentEndDate = moment(this.event.endDate);
          this.event.endDate = moment(this.event.endDate).subtract(1, 'day').format('YYYY-MM-DD');
          const today = moment();
          if (this.event.status === 2) {
            if (today > momentEndDate) {
              this.isEventFinish = true;
              this._eventService.updateEventFinished(true);
            }
          }
          if (this.event.requestedPrinterTypes.length === 0 && this.event.requestedRouterTypes.length === 0) {
            if (this.event.deviceUsers.length === 0) {
              this.disableDeviceMenuItem = true;
            }
          } else {
            const requestedPrinters = this._getRequestedQuantity(this.event.requestedPrinterTypes);
            if (this.event.deviceUsers.length === 0 || this.event.printers.length < requestedPrinters) {
              this.disableDeviceMenuItem = true;
            }
          }
          if (this.event.priceLists.length === 0) {
            this.warningMissingPriceList = true;
          }
          if (this.event.printersCategories.length === 0 && this.event.requestedSoftwareTypes.filter(s => s.softwareType.id === 4 || s.softwareType.id === 5).length > 0) {
            this.warningMissingCategoryPrinterAssign = true;
          }
          if (this.event.priceLists.length === 0 ||
            this.event.printers.length < this._getRequestedQuantity(this.event.requestedPrinterTypes)) {
            this.disableMissingCategoryPrinterAssign = true;
          }

          if (this.isEventFinish) {
            // this._router.navigate(['/event/details/' + params['eventId'] + '/statistics']);
          }
        }, error: (error) => {
          if (this.activeSubMenu !== 'advertising') {
            return this._router.navigate(['/event/my-events']);
          } else {
            this.enableSubMenu = false;
            this.enableEventHeader = false;
            this.enableEventFooter = false;
          }
        }
      });

      this._eventService.currentWarningMissingPriceList.subscribe(result => {
        this.warningMissingPriceList = result;
        if (this.event.priceLists.length > 0 && this.event.printers.length > 0) {
          this.disableMissingCategoryPrinterAssign = false;
        }
      });
      this._eventService.currentWarningMissingCategoryPrinterAssign.subscribe(result => {
        this.warningMissingCategoryPrinterAssign = result;
      });
    });
  }

  private _getRequestedQuantity(objectArray: any) {
    let amount = 0;
    objectArray.map(function (object) {
      amount += object.quantity;
    });
    return amount;
  }

  print() {
    // window.print();
    this.printElem('container-print-deliverypaper1', 'container-print-deliverypaper2');
  }

  printElem(elem1, elem2) {
    const mywindow = window.open('', '', 'height=768,width=1024');

    mywindow.document.write('<html><head>');
    mywindow.document.write(document.getElementsByTagName('head')[0].innerHTML);
    mywindow.document.write('</head><body style="background-color:white !important;"><div style="padding:40px 20px;">');
    mywindow.document.write(document.getElementById(elem1).innerHTML);
    mywindow.document.write(document.getElementById(elem2).innerHTML);
    mywindow.document.write('</div></body></html>');

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    setInterval(() => {
      mywindow.print();
      mywindow.close();
    }, 2000);


    return true;
  }
}
