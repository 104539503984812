import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-order-options',
  templateUrl: './order-options.component.html',
  styleUrls: ['./order-options.component.css']
})
export class OrderOptionsComponent {
  @Input() orderOptions: any;

  @Input() eventCurrency: string;
}
