<div class="form-group">
  <label class="control-label">{{label}}</label>
  <div class="input-group input-group-sm">
    <div class="input-group-prepend">
      <button class="btn btn-outline-secondary" type="button" (click)="changeValueClickEvent(true)"> - </button>
    </div>
    <input type="number" class="form-control {{ (errorText !== '' ? 'is-invalid' : '') }}" style="text-align: center"
      [(ngModel)]="model" disabled="disabled">
    <div class="input-group-prepend">
      <button class="btn btn-outline-secondary" type="button" id="button-addon1" (click)="changeValueClickEvent(false)">
        + </button>
    </div>
  </div>
</div>