<div class="wrapper">
  <div class="sidebar">
      <app-sidebar></app-sidebar>
  </div>
  <div class="main-panel">
      <app-top-navbar title="{{'pricelists.myPriceLists.myPriceLists' | translate}}"></app-top-navbar>
      <div class="main-content container-fluid">
        <div class="row">
          <div class="col-12">
              <div class="card">
                  <div class="card-body">
                      <div class="card-title">
                          <h4 style="margin-top: 0px;">{{'pricelists.myPriceLists.myPriceLists' | translate}}
                          </h4>
                      </div>
                      <div class="table-responsive">
                          <table class="table">
                              <thead>
                                  <tr>
                                      <th scope="col">{{'pricelists.myPriceLists.eventName' | translate}}</th>
                                      <th scope="col">{{'pricelists.myPriceLists.startDate' | translate}}</th>
                                      <th scope="col">{{'pricelists.myPriceLists.endDate' | translate}}</th>
                                      <th scope="col">{{'pricelists.myPriceLists.priceListName' | translate}}</th>
                                      <th scope="col">{{'pricelists.myPriceLists.creationDate' | translate}}</th>
                                      <th scope="col"></th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <ng-container *ngIf="allPriceLists; else emptyAllPriceListsTemplate">
                                      <tr *ngFor="let priceList of allPriceLists">
                                          <td>{{priceList.event?.eventName ? priceList.event.eventName : ''}}</td>
                                          <td>{{priceList.event?.startDate ? (priceList.event.startDate | date:'dd-MM-yyyy') : ''}}</td>
                                          <td>{{priceList.event?.endDate ? (priceList.event.endDate | date:'dd-MM-yyyy') : ''}}</td>
                                          <td>{{priceList.name}}</td>
                                          <td style="white-space: nowrap;">{{priceList.createdAt | date:'dd-MM-yyyy HH:mm:ss'}}</td>
                                          <td class="text-right">
                                            <div class="btn-group btn-group-sm" role="group" fxLayout="row" fxLayoutAlign="space-around start">
                                              <a href="price-list/preview/{{priceList.id}}" target="_blank" class="btn btn-link" style="color:#57c6f2;"><i
                                                  class="fa fa-eye"></i></a>&nbsp;&nbsp;
                                              <button class="btn btn-link" style="color:green;" (click)="exportPriceList(priceList.id, priceList.name)"><i
                                                  class="fa fa-file-excel"></i></button>
                                            </div>
                                          </td>
                                      </tr>
                                  </ng-container>
                                  <ng-template #emptyAllPriceListsTemplate>
                                      <tr>
                                          <td colspan="6" class="text-center">
                                              {{'price-list.dashboard.noPriceListsErrorMsg' | translate}}
                                          </td>
                                      </tr>
                                  </ng-template>

                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      </div>
  </div>
</div>
