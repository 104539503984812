<ng-template #confirmDialogModel let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{modalTitle}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{modalContent}}</p>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="decisionClickAction(false)">{{denyText}}</button>
    <button type="button" class="btn btn-success" (click)="decisionClickAction(true)">{{acceptText}}</button>
  </div>
</ng-template>
