import { ProductCategory } from './ProductCategory';
import { ProductOption } from './ProductOption';
import { PriceList } from './PriceList';

export class Product {
    public id: number;
    public name: string;
    public extraInfo: string;
    public price: number;
    public stockPerDay: number;
    public status: number;
    public productCategory: ProductCategory;
    public productOptions: ProductOption[] = [];
    public priceList: PriceList;
    public itemOrder: number;

    static fromDto(p): Product {
        const product = new Product();

        if (!p) {
            return null;
        }

        product.id = p.id;
        product.name = p.name;
        if (p.extraInfo) {
          product.extraInfo = p.extraInfo;
        } else {
          p.extraInfo = '';
        }
        product.price = p.price;
        product.stockPerDay = p.stockPerDay;
        product.status = p.status;
        product.productCategory = p.productCategory;
        product.productOptions = p.productOptions;
        product.priceList = p.priceList;
        product.itemOrder = p.itemOrder;

        return product;
    }
}
