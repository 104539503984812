import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth.service';
import { NavigationService } from 'src/app/shared/navigation.service';

@Component({
  selector: 'app-register-confirm',
  templateUrl: './register-confirm.component.html',
  styleUrls: ['./register-confirm.component.css']
})
export class RegisterConfirmComponent implements OnInit {

  constructor(private _authService: AuthService, private _navigation: NavigationService) {
  }

  ngOnInit() {
    if (this._authService.isAuthenticated()) {
      return this._navigation.home().go();
    }
  }

}
