import { Product } from './Product';

export class ProductOption {
    public id: number;
    public name: string;
    public price: number;
    public extraInfo: string;
    public status: number;
    public product: Product = new Product();
    public itemOrder: number;

    static fromDto(p): ProductOption {
        const productOption = new ProductOption();

        if (!p) {
            return null;
        }

        productOption.id = p.id;
        productOption.name = p.name;
        productOption.price = p.price;
        if (p.extraInfo) {
          productOption.extraInfo = p.extraInfo;
        } else {
          p.extraInfo = '';
        }
        productOption.status = p.status;
        productOption.product = p.product;
        productOption.itemOrder = p.itemOrder;

        return productOption;
    }
}
