<form [formGroup]="productOptionForm" (ngSubmit)="onProductOptionSubmit()" class="needs-validation form-row">
    <div class="col">
        <div class="form-group" [formGroup]="productOptionForm">
            <label class="control-label col-form-label-sm">{{'price-list.manage.optionName' | translate}}</label>
            <div class="input-group input-group-sm">
                <input type="text" class="form-control {{productOptionErrorArray['name'] ? 'is-invalid' : ''}}"
                    placeholder="{{'price-list.manage.productName' | translate}}" name="name" id="name"
                    [required]="true" formControlName="name" />
                <div class="input-group-append">
                    <span class="input-group-text"> {{(productOptionForm.controls['name'].value ?
                        (productOptionForm.controls['name'].value.length + productOptionForm.controls['extraInfo'].value.length) :
                        0)}} /
                        32</span>
                </div>
                <div class="invalid-feedback">
                    {{productOptionErrorArray['name'] | translate}}
                </div>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="form-group" [formGroup]="productOptionForm">
            <label class="control-label col-form-label-sm">{{'price-list.manage.extraInfo' | translate}}</label>
            <div class="input-group input-group-sm">
                <input type="text" class="form-control {{productOptionErrorArray['extraInfo'] ? 'is-invalid' : ''}}"
                    placeholder="{{'price-list.manage.extraInfo' | translate}}" name="extraInfo" id="extraInfo"
                    formControlName="extraInfo">
                <div class="input-group-append">
                    <span class="input-group-text"> {{(productOptionForm.controls['name'].value ?
                        (productOptionForm.controls['name'].value.length + productOptionForm.controls['extraInfo'].value.length) :
                        0)}} /
                        32</span>
                </div>
                <div class="invalid-feedback">
                    {{productOptionErrorArray['extraInfo'] | translate}}
                </div>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="form-group" [formGroup]="productOptionForm">
            <label class="control-label col-form-label-sm">{{'price-list.manage.price' | translate}}</label>
            <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">€</span>
                </div>
                <input type="text" class="form-control {{productOptionErrorArray['price'] ? 'is-invalid' : ''}}"
                    placeholder="00.00" name="price" id="price" [required]="true" formControlName="price">
                <div class="invalid-feedback">
                    {{productOptionErrorArray['price'] | translate}}
                </div>
            </div>
        </div>
    </div>
    <div class="col-auto text-right">
        <button type="submit" class="btn btn-primary btn-sm" style="margin-top: 27px;" [disabled]="disableAddButton">{{'price-list.manage.add' |
            translate}}</button>
    </div>
</form>
