<div class="container">
    <div class="row">
        <div class="col-sm-9 col-md-7 col-lg-6 mx-auto">
            <div class="text-center mt-5 mb-3">
                <img class="logo" src="/assets/Famulus_logo_circle_new.png" alt="Famulus" height="100">
            </div>
            <div class="card card-signin">
                <div class="card-body">
                    <div class="card-title text-center">
                        <h4 class="m-0">{{'recover.passwordRecovery' | translate}}</h4>
                    </div>
                    <span class="form-text text-muted mb-3">
                        {{'recover.passwordRecoveryDesc' | translate}}
                    </span>
                    <form [formGroup]="recoveryForm" (ngSubmit)="onRecovery()" class="needs-validation">
                        <app-form-group-input inputType="email" label="{{'login.email' | translate}} *" name="email"
                            id="email" placeHolder="{{'login.email' | translate}}" [required]="true"
                            formControlNameValue="email" [error]="errorArray['email']" [formGroup]="recoveryForm"
                            ngDefaultControl>
                        </app-form-group-input>
                        <div class="text-center mt-4">
                            <button class="btn btn-primary btn-signin btn-block btn-sm"
                                type="submit">{{'recover.recover' | translate}}</button>
                            <div class="text-center my-4">
                                <a href="/login" class="recover-password">{{'login.login-btn' | translate}}</a>
                            </div>
                        </div>
                    </form>
                    <p class="text-muted text-center mt-5" style="margin-bottom: 0px;"><small>Copyright
                            RR-Solutions</small></p>
                </div>
            </div>
        </div>
    </div>
</div>
