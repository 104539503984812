import { Region } from './Region';
import { Village } from './Village';

export class Province {
    public id: number;
    public name: string;
    public region: Region;
    public villages: Village[];

    public static fromDto(p): Province {
        const province = new Province();
        if (!p) {
            return null;
        }

        province.id = p.id;
        province.name = p.name;
        province.region = Region.fromDto(p.region);
        if (p.villages) {
            province.villages = p.villages.map(village => Village.fromDto(village));
        }

        return province;
    }
}
