import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ProductOption } from 'src/app/shared/models/ProductOption';
import { FormGroup, FormBuilder, Validators, FormControlOptions } from '@angular/forms';
import { PriceListService } from 'src/app/price-list/shared/price-list.service';
import { ValidationUtils } from 'src/app/shared/validation.utils';
import { Product } from 'src/app/shared/models/Product';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-product-option-update-form',
  templateUrl: './product-option-update-form.component.html',
  styleUrls: ['./product-option-update-form.component.css']
})
export class ProductOptionUpdateFormComponent implements OnInit, OnChanges {

  public editProductOptionForm: FormGroup;
  public productOptionErrorArray = new Array();
  private _newProductOption: ProductOption = new ProductOption();

  @Input()
  productOption: ProductOption;

  @Input()
  product: Product;

  @Output()
  emitCancelProductOptionEditMood: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  eventCurrency: string;

  constructor(private _formBuilder: FormBuilder, private _priceListService: PriceListService, private _toastr: ToastrService,
              private _translate: TranslateService) {
    this.editProductOptionForm = this._formBuilder.group({
      name: ['', [<any>Validators.required, <any>Validators.maxLength(32)]],
      extraInfo: ['', <any>Validators.maxLength(32)],
      price: ['0.00', <any>Validators.pattern(/^[+-]?[0-9]{1,3}(?:[0-9]*(?:[.,][0-9]{2})?|(?:,[0-9]{3})*(?:\.[0-9]{2})?|(?:\.[0-9]{3})*(?:,[0-9]{2})?)$/)]
    }, {
      validator: ValidationUtils.checkStringLength('name', 'extraInfo')
    } as FormControlOptions);
  }

  ngOnInit() {
    console.log('ngOnInit');
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('eccomi');
    this.editProductOptionForm.patchValue(this.productOption);
  }

  onProductOptionEditSubmit() {
    if (this.editProductOptionForm.valid) {
      this._newProductOption = ProductOption.fromDto(this.editProductOptionForm.value);
      this._newProductOption.status = this.productOption.status;
      this._newProductOption.id = this.productOption.id;
      this._newProductOption.product = this.product;
      this._priceListService.updateProductOption(this._newProductOption).subscribe({
        next: (result) => {
          this.emitCancelProductOptionEditMood.emit();
          this.product.productOptions = result.productOptions;
          this.editProductOptionForm.reset({name: '', extraInfo: '', price: '0.00'});
          this._toastr.success(this._translate.instant('price-list.manage.productOptionSuccessUpdateMsg'));
        }, error: (error) => {
          console.log(error);
        }
      });
    }
    this.productOptionErrorArray = ValidationUtils.getFormValidationErrors(this.editProductOptionForm, 'price-list.manage');
  }

  cancelEditButtonClick() {
    this.emitCancelProductOptionEditMood.emit();
  }

}
