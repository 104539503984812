import { RequestedCable } from './RequestedCable';
import { CableType } from './CableType';

export class Cable {
    public id: number;
    public quantity: number;
    public status: number;
    public requestedCable: RequestedCable;
    public cableType: CableType;

    static fromDto(c): Cable {
        const cable = new Cable();

        if (!c) {
            return null;
        }

        cable.id = c.id;
        cable.quantity = c.quantity;
        cable.status = c.status;
        cable.requestedCable = c.requestedCable;
        cable.cableType = c.cableType;

        return cable;
    }
}
