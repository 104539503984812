import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormControlOptions } from '@angular/forms';
import { Option } from 'src/app/shared/form-group-select/form-group-select.component';
import { ValidationUtils } from 'src/app/shared/validation.utils';
import { PriceList } from 'src/app/shared/models/PriceList';
import { Product } from 'src/app/shared/models/Product';
import { PriceListService } from '../../shared/price-list.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-product-form',
  templateUrl: './add-product-form.component.html',
  styleUrls: ['./add-product-form.component.css']
})
export class AddProductFormComponent implements OnInit {

  public productForm: FormGroup;
  public errorArray = new Array();

  @Input()
  productCategoryOptions: Option[];

  @Input()
  priceList: PriceList;

  @Input()
  disableAddProductBtn = false;

  constructor(private _formBuilder: FormBuilder, private _priceListService: PriceListService, private _toastr: ToastrService,
              private _translate: TranslateService) {

    this.productForm = this._formBuilder.group({
      categoryId: ['', <any>Validators.required],
      name: ['', [<any>Validators.required, <any>Validators.maxLength(32)]],
      extraInfo: ['', <any>Validators.maxLength(32)],
      price: ['0.00', <any>Validators.pattern(/^\d{0,8}(\.\d{1,4})?$/)],
    }, {
      validator: ValidationUtils.checkStringLength('name', 'extraInfo')
    } as FormControlOptions);
  }

  ngOnInit() {
    if (this.priceList.event.useStock) {
      this.productForm.addControl('stockPerDay', new FormControl(0, <any>Validators.pattern(/^[1-9][0-9]*$/)));
    }
  }

  onProductSubmit() {
    this.errorArray = [];
    console.log(this.productForm.valid);
    if (this.productForm.valid) {
      const product = Product.fromDto(this.productForm.value);
      product.status = 1;
      product.productCategory = this.priceList.productCategories.filter(category =>
        category.id.toString() === this.productForm.value.categoryId)[0];
      console.log(product);
      this._priceListService.insertNewProduct(product).subscribe({
        next: (result) => {
          this.priceList.productCategories = result;
          this.productForm.reset({
            categoryId: this.productForm.value.categoryId,
            price: '0.00',
            name: '',
            extraInfo: ''
          });
          this._toastr.success(this._translate.instant('price-list.manage.productAddSuccessMsg'));
        }, error: (error) => {
          console.log(error);
        }
      });
    }
    this.errorArray = ValidationUtils.getFormValidationErrors(this.productForm, 'price-list.manage');
  }
}
