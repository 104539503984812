import { Printer } from './Printer';
import { ProductCategory } from './ProductCategory';
import { Event } from './Event';
import { DeviceUser } from './DeviceUser';

export class PrintersCategories {
    public id: number;
    public printer: Printer;
    public productCategory: ProductCategory;
    public event: Event;
    public deviceUser: DeviceUser;

    static fromDto(p): PrintersCategories {
        const printersCategories = new PrintersCategories();
        if (!p) {
            return null;
        }
        printersCategories.id = p.id;
        printersCategories.printer = Printer.fromDto(p.printer);
        printersCategories.productCategory = ProductCategory.fromDto(p.productCategory);
        printersCategories.event = Event.fromDto(p.event);
        printersCategories.deviceUser = DeviceUser.fromDto(p.deviceUser);

        return printersCategories;
    }
}
