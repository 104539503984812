import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'eventStatus',
  pure: false
})
export class EventStatusPipe implements PipeTransform {

  constructor(private _translate: TranslateService) { }

  transform(status: number, startDate?: string, endDate?: string): string {
    const today = moment();
    const momentStartDate = moment(startDate);
    const momentEndDate = moment(endDate).add(1, 'day');
    let humanStatus: string;
    switch (status) {
      case 0:
        humanStatus = 'eventStatusPipe.deleted';
        break;
      case 2:
        if (today >= momentStartDate && today <= momentEndDate) {
          humanStatus = 'eventStatusPipe.ongoing';
        } else if (today > momentEndDate) {
          humanStatus = 'eventStatusPipe.finished';
        } else {
          humanStatus = 'eventStatusPipe.approved';
        }
        break;
      case 3:
        humanStatus = 'eventStatusPipe.cancelled';
        break;
      default:
        humanStatus = 'eventStatusPipe.pending';
        break;
    }
    return this._translate.instant(humanStatus);
  }

}
