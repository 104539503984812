const local = false;

export const environment = {
  production: false,
  URL_BASE: local
    ? 'http://localhost:3001/api/v1/client'
    : 'https://famulusbackend.rrsolutionstest.it/api/v1/client',
  URL_IMAGES: 'https://famulusimages.rrsolutionstest.it/',
  COOKIE_DOMAIN: local
    ? 'localhost'
    : '.rrsolutionstest.it'
};
