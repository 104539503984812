<ul class="pagination pagination-sm">
    <li class="page-item" [ngClass]="{'disabled': page <= 1}" (click)="previousPage()">
        <a aria-label="Previous" class="page-link">
            <span aria-hidden="true" tabindex="-1">«</span>
        </a>
    </li>

    <ng-template ngFor [ngForOf]="pages" let-p>
        <li *ngIf="p.canClick" class="page-item" [ngClass]="{'disabled': lastPage <= 1, 'active': p.value === page}" (click)="changePage(p.value)">
            <a class="page-link">
          {{p.value}}
        </a>
        </li>

        <li *ngIf="!p.canClick" class="page-item disabled">
            <a class="page-link">
                <span aria-hidden="true" tabindex="-1">{{p.value}}</span>
            </a>
        </li>
    </ng-template>

    <li class="page-item" [ngClass]="{'disabled': lastPage <= 1 || page === lastPage}" (click)="nextPage()">
        <a aria-label="Next" class="page-link">
            <span aria-hidden="true" tabindex="-1">»</span>
        </a>
    </li>
</ul>