import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { URL_BASE } from '../constants';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ActivateLoginService {

  constructor(private _http: HttpClient) { }

  public activateAccountLogin(activateCode: string): Observable<any> {
    return this._http.post(URL_BASE + '/clientUser/activate', { activateCode }).pipe(
      map((result: any) => result)
    );
  }
}
