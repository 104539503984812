export class SurveyAnswer {
    public surveyId: number;
    public description: string;

    static fromDto(c): SurveyAnswer {
        const surveyAnswer = new SurveyAnswer();
        if (!c) {
            return null;
        }
        surveyAnswer.surveyId = c.surveyId;
        surveyAnswer.description = c.description;
        return c;
    }
}
