import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActivateLoginService } from './activate-login.service';

@Component({
  selector: 'app-activate-login',
  templateUrl: './activate-login.component.html',
  styleUrls: ['./activate-login.component.css']
})
export class ActivateLoginComponent implements OnInit {

  public currentDate = new Date();
  public activateCode: string;
  public lang: string;
  public hasError: boolean;
  public isCompleted = false;
  public title: string;
  public message: string;

  constructor(private _route: ActivatedRoute, private _activateLoginService: ActivateLoginService) { }

  ngOnInit(): void {
    this._route.queryParams.subscribe(params => {
      console.log(params);
      this.activateCode = params.activateCode;
      this.lang = params.lang;
      if (!(this.activateCode) || !(this.lang)) {
        console.error('Activate code or lang is not defined!');
        return;
      }

      if (this.lang === 'it') {
        this.title = 'Attivazione account';
      } else {
        this.title = 'Kontoaktivierung';
      }

      this._activateLoginService.activateAccountLogin(this.activateCode).subscribe({
        next: (res) => {
          this.hasError = false;
          this.isCompleted = true;
          if (this.lang === 'it') {
            this.message = 'Il suo account è stato attivato con successo! Nella sua casella di posta elettronica troverà un e-mail con le credenziali valide per l\'accesso al sistema!';
          } else {
            this.message = 'Ihr Konto wurde erfolgreich aktiviert! Sie werden in Ihrem Posteingang eine E-Mail mit gültigen Zugangsdaten für das System finden!';
          }
        },
        error: (error) => {
          this.hasError = true;
          if (error.status === 601) {
            // Send activation code
            if (this.lang === 'it') {
              this.message = 'Errore: il codice attivazione non è stato inviato!';
            } else {
              this.message = 'Fehler: Aktivierungskodex nicht gesendet!';
            }
          } else if (error.status === 602) {
            // Activation code does not exist.
            if (this.lang === 'it') {
              this.message = 'Errore: il codice attivazione non esiste!';
            } else {
              this.message = 'Fehler: Der Aktivierungskodex existiert nicht!';
            }
          } else if (error.status === 603) {
            // Error sending Email to specific email.
            if (this.lang === 'it') {
              this.message = 'Errore: impossibile inviare l\'email!';
            } else {
              this.message = 'Fehler: E-Mail kann nicht gesendet werden!';
            }
          } else {
            // Database error
            if (this.lang === 'it') {
              this.message = 'Errore: Errore generico!';
            } else {
              this.message = 'Fehler: Allgemeiner Fehler!';
            }
          }
        }
      });
    }
  );

  }

}
