<app-products-bulk-actions [priceList]="priceList" [productIdsArray]="productIdsArray"
  [productCategoryOptions]="productCategoryOptions"
  (getPriceListProductCategories)="getPriceListProductCategories($event)">
</app-products-bulk-actions>
<div class="table-responsive" style="position: relative;">
  <div class="scroll-info">
    &rArr;&nbsp;&nbsp;Scroll
  </div>
  <table class="table table-striped table-sm">
    <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col">{{'price-list.manage.category' | translate}}</th>
        <th scope="col">{{'price-list.manage.name' | translate}}</th>
        <th scope="col">{{'price-list.manage.extraInfo' | translate}}</th>
        <th scope="col">{{'price-list.manage.price' | translate}}</th>
        <th scope="col" class="text-center" *ngIf="priceList.event.useStock">{{'price-list.manage.stock' | translate}}</th>
        <th scope="col" class="text-center">{{'price-list.manage.position' | translate}}</th>
        <th scope="col" class="text-center">{{'price-list.manage.displayed' | translate}}</th>
        <th scope="col" class="text-center">{{'price-list.manage.productOptions' | translate}}</th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let category of priceList.productCategories">
        <ng-container *ngFor="let product of category.products; first as isFirst; last as isLast; let i = index">
          <tr [ngClass]="{'bluBottomLine': isLast}">
            <ng-container *ngIf="choosenEditProduct.id === product.id; else displayTemplate">
              <td colspan="9">
                <app-edit-product-form [product]="choosenEditProduct" [productCategoryOptions]="productCategoryOptions" [eventCurrency]="eventCurrency"
                  (getCancelEditModeEvent)="cancelProductEditMode()" [productCategories]="priceList.productCategories"
                  [priceList]="priceList">
                </app-edit-product-form>
              </td>
            </ng-container>
            <ng-template #displayTemplate>
              <td class="text-center">
                <input type="checkbox" [value]="product.id" [checked]="productIdsArray.indexOf(product.id) !== -1"
                  (change)="onCheckboxChange($event)" />
              </td>
              <td [style.width]="150" scope="row">{{category.name}}</td>
              <td [style.width]="250">
                {{product.name}}
              </td>
              <td [style.width]="250">
                {{product.extraInfo}}
              </td>
              <td [style.width]="150">{{product.price | currency:eventCurrency + ' '}}</td>
              <td [style.width]="250" class="text-center" *ngIf="priceList.event.useStock">
                {{product.stockPerDay}} <i class="fa fa-inventory"></i>
              </td>
              <td [style.width]="150" class="text-center"><i class="fa fa-arrows-alt-v"></i>
                {{product.itemOrder}}</td>
              <td [style.width]="150" class="text-center">
                <ng-container *ngIf="product.status == 1; else disabledTemplate">
                  <i style="color: green; cursor: pointer;" class="fas fa-check"
                    (click)="changeProductStatus(product, 0, category)"></i>
                </ng-container>
                <ng-template #disabledTemplate>
                  <i style="color: red; cursor: pointer;" class="fas fa-times"
                    (click)="changeProductStatus(product, 1, category)"></i>
                </ng-template>
              </td>
              <td class="text-center">{{product.productOptions.length}}</td>
              <td class="text-right">
                <div class="btn-group btn-group-sm" role="group" aria-label="Button group with nested dropdown">
                  <button type="button" class="btn btn-link" *ngIf="!isFirst"
                    (click)="changeProductPosition(product.id, product.itemOrder-1, category.id)" placement="top"
                    [ngbTooltip]="'price-list.manage.moveUp' | translate" [disabled]="editProductMood"><i
                      class="fa fa-angle-up"></i></button>
                  <button type="button" class="btn btn-link" *ngIf="!isLast"
                    (click)="changeProductPosition(product.id, product.itemOrder+1, category.id)" placement="top"
                    [ngbTooltip]="'price-list.manage.moveDown' | translate" [disabled]="editProductMood"><i
                      class="fa fa-angle-down"></i></button>
                  <button type="button" class="btn btn-link" placement="top"
                    [ngbTooltip]="'price-list.manage.edit' | translate" (click)="enableEditProduct(product, category)"
                    [disabled]="editProductMood"><i class="fa fa-edit"></i></button>
                  <button type="button" class="btn btn-link" placement="top"
                    [ngbTooltip]="'price-list.manage.delete' | translate" (click)="showConfirmDeleteDialog(product.id)"
                    [disabled]="editProductMood"><i class="fa fa-trash"></i></button>
                  <button type="button" class="btn btn-link" placement="top"
                    [ngbTooltip]="'price-list.manage.duplicate' | translate" [disabled]="editProductMood"
                    (click)="showConfirmDuplicateDialog(product.id)"><i class="fa fa-copy"></i></button>
                  <!--  -->
                  <button type="button" class="btn btn-link" placement="top"
                    [ngbTooltip]="'price-list.manage.showOptions' | translate" [disabled]="editProductMood"
                    init="toggle[product.id] = false" (click)="toggle[product.id] = !toggle[product.id]"><i
                      class="fa fa-align-justify"></i></button>
                </div>
              </td>
            </ng-template>
          </tr>
          <tr *ngIf=toggle[product.id] style="border: .125rem solid #57c6f2; border-top: none;">
            <td colspan="9">
              <app-product-option-form [product]="product" [disableAddButton]="editProductOptionMood" [eventCurrency]="eventCurrency">
              </app-product-option-form>
              <ng-container *ngIf="product.productOptions.length >= 1">
                <app-product-option-table [product]="product" [eventCurrency]="eventCurrency"
                  (getEditProductOptionMood)="getEditProductOptionMood($event)"></app-product-option-table>
              </ng-container>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</div>
<app-products-bulk-actions [priceList]="priceList" [productIdsArray]="productIdsArray"
  [productCategoryOptions]="productCategoryOptions"
  (getPriceListProductCategories)="getPriceListProductCategories($event)">
</app-products-bulk-actions>

<app-confirm-dialog-model #confirmDeleteProductDialog (getResult)="getDeleteProductDecision($event)"
  [modalTitle]="'price-list.manage.deleteProductTitle' | translate"
  [modalContent]="'price-list.manage.deleteProductMsg' | translate" [denyText]="'price-list.manage.no' | translate"
  [acceptText]="'price-list.manage.yes' | translate">
</app-confirm-dialog-model>

<app-confirm-dialog-model #confirmDuplicateProductDialog (getResult)="getDuplicateProductDecision($event)"
  [modalTitle]="'price-list.manage.duplicateProductTitle' | translate"
  [modalContent]="'price-list.manage.duplicateProductMsg' | translate" [denyText]="'price-list.manage.no' | translate"
  [acceptText]="'price-list.manage.yes' | translate">
</app-confirm-dialog-model>
