import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LOCAL_STORAGE } from '../constants';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AccountService } from 'src/app/account/shared/account.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from 'src/app/shared/navigation.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public newPassword: string;
  public repeatPassword: string;
  public errorMsg: string;
  public currentLanguage: string;
  public presentationVideoUrl: SafeResourceUrl;
  public setupVideoUrl: SafeResourceUrl;
  public eventSetupVideoUrl: SafeResourceUrl;
  public eventAppExplanationVideoUrl: SafeResourceUrl;
  public deleteDataVideoUrl: SafeResourceUrl;
  private _clientId: number;
  private _currentToken: any;



  @ViewChild('changePasswordModal', { static: true })
  changePasswordModal;

  constructor(private _modalService: NgbModal, public jwtHelper: JwtHelperService, private _accountsService: AccountService,
    private _toastr: ToastrService, private _translateService: TranslateService, private _navigation: NavigationService,
    private _sanitizer: DomSanitizer) {}

  ngOnInit() {

    const accessToken = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
    this._currentToken = this.jwtHelper.decodeToken(accessToken).data;

    if (this._translateService.currentLang) {
      this.currentLanguage = this._translateService.currentLang.toUpperCase();
    }
    if (this.currentLanguage === 'DE') {
      this.presentationVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/dPjTjBPQWss');
      this.setupVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/P6r82dTzjWU');
      this.eventSetupVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/JBZP6CgJPPM');
      this.eventAppExplanationVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/2U_bdaa9QcI');
      this.deleteDataVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/1wSi_bmSq-Y');
    } else if (this.currentLanguage === 'IT') {
      this.presentationVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/tkOs6Vn-OoE');
      this.setupVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/HZfvSsY7Gm8');
      this.eventSetupVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/QKpH5az8OyA');
      this.eventAppExplanationVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/DwvFEb2CMXE');
      this.deleteDataVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/0wZ6sP_atEE');
    } else {
      this.presentationVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/tkOs6Vn-OoE');
      this.setupVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/HZfvSsY7Gm8');
      this.eventSetupVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/QKpH5az8OyA');
      this.eventAppExplanationVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/DwvFEb2CMXE');
      this.deleteDataVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/9QsyGQFnyvs');
    }
    console.log('presentationVideoUrl', this.presentationVideoUrl);
    console.log('setupVideoUrl', this.setupVideoUrl);
    this._translateService.onLangChange.subscribe(result => {
      this.currentLanguage = result.lang.toUpperCase();
      if (this.currentLanguage === 'DE') {
        this.presentationVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/dPjTjBPQWss');
        this.setupVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/P6r82dTzjWU');
        this.eventSetupVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/JBZP6CgJPPM');
        this.eventAppExplanationVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/2U_bdaa9QcI');
        this.deleteDataVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/1wSi_bmSq-Y');
      } else if (this.currentLanguage === 'IT') {
        this.presentationVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/tkOs6Vn-OoE');
        this.setupVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/HZfvSsY7Gm8');
        this.eventSetupVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/QKpH5az8OyA');
        this.eventAppExplanationVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/DwvFEb2CMXE');
        this.deleteDataVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/0wZ6sP_atEE');
      } else {
        this.presentationVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/tkOs6Vn-OoE');
        this.setupVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/HZfvSsY7Gm8');
        this.eventSetupVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/QKpH5az8OyA');
        this.eventAppExplanationVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/DwvFEb2CMXE');
        this.deleteDataVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/9QsyGQFnyvs');
      }
      console.log('presentationVideoUrl', this.presentationVideoUrl);
      console.log('setupVideoUrl', this.setupVideoUrl);
    });
  }

  updateCurrentClientPassword() {
    this.errorMsg = undefined;
    if (this.newPassword === '' || !this.newPassword) {
      this.errorMsg = this._translateService.instant('changePasswordModal.required');
      return;
    }
    if (this.newPassword.length < 6) {
      this.errorMsg = this._translateService.instant('changePasswordModal.shortLength');
      return;
    }
    if (this.newPassword !== this.repeatPassword) {
      this.errorMsg = this._translateService.instant('changePasswordModal.passwordNotEqual');
      return;
    }
    this._accountsService.updateFirstLoginClientPassword(this._clientId, this.newPassword).subscribe(result => {
      localStorage.removeItem(LOCAL_STORAGE.FIRST_LOGIN);
      this._modalService.dismissAll();
      this._navigation.requestEvent().go();
      this._toastr.success(this._translateService.instant('changePasswordModal.changeSuccess'));
    });
  }

}
