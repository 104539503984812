import { RequestedSoftwareType } from './RequestedSoftwareType';

export class SoftwareType {
    public id: number;
    public name: string;
    public requestedSoftwareType: RequestedSoftwareType;

    static fromDto(s): SoftwareType {
        const softwareType = new SoftwareType();

        if (!s) {
            return null;
        }

        softwareType.id = s.id;
        softwareType.name = s.name;
        softwareType.requestedSoftwareType = s.requestedSoftwareType;

        return s;
    }
}
