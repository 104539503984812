<form #filtersForm="ngForm" (submit)="apply()">
    <div class="data-filters-container ibox" [ngClass]="{ 'full-width': fullWidth }">
        <!-- <div class="data-filters-title ibox-title" (click)="showFilters()">
        <h2 class="data-filters-title-text" [translate]="'data-table.filters.title'"></h2>
        <i class="fa" [ngClass]="{ 'fa-angle-up': !opened, 'fa-angle-down': opened }"></i>
      </div> -->
        <div class="data-filters ibox-content" [ngClass]="{ 'no-height': !opened }">

            <ng-content select="app-data-filter-group"></ng-content>

        </div>
        <div class="data-filters-footer ibox-content text-right mt-1 mb-1" style="padding-right: 5px;" [ngClass]="{ 'no-height': !opened }">
            <button type="button" class="btn btn-default btn-sm" (click)="reset()">
          <i class="fa fa-ban"></i>
          {{'data-table.filters.buttons.reset' | translate}}
        </button>

            <button type="submit" class="btn btn-primary btn-sm">
          <i class="fa fa-check"></i>
          {{'data-table.filters.buttons.apply' | translate}}
        </button>

            <ng-content select="app-data-filters-button"></ng-content>
        </div>

    </div>
</form>