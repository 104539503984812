import { Event } from './Event';
import { Cable } from './Cable';

export class RequestedCable {
    public id: number;
    public quantity: number;
    public parentRequestId: number;
    public event: Event;
    public cables: Cable;

    static fromDto(r): RequestedCable {
        const requestedCable = new RequestedCable();

        if (!r) {
            return null;
        }

        requestedCable.id = r.id;
        requestedCable.quantity = r.quantity;
        requestedCable.parentRequestId = r.parentRequestId;
        requestedCable.event = r.event;
        requestedCable.cables = r.cables;

        return requestedCable;
    }
}
