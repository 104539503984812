import { Company } from './Company';
import { Event } from './Event';
import { SurveyAnswer } from './SurveyAnswer';

export class Client {
    public id: number;
    public title: string;
    public firstName: string;
    public lastName: string;
    public firstEmail: string;
    public secondEmail: string;
    public firstPhone: string;
    public secondPhone: string;
    public username: string;
    public password: string;
    public parentAdminId: number;
    public notes: string;
    public company: Company = new Company();
    public status: number;
    public recoveryCode: string;
    public administrator: boolean;
    public newsletter = false;
    public language: string;
    public events: Event[] = [];
    public villageId: number;
    public answer: SurveyAnswer = new SurveyAnswer();

    static fromDto(c, existingClient?: Client): Client {
        const client = new Client();
        if (!c) {
            return null;
        }
        if (existingClient) {
            client.id = (c.id === undefined ? existingClient.id : c.id);
            client.title = (c.title === undefined ? existingClient.title : c.title);
            client.firstName = (c.firstName === undefined ? existingClient.firstName : c.firstName);
            client.lastName = (c.lastName === undefined ? existingClient.lastName : c.lastName);
            client.firstEmail = (c.firstEmail === undefined ? existingClient.firstEmail : c.firstEmail);
            client.secondEmail = (c.secondEmail === undefined ? existingClient.secondEmail : c.secondEmail);
            client.firstPhone = (c.firstPhone === undefined ? existingClient.firstPhone : c.firstPhone);
            client.secondPhone = (c.secondPhone === undefined ? existingClient.secondPhone : c.secondPhone);
            client.username = (c.username === undefined ? existingClient.username : c.username);
            client.password = (c.password === undefined ? existingClient.password : c.password);
            client.parentAdminId = (c.parentAdminId === undefined ? existingClient.parentAdminId : c.parentAdminId);
            client.notes = (c.notes === undefined ? existingClient.notes : c.notes);
            client.status = (c.status === undefined ? existingClient.status : c.status);
            client.recoveryCode = (c.recoveryCode === undefined ? existingClient.recoveryCode : c.recoveryCode);
            client.administrator = (c.administrator === undefined ? existingClient.administrator : c.administrator);
            client.company = (c.company === undefined ? existingClient.company : c.company);
            client.newsletter = c.newsletter;
            client.language = (c.language === undefined ? existingClient.language : c.language);
        } else {
            client.id = c.id;
            client.title = c.title;
            client.firstName = c.firstName;
            client.lastName = c.lastName;
            client.firstEmail = c.firstEmail;
            client.secondEmail = c.secondEmail;
            client.firstPhone = c.firstPhone;
            client.secondPhone = c.secondPhone;
            client.username = c.username;
            client.password = c.password;
            client.parentAdminId = c.parentAdminId;
            client.notes = c.notes;
            client.status = c.status;
            client.recoveryCode = c.recoveryCode;
            client.administrator = c.administrator;
            client.company = Company.fromDto(c.company);
            client.newsletter = c.newsletter;
            client.language = c.language;
            if (c.events) {
                client.events = c.events.map(event => Event.fromDto(event));
            }
        }
        return client;
    }
}


