<div class="data-filter" style="padding: 0px 5px;">
    <label style="margin: 0px; font-weight: bold;">{{label}}</label>
    <div class="input-group input-group-sm">
        <input type="text" [(ngModel)]="model" (ngModelChange)="change()" [dpDayPicker]="datePickerConfig" [theme]="'dp-material'"
        placeholder="dd-mm-yyyy" class="form-control form-control-sm" [disabled]="disabled" ngbDatepicker #d="ngbDatepicker"/>
        <div class="input-group-append">
            <button class="btn btn-outline-secondary btn-sm" (click)="d.toggle()" type="button"><i
                class="fa fa-calendar"></i></button>
        </div>
    </div>
</div>
