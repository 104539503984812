<nav class="navbar navbar-expand-lg navbar-dark bg-dark" style="padding: 10.4px; background-color: #57c6f2 !important;">
  <a class="navbar-brand" href="#">{{title}}</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav ml-auto" id="">
      <!--
      <li class="nav-item">
        <a class="nav-link" href="/account">{{'topNavbar.account' | translate}}</a>
      </li>
      -->
      <li class="nav-item">
        <a class="nav-link" href="#" (click)="logout($event)">{{'topNavbar.logout' | translate}}</a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
          aria-expanded="false" style="padding: 7px;">
          <img [src]="'/assets/flag/' + currentLanguage.flag +'.png'">
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown" style="min-width: 0px;">
          <a class="dropdown-item" *ngFor="let l of languages" (click)="changeLanguage(l)">
            <img [src]="'/assets/flag/' + l.flag +'.png'">
          </a>
        </div>
      </li>
    </ul>
  </div>

  <app-sidebar *ngIf="isMobileMenu()"></app-sidebar>
</nav>
