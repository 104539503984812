import { RequestedDevice } from './RequestedDevice';

export class DeviceType {
    public id: number;
    public name: string;
    public status: number;
    public requestedDevice: RequestedDevice;

    static fromDto(d): DeviceType {
        const deviceType = new DeviceType();

        if (!d) {
            return null;
        }

        deviceType.id = d.id;
        deviceType.name = d.name;
        deviceType.status = d.status;
        deviceType.requestedDevice = d.requestedDevice;

        return d;
    }
}
