import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControlOptions } from '@angular/forms';
import { PriceListService } from 'src/app/price-list/shared/price-list.service';
import { ValidationUtils } from 'src/app/shared/validation.utils';
import { Product } from 'src/app/shared/models/Product';
import { ProductOption } from 'src/app/shared/models/ProductOption';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-product-option-form',
  templateUrl: './product-option-form.component.html',
  styleUrls: ['./product-option-form.component.css']
})
export class ProductOptionFormComponent implements OnInit {

  public productOptionForm: FormGroup;
  public productOptionErrorArray = Array();
  public newProductOption: ProductOption = new ProductOption();

  @Input()
  product: Product = new Product();

  @Input()
  disableAddButton: boolean;

  @Input()
  eventCurrency: string;

  constructor(private _formBuilder: FormBuilder, private _priceListService: PriceListService, private _toastr: ToastrService,
              private _translate: TranslateService) {
    this.productOptionForm = this._formBuilder.group({
      name: ['', [<any>Validators.required, <any>Validators.maxLength(32)]],
      extraInfo: ['', <any>Validators.maxLength(32)],
      price: ['0.00', <any>Validators.pattern(/^(?:-)?(?:\d+|\d{1,3}(?:,\d{3})*)(?:\.\d{1,2})?$/)]
    }, {
      validator: ValidationUtils.checkStringLength('name', 'extraInfo')
    } as FormControlOptions);
  }

  ngOnInit() {
  }


  onProductOptionSubmit() {
    if (this.productOptionForm.valid) {
      this.newProductOption = ProductOption.fromDto(this.productOptionForm.value);
      this.newProductOption.product = this.product;
      this.newProductOption.status = 1;
      this._priceListService.insertNewProductOption(this.newProductOption).subscribe({
        next: (result) => {
          console.log(result);
          this.product.productOptions = result.productOptions;
          this.productOptionForm.reset({name: '', extraInfo: '', price: '0.00'});
          this._toastr.success(this._translate.instant('price-list.manage.productOptionAddSuccessMsg'));
        }, error: (error) => {
          console.log(error);
        }
      });
    }
    this.productOptionErrorArray = ValidationUtils.getFormValidationErrors(this.productOptionForm, 'price-list.manage');
  }

}
