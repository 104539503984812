import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpUtils } from 'src/app/shared/http-utils';
import { URL_BASE, URL_IMAGES } from '../../core/constants';
import { map } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { PrinterType } from 'src/app/shared/models/PrinterType';
import { RouterType } from 'src/app/shared/models/RouterType';
import { DeviceType } from 'src/app/shared/models/DeviceType';
import { Device } from 'src/app/shared/models/Device';
import { SoftwareType } from 'src/app/shared/models/SoftwareType';
import { Ups } from 'src/app/shared/models/Ups';
import { Cable } from 'src/app/shared/models/Cable';
import { Event } from 'src/app/shared/models/Event';
import { EventCost } from 'src/app/shared/models/EventCost';
import { EventOffer } from 'src/app/shared/models/EventOffer';
import { PrintersCategories } from 'src/app/shared/models/PrintersCategories';
import { TurnOverProduct } from 'src/app/shared/models/TurnOverProduct';
import { OnlineEvent } from 'src/app/shared/models/OnlineEvent';
import { Printer } from 'src/app/shared/models/Printer';
import { AdditionalCost } from 'src/app/shared/models/AdditionalCost';
import { DeviceUser } from '../../shared/models/DeviceUser';

@Injectable()
export class EventService {
  private _headers: any;

  private _eventObjectSource = new BehaviorSubject(undefined);
  public currentEventObject = this._eventObjectSource.asObservable();

  private _activeSubPage = new BehaviorSubject('advertising');
  public currentActiveSubPage = this._activeSubPage.asObservable();

  private _warningMissingPriceList = new BehaviorSubject(false);
  public currentWarningMissingPriceList = this._warningMissingPriceList.asObservable();

  private _warningMissingCategoryPrinterAssign = new BehaviorSubject(false);
  public currentWarningMissingCategoryPrinterAssign = this._warningMissingCategoryPrinterAssign.asObservable();

  private _eventFinished = new BehaviorSubject(false);
  public currentEventFinished = this._eventFinished.asObservable();

  constructor(private _http: HttpClient) {
    this._headers = HttpUtils.createHeaders();
  }

  public updateEventObject(event: Event) {
    this._eventObjectSource.next(event);
  }

  public updateCurrentActiveSubPage(subPageName: string) {
    this._activeSubPage.next(subPageName);
  }

  public updateWarningMissingPriceList(value: boolean) {
    this._warningMissingPriceList.next(value);
  }

  public updateWarningMissingCategoryPrinterAssign(value: boolean) {
    this._warningMissingCategoryPrinterAssign.next(value);
  }

  public updateEventFinished(value: boolean) {
    this._eventFinished.next(value);
  }

  /**
   * getAllPrinterTypes
   */
  public getAllPrinterTypes(): Observable<PrinterType[]> {
    return this._http.get(URL_BASE + '/printerType/get', { headers: this._headers }).pipe(
      map((result: any[]) => result.map(res => PrinterType.fromDto(res)))
    );
  }

  /**
   * getAllRouterTypes
   */
  public getAllRouterTypes(): Observable<RouterType[]> {
    return this._http.get(URL_BASE + '/routerType/get', { headers: this._headers }).pipe(
      map((result: any[]) => result.map(res => RouterType.fromDto(res)))
    );
  }

  /**
   * getAllDeviceTypes
   */
  public getAllDeviceTypes(): Observable<DeviceType[]> {
    return this._http.get(URL_BASE + '/deviceType/get', { headers: this._headers }).pipe(
      map((result: any[]) => result.map(res => DeviceType.fromDto(res)))
    );
  }

  /**
   * getAllDevices
   */
  public getAllDevices(): Observable<Device[]> {
    return this._http.get(URL_BASE + '/device/get', { headers: this._headers }).pipe(
      map((result: any[]) => result.map(res => Device.fromDto(res)))
    );
  }

  /**
   * getAllSoftwareTypes
   */
  public getAllSoftwareTypes(): Observable<SoftwareType[]> {
    return this._http.get(URL_BASE + '/softwareType/get', { headers: this._headers }).pipe(
      map((result: any[]) => result.map(res => SoftwareType.fromDto(res)))
    );
  }

  /**
   * getAllUps
   */
  public getAllUps(): Observable<Ups[]> {
    return this._http.get(URL_BASE + '/ups/get', { headers: this._headers }).pipe(
      map((result: any[]) => result.map(res => Ups.fromDto(res)))
    );
  }

  /**
   * getAllCables
   */
  public getAllCables(): Observable<Cable[]> {
    return this._http.get(URL_BASE + '/cable/get', { headers: this._headers }).pipe(
      map((result: any[]) => result.map(res => Cable.fromDto(res)))
    );
  }

  /**
   * insertEventRequest
   */
  public insertEventRequest(event: Event): Observable<Event> {
    return this._http.put(URL_BASE + '/event/insert', { event }, { headers: this._headers }).pipe(
      map((res: any) => Event.fromDto(res)));
  }

  /**
   * getAllCables
   */
  public calculatePrice(event: any): Observable<any> {
    return this._http.post(URL_BASE + '/event/calculatePrice', { event }, { headers: this._headers }).pipe(
      map((res: any) => EventCost.fromDto(res)));
  }

  /**
   * getEventsForClient
   */
  public getEventsForClient(clientId: string): Observable<Event[]> {
    console.log('getEventsForClient', URL_BASE + '/event/getEventsForClient');
    console.log('clientId', clientId);
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/event/getEventsForClient', { headers, params: { id: clientId } }).pipe(
      map((result: any[]) => result.map(res => Event.fromDto(res)))
    );
  }
  /**
   * getEventsForClient
   */
  public getEventOffersForClient(clientId: string): Observable<EventOffer[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/event/getEventOffersForClient', { headers, params: { clientId } }).pipe(
      map((result: any[]) => result.map(res => EventOffer.fromDto(res)))
    );
  }

  public downloadEventOfferPdf(offerId: string, versionLetter: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.post(URL_BASE + '/eventOffer/download', { offerId, versionLetter }, {
      responseType: 'blob',
      headers: headers
    });
  }

  /**
   * getEventForClient
   */
  public getEventForClient(eventId: number, clientId: number): Observable<Event> {
    return this._http.get(URL_BASE + '/event/getEventOfClient',
      { headers: this._headers, params: { eventId: eventId.toString(), clientId: clientId.toString() } }).pipe(
        map((res: any) => Event.fromDto(res))
      );
  }

  public updateEventLocation(eventId: number, latitude: string, longitude: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/event/updateLocation', { eventId, latitude, longitude }, { headers }).pipe(
      map((res: any) => res));
  }

  public updateDeviceUser(deviceUser: DeviceUser, eventId: number): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/deviceUser/update', { deviceUser, eventId }, {headers}).pipe(
      map((res: any) => res));
  }

  /**
   * getPrintersCategoriesByEventId
   */
  public getPrintersCategoriesByEventId(eventId: string): Observable<PrintersCategories[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/printersCategories/get', { headers: headers, params: { eventId } }).pipe(
      map((result: any[]) => result.map(res => PrintersCategories.fromDto(res)))
    );
  }

  public getPrintersCategoriesDeliveryPaperByEventId(eventId: string): Observable<any[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/printersCategories/deliveryPaper/get', { headers: headers, params: { eventId } }).pipe(
      map((res: any) => res));
  }

  /**
   *  insertPrintersCategories
   */
  public insertPrintersCategories(printersCategories: any[], eventId: string) {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/printersCategories/insert', { printersCategories, eventId }, { headers }).pipe(
      map((res: any) => res));
  }

  public getEventTurnOver(eventId: string): Observable<TurnOverProduct[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/turnOver/get', { headers: headers, params: { eventId } }).pipe(
      map((result: any[]) => result.map(res => TurnOverProduct.fromDto(res)))
    );
  }

  public getCategoriesTurnOver(eventId: string): Observable<TurnOverProduct[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/turnOver/getCategories', { headers: headers, params: { eventId } }).pipe(
      map((result: any[]) => result.map(res => TurnOverProduct.fromDto(res)))
    );
  }

  public getDevicesTurnOver(eventId: string): Observable<TurnOverProduct[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/turnOver/getDevices', { headers: headers, params: { eventId } }).pipe(
      map((result: any[]) => result)
    );
  }


  public getOnlineEventByEventId(eventId: string): Observable<OnlineEvent> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/onlineEvent/getByEventId', { headers: headers, params: { eventId } }).pipe(
      map((result: any) => OnlineEvent.fromDto(result))
    );
  }

  public getOnlineEventByEventOfferId(eventOfferId: string): Observable<OnlineEvent> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/onlineEvent/getByEventOfferId', { headers: headers, params: { eventOfferId } }).pipe(
      map((result: any) => OnlineEvent.fromDto(result))
    );
  }

  public changePublishValue(id: string, status: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.post(URL_BASE + '/onlineEvent/changePublishValue', { id, status }, { headers }).pipe(
      map((result: any) => result)
    );
  }

  /**
   * uploadNewPriceListToEvent
   */
  public insertOnlineEvent(type: string, fileToUpload: File, imageName: string, id: string, onlineEvent: OnlineEvent, imageRemoved: boolean): Observable<any> {
    const formData = new FormData();
    let pictureSubFolder: string;
    if (type === 'eventOffer') {
      formData.append('eventOfferId', id);
      formData.append('eventId', '0');
      pictureSubFolder = 'offers/';
    } else {
      formData.append('eventOfferId', '0');
      formData.append('eventId', id);
      pictureSubFolder = '';
    }
    formData.append('onlineEvent', JSON.stringify(onlineEvent));
    if (fileToUpload) {
      formData.append('imageName', imageName);
      formData.append('file', fileToUpload, fileToUpload.name);
      console.log('change/add fileToUpload.name', fileToUpload.name);
    } else {
      if (!imageRemoved && onlineEvent.pictureName) {
        const imageName = this.replaceSubstring(onlineEvent.pictureName, URL_IMAGES + pictureSubFolder + id + '/', '');
        formData.append('imageName', imageName);
        console.log('remains same fileToUpload.name', imageName);
      }
    }
    const headers = HttpUtils.createHeaders();
    return this._http.post(URL_BASE + '/onlineEvent/insert', formData, { headers }).pipe(
      map((res: any) => res));
  }


  /**
   * uploadNewPriceListToEvent
   */
   public getPrintersByCompany(): Observable<Printer[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/printers/getByCompany', { headers: headers }).pipe(
      map((result: any[]) => result)
    );
  }

  public printersAssignUnassignToEvent(eventId: number, printerId: number, isAssign: number): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.post(URL_BASE + '/printers/assignUnassign', { eventId, printerId, isAssign}, { headers }).pipe(
      map((result: any) => result)
    );
  }

  public replaceSubstring(inSource: string, inToReplace: string, inReplaceWith: string): string {
    const outString: string[] = [];
    const repLen = inToReplace.length;
    let startIndex = 0;

    let idx = inSource.indexOf(inToReplace, startIndex);

    while (idx !== -1) {
      outString.push(inSource.substring(startIndex, idx));
      outString.push(inReplaceWith);

      startIndex = idx + repLen;
      idx = inSource.indexOf(inToReplace, startIndex);
    }

    outString.push(inSource.substring(startIndex));

    return outString.join('');
  }

  /**
  * getAdditionalCosts
  */
  public getAdditionalCosts(): Observable<AdditionalCost[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/additionalCosts/get', { headers }).pipe(
      map((result: any[]) => result.map(res => AdditionalCost.fromDto(res)))
    );
  }
  /**
  * getAdditionalCostsAssigned
  */
  public getAdditionalCostsAssigned(eventId: string): Observable<any[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/additionalCosts/getAssigned', { headers: headers, params: { eventId } }).pipe(
      map((result: any[]) => result)
    );
  }

}
