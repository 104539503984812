<div class="full-page register-page">
    <div class="content">
      <div class="container text-center">
        <div class="row">
            <div class="col-md-12">
              <div class="header-text">
                  <h2>Registration Success</h2>
                  <h4>registration successfuly submitted!</h4>
                  <hr>
              </div>
            </div>
            <div class="col-md-12">
              <div class="media">
                  <div class="media-left">
                    <div class="icon"><i class="fa fa-email"></i></div>
                  </div>
                  <div class="media-body">
                    <h4>Confirm Email</h4>
                    We sended to your email the confirmation code to activate your account
                  </div>
              </div>
            </div>
            <div class="col">
              <a href="/login" class="btn btn-default btn-lg">Login</a>
            </div>
        </div>
      </div>
    </div>
  </div>
  