import { Event } from './Event';
import { RouterType } from './RouterType';

export class RequestedRouterType {
    public id: number;
    public quantity: number;
    public parentRequestId: number;
    public events: Event;
    public routerType: RouterType;

    static fromDto(r): RequestedRouterType {
        const requestedRouterType = new RequestedRouterType();

        if (!r) {
            return null;
        }

        requestedRouterType.id = r.id;
        requestedRouterType.quantity = r.quantity;
        requestedRouterType.parentRequestId = r.parentRequestId;
        requestedRouterType.events = r.events;
        requestedRouterType.routerType = r.routerType;

        return r;
    }
}
