import { Country } from './Country';

export class DeliveryAddress {
    public id: number;
    public firstLastName: string;
    public address: string;
    public addressDetails: string;
    public city: string;
    public province: string;
    public postcode: string;
    public phone: string;
    public country: Country;

    static formDto(d): DeliveryAddress {
        const deliveryAddresses = new DeliveryAddress();
        if (!d) {
            return null;
        }
        deliveryAddresses.id = d.id;
        deliveryAddresses.firstLastName = d.firstLastName;
        deliveryAddresses.address = d.address;
        deliveryAddresses.addressDetails = d.addressDetails;
        deliveryAddresses.city = d.city;
        deliveryAddresses.province = d.province;
        deliveryAddresses.postcode = d.postcode;
        deliveryAddresses.phone = d.phone;
        deliveryAddresses.country = d.country;

        return deliveryAddresses;
    }
}
