import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Spinkit } from 'ng-http-loader';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public spinkit = Spinkit;
  private _defaultLanguage = 'de';
  private _languageArray = ['de', 'it', 'en'];

  constructor(private translate: TranslateService) {
    this.initializeLanguages();
  }

  private initializeLanguages(): void {
    this.translate.addLangs(this._languageArray);
    if (this._languageArray.includes(this.translate.getBrowserCultureLang())) {
      this._defaultLanguage = this.translate.getBrowserCultureLang();
    }
    // translate.setDefaultLang(this._defaultLanguage);
    const locale = localStorage.getItem('locale');
    if (locale && this.translate.getLangs().findIndex(l => l === locale) !== -1) {
      this.translate.use(locale);
    } else {
      localStorage.setItem('locale', this._defaultLanguage);
    }
  }
}
