import { Country } from './Country';

export class PickupPoint {
    public id: number;
    public name: string;
    public nameLangKey: string;
    public addressLangKey: string;
    public postCode: string;
    public cityLangKey: string;
    public country: Country;

    static fromDto(p): PickupPoint {
        const pickupPoint = new PickupPoint();
        if (!p) {
            return null;
        }

        pickupPoint.id = p.id;
        pickupPoint.name = p.name;
        pickupPoint.nameLangKey = p.nameLangKey;
        pickupPoint.addressLangKey = p.addressLangKey;
        pickupPoint.postCode = p.postCode;
        pickupPoint.cityLangKey = p.cityLangKey;
        pickupPoint.country = Country.fromDto(p.country);

        return pickupPoint;
    }
}
