import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { SearchObjectResult } from '../models/SearchObject';
import { Observable, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DataFiltersService } from '../../shared/data-filters/data-filters.service';
import { DataFiltersComponent } from '../../shared/data-filters/data-filters.component';

export interface DataTableColumn {
  name: string;
  displayed: boolean;
  translationKey: string;
  template: TemplateRef<any>;
  sortable: boolean;
  additionalClass?: string;
  trAddidionalClass?: string;
}

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css']
})
export class DataTableComponent implements OnInit, OnDestroy, OnChanges {
  items: any[] = [];

  @Input()
  columns: DataTableColumn[] = [];

  @Input()
  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<any>>;

  @Input()
  filters: any = {};

  @Input()
  pageSize = 10;

  @Input()
  filtersComponent: DataFiltersComponent;

  @Input()
  selectedItemId: string;

  @Output()
  filtersChange: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  beforeReset = new EventEmitter<void>();

  @Output()
  onReset: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onLoad = new EventEmitter<SearchObjectResult<any>>();

  @Output()
  onPageChange = new EventEmitter<number>();

  @Output()
  onTableRowClick = new EventEmitter<any>();


  count = 0;
  totalCount = 0;
  page = 1;
  lastPage = 1;

  resetSubscription: Subscription;
  applySubscription: Subscription;

  loading = false;


  constructor(
    private toastr: ToastrService,
    private translate: TranslateService,
    private filtersService: DataFiltersService
  ) {
    this.resetSubscription = filtersService.resetObservable.subscribe(() => this.reset());
    this.applySubscription = filtersService.applyObservable.subscribe(() => this.refresh());
  }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
    this.changePage(1);
  }

  reset() {
    this.beforeReset.emit();

    this.filters = {};
    this.filtersChange.emit(this.filters);
    this.onReset.emit();

    this.refresh();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.loadPage && changes.loadPage.currentValue) {
      this.changePage(this.page);
    }

    if (changes.filtersComponent && changes.filtersComponent.currentValue) {
      this.resetSubscription = this.filtersComponent.getResetObservable().subscribe(() => this.reset());
      this.applySubscription = this.filtersComponent.getApplyObservable().subscribe(() => this.refresh());
    }
  }

  changePage(page: number) {
    if (!this.loadPage) {
      return;
    }

    this.page = page;
    this.onPageChange.emit(this.page);

    this.loadPage(this.page, this.pageSize)
      .subscribe({
        next: (res) => {
          console.log('my res', res);
          this.count = res.count;
          this.totalCount = res.totalCount;
          this.items = res.results;
          this.lastPage = res.totalPages;

          this.onLoad.emit(res);
        }, error: (error) => {
          console.log(error);
          this.toastr.error(error.error.message || this.translate.instant('data-table.errors.load-page'));
        }
      });
  }

  loadNextPage() {
    if (!this.loadPage) {
      return;
    }

    this.page = this.page + 1;
    this.onPageChange.emit(this.page);

    this.loading = true;

    this.loadPage(this.page, this.pageSize)
      .subscribe({
        next: (res) => {
          this.items.push(...res.results);

          this.onLoad.emit(res);
        }, error: (error) => {
          console.log(error);
          this.toastr.error(error.error.message || this.translate.instant('data-table.errors.load-page'));
        }
      });
  }

  ngOnDestroy() {
    this.resetSubscription.unsubscribe();
    this.applySubscription.unsubscribe();
  }

  onTableRowClickEvent(item: any) {
    this.onTableRowClick.emit(item);
  }

}
