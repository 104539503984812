<div [formGroup]="formGroup" class="form-group">
  <label class="control-label col-form-label-sm" *ngIf="label !== ''">{{label}}</label>
  <select class="form-control form-control-sm {{additionalClass}} {{error ? 'is-invalid' : ''}}"
    [formControlName]="formControlNameValue" name="{{name}}" id="{{id}}" (ngModelChange)="change()"
    [required]="required">
    <option *ngFor="let item of options" [value]="item.value">{{item.label | translate}}</option>
  </select>
  <div class="invalid-feedback" [hidden]="!error">
    {{error | translate}}
  </div>
</div>