import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'decimalNumber'})
export class DecimalNumberPipe implements PipeTransform {

  constructor() {
  }

  transform(value: any, decimal?: string): any {
    if (!value) {
      return value;
    }
    return parseFloat(value).toFixed(2);
  }
}
