<div class="wrapper">
    <div class="sidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="main-panel">
        <app-top-navbar title="{{ 'price-list.manage.modifyPriceList' | translate}}"></app-top-navbar>
        <div class="main-content container-fluid">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                      <div class="col-12 col-pricelist-right text-right">
                        <div class="form-group" style="padding-top: 9px;">
                            <a href="/event/details/{{priceList?.event?.id}}/price-list"
                                class="btn btn-primary btn-sm"><i class="fa fa-arrow-circle-left"></i>
                                {{'price-list.manage.backToEvent' | translate}}</a>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col col-pricelist-left">
                          <div class="card-text">
                              <div class="form-group" style="margin: 0; padding-top: 9px;">
                                  <label
                                      class="control-label">{{'price-list.manage.priceListName' | translate}}</label>
                                  <div class="input-group input-group-sm">
                                      <input type="text"
                                          class="form-control {{ (priceListNameError !== '' ? 'is-invalid' : '') }}"
                                          [(ngModel)]="priceList.name"
                                          placeholder="{{'price-list.manage.priceListName' | translate}}"
                                          aria-label="Recipient's username" aria-describedby="button-addon2">
                                      <div class="input-group-append">
                                          <button class="btn btn-outline-secondary" type="button"
                                              (click)="updatePriceListName()">{{'price-list.manage.update' | translate}}</button>
                                      </div>
                                      <div class="invalid-feedback">
                                          {{priceListNameError | translate}}
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                    <div class="row">
                        <div class="col">
                          <nav ngbNav #nav="ngbNav" class="nav-tabs" (navChange)="beforeChange($event)">
                            <ng-container ngbNavItem id="categories-tab">
                                <a ngbNavLink>{{'price-list.manage.categories' | translate}}</a>
                                <ng-template ngbNavContent>
                                  <div class="row">
                                    <div class="col-lg-6 col-12">
                                        <form [formGroup]="productCategoryForm"
                                            (ngSubmit)="onProductCategorySubmit()" class="needs-validation">
                                            <div class="form-group mt-3" [formGroup]="productCategoryForm">
                                                <label
                                                    class="control-label">{{'price-list.manage.categoryName' | translate}}</label>
                                                <div class="input-group input-group-sm">
                                                    <input type="text"
                                                        class="form-control {{clientErrorArray['name'] ? 'is-invalid' : ''}}"
                                                        name="name" id="name" [required]="true"
                                                        formControlName="name"
                                                        placeholder="{{'price-list.manage.categoryName' | translate}}">
                                                    <div class="input-group-append">
                                                        <button class="btn btn-outline-secondary" type="submit">
                                                            {{'price-list.manage.add' | translate}}</button>
                                                    </div>
                                                    <div class="invalid-feedback">
                                                        {{clientErrorArray['name'] | translate}}
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                  </div>
                                  <app-category-table [priceList]="priceList"></app-category-table>
                                </ng-template>
                            </ng-container>
                            <ng-container ngbNavItem id="products-tab">
                                <a ngbNavLink>{{'price-list.manage.products' | translate}}</a>
                                <ng-template ngbNavContent>
                                  <app-add-product-form [productCategoryOptions]="productCategoryOptions" [eventCurrency]="eventCurrency"
                                      [priceList]="priceList" [disableAddProductBtn]="disableAddProductBtn">
                                  </app-add-product-form>
                                  <app-products-table [priceList]="priceList" [eventCurrency]="eventCurrency"
                                      [productCategoryOptions]="productCategoryOptions"
                                      (isProductEditMood)="getProductEditMood($event)"></app-products-table>
                                </ng-template>
                            </ng-container>
                          </nav>
                          <div [ngbNavOutlet]="nav" class="mt-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
