<div [formGroup]="formGroup" class="form-group">
    <label class="control-label col-form-label-sm" *ngIf="label !== ''">{{label}}</label>
    <div class="input-group">
    <input type="{{inputType === 'password' ? showPassword ? 'text' : 'password' : inputType}}"
           class="form-control form-control-sm {{additionalClass}} {{error ? 'is-invalid' : ''}}"
           [formControlName]="formControlNameValue"
           name="{{name}}" id="{{id}}"
           (ngModelChange)="change()"
           placeholder="{{placeHolder}}"
           [required]="required"/>
           <div *ngIf="inputType === 'password'" class="input-group-append">
            <span class="input-group-text">
              <i class="fa" [ngClass]="{ 'fa-eye-slash': !showPassword, 'fa-eye': showPassword }" (click)="toggleVisibility()"></i>
            </span>
           </div>
    </div>
    <div class="invalid-feedback" [hidden]="!error">
        {{error | translate}}
    </div>
  </div>
