<nav class="navbar navbar-expand-lg navbar-dark bg-dark" style="padding: 10.4px; background-color: #57c6f2 !important;">
		<a class="navbar-brand" href="javascript:window.close();">Close</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
          aria-expanded="false" style="padding: 7px;">
          <img [src]="'/assets/flag/' + currentLanguage.flag +'.png'">
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown" style="min-width: 0px;">
          <a class="dropdown-item" *ngFor="let l of languages" (click)="changeLanguage(l)">
            <img [src]="'/assets/flag/' + l.flag +'.png'">
          </a>
        </div>
      </li>
    </ul>
  </div>
</nav>
<div class="jumbotron">
  <h1 class="display-4" style="text-align:center">{{'terms.header' | translate}}</h1>
  	<p class="lead" style="text-align:center;">{{'terms.p1' | translate}}</p>
    <p class="lead" style="text-align:center;">{{'terms.p2' | translate}}</p>
    <p class="lead" style="text-align:center;">{{'terms.p3' | translate}}</p>
    <p class="lead" style="text-align:center;">{{'terms.p4' | translate}}</p>
    <p class="lead" style="text-align:center;">{{'terms.p5' | translate}}</p>
    <p class="lead" style="text-align:center;">{{'terms.p6' | translate}}</p>
    <p class="lead" style="text-align:center;">{{'terms.p7' | translate}}</p>
    <p class="lead" style="text-align:center;">{{'terms.p8' | translate}}</p>
    <p class="lead" style="text-align:center;">{{'terms.p9' | translate}}</p>
    <p class="lead" style="text-align:center;">{{'terms.p10' | translate}}</p>
    <hr class="my-4">
</div>
