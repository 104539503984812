<div class="container mt-3" id="container">
    <div data-size="A4" id="container-print-deliverypaper">
        <div>
          <div class="row">
              <div class="col" id="title-container">
                  <h2>{{'event.delivery-paper.deliveryPaper' | translate}}</h2>
              </div>
          </div>
          <div class="row mt-3">
              <div class="col-6">
                  <div class="card border" style="height: 150px;">
                      <div class="card-body">
                          <strong class="text-uppercase">{{'event.delivery-paper.sender' | translate}}</strong>
                          <div class="card-text" [innerHTML]="'event.delivery-paper.addressSender' | translate">
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-6">
                  <div class="card border" style="height: 150px;">
                      <div class="card-body">
                          <strong class="text-uppercase">{{'event.delivery-paper.reciever' | translate}}</strong>
                          <div class="card-text">
                              {{event.clientUser.firstName}} {{event.clientUser.lastName}}<br />
                              {{event.clientUser.company.name}}
                              <br /> {{event.clientUser.company.address}} <br /> I -
                              {{event.clientUser.company.postalCode}}
                              <br /> {{event.clientUser.company?.village?.name}}
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="row mt-1 ">
              <div class="col">
                  <div class="card border" style="height: 70px;">
                      <div class="card-body">
                          <div class="row" style="margin-top: -5px;">
                              <div class="col">
                                  <strong
                                      class="text-uppercase">{{'event.delivery-paper.eventName' | translate}}:</strong>
                                  <div class="card-text">
                                      {{event.eventName}}
                                  </div>
                              </div>
                              <div class="col">
                                  <strong class="text-uppercase">{{'event.delivery-paper.date' | translate}}:</strong>
                                  <div class="card-text">
                                      {{event.startDate | date:'dd-MM-yyyy'}} / {{event.endDate | date:'dd-MM-yyyy'}}
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="row mt-3">
              <div class="col">
                  <table class="table table-bordered" style="margin-top: -12px">
                      <thead>
                          <tr>
                              <th class="text-uppercase text-center" style="width: 70%; color: #000000;">
                                  {{'event.delivery-paper.productDescription' | translate}}
                              </th>
                              <th class="text-uppercase text-center" style="width: 10%; color: #000000;">
                                  {{'event.delivery-paper.software' | translate}}
                              </th>
                              <th class="text-uppercase text-center" style="width: 10%; color: #000000">
                                  {{'event.delivery-paper.hardware' | translate}}
                              </th>
                              <th class="text-uppercase text-center" style="width: 10%; color: #000000">
                                  {{'event.delivery-paper.service' | translate}}
                              </th>
                          </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngIf="waitersCount > 0 || waiterSoftwareCount > 0">
                          <tr>
                            <td>{{'event.delivery-paper.waiter' | translate}}</td>
                            <td class="text-center">{{waiterSoftwareCount}}</td>
                            <td class="text-center">{{waitersCount}}</td>
                            <td class="text-center">-</td>
                          </tr>
                        </ng-container>
                        <ng-container *ngIf="waitersCount > 0">
                          <tr>
                            <td>{{'event.delivery-paper.phoneCharger' | translate}}</td>
                            <td class="text-center">-</td>
                            <td class="text-center">{{waitersCount}}</td>
                            <td class="text-center">-</td>
                          </tr>
                        </ng-container>
                        <ng-container *ngIf="cashRegisterCount > 0 || cashRegisterSoftwareCount > 0">
                          <tr>
                            <td>{{'event.delivery-paper.cashRegister' | translate}}</td>
                            <td class="text-center">{{cashRegisterSoftwareCount}}</td>
                            <td class="text-center">{{cashRegisterCount}}</td>
                            <td class="text-center">-</td>
                          </tr>
                        </ng-container>
                        <ng-container *ngIf="cashRegisterCount > 0 && cashRegisterPhone">
                          <tr>
                            <td>{{'event.delivery-paper.phoneCharger' | translate}}</td>
                            <td class="text-center">-</td>
                            <td class="text-center">{{cashRegisterCount}}"</td>
                            <td class="text-center">-</td>
                          </tr>
                        </ng-container>
                        <ng-container *ngIf="cashRegisterCount > 0 && !cashRegisterPhone">
                          <tr>
                            <td>{{'event.delivery-paper.tabletCharger' | translate}}</td>
                            <td class="text-center">-</td>
                            <td class="text-center">{{cashRegisterCount}}</td>
                            <td class="text-center">-</td>
                          </tr>
                        </ng-container>
                        <ng-container *ngIf="mainDeviceCount > 0 || mainDeviceSoftwareCount > 0">
                          <tr>
                            <td>{{'event.delivery-paper.mainDevice' | translate}}</td>
                            <td class="text-center">{{mainDeviceSoftwareCount}}</td>
                            <td class="text-center">{{mainDeviceCount}}</td>
                            <td class="text-center">-</td>
                          </tr>
                        </ng-container>
                        <ng-container *ngIf="mainDeviceCount > 0 && mainDevicePhone">
                          <tr>
                            <td>{{'event.delivery-paper.phoneCharger' | translate}}</td>
                            <td class="text-center">-</td>
                            <td class="text-center">{{mainDeviceCount}}</td>
                            <td class="text-center">-</td>
                          </tr>
                        </ng-container>
                        <ng-container *ngIf="mainDeviceCount > 0 && !mainDevicePhone">
                          <tr>
                            <td>{{'event.delivery-paper.tabletCharger' | translate}}</td>
                            <td class="text-center">-</td>
                            <td class="text-center">{{mainDeviceCount}}</td>
                            <td class="text-center">-</td>
                          </tr>
                        </ng-container>
                        <ng-container *ngIf="printerCount > 0 || printerSoftwareCount > 0">
                          <tr>
                            <td>{{'event.delivery-paper.printers' | translate}}</td>
                            <td class="text-center">{{printerSoftwareCount}}</td>
                            <td class="text-center">{{printerCount}}</td>
                            <td class="text-center">-</td>
                          </tr>
                        </ng-container>
                        <ng-container *ngIf="fiscalPrinterCount > 0 || fiscalPrinterSoftwareCount">
                          <tr>
                            <td>{{'event.delivery-paper.fiscalPrinter' | translate}}</td>
                            <td class="text-center">{{fiscalPrinterSoftwareCount}}</td>
                            <td class="text-center">{{fiscalPrinterCount}}</td>
                            <td class="text-center">-</td>
                          </tr>
                        </ng-container>
                        <ng-container *ngIf="printerCount > 0 || fiscalPrinterCount > 0">
                          <tr>
                            <td>{{'event.delivery-paper.phoneCharger' | translate}}</td>
                            <td class="text-center">-</td>
                            <td class="text-center">{{printerCount+fiscalPrinterCount}}</td>
                            <td class="text-center">-</td>
                          </tr>
                        </ng-container>
                        <ng-container>
                          <tr *ngFor="let cable of cablesAmount">
                            <td>{{'event.delivery-paper.cable' | translate}} {{cable.length}}m</td>
                            <td class="text-center">-</td>
                            <td class="text-center">{{cable.amount}}</td>
                            <td class="text-center">-</td>
                          </tr>
                        </ng-container>
                        <ng-container>
                          <tr *ngFor="let additionalCost of additionalCostsArray">
                            <ng-container *ngIf="additionalCost.id < 3 || additionalCost.id === 6 || additionalCost.id === 7 || additionalCost.id === 8 || additionalCost.id === 11">
                              <td>{{getadditionalCostLabel(additionalCost.name)}}</td>
                              <td class="text-center">-</td>
                              <td class="text-center">-</td>
                              <td class="text-center">{{additionalCost.quantityRequested}}</td>
                            </ng-container>
                            <ng-container *ngIf="additionalCost.id === 3 || additionalCost.id === 4 || additionalCost.id === 5 || additionalCost.id === 12">
                              <td>{{getadditionalCostLabel(additionalCost.name)}}</td>
                              <td class="text-center">-</td>
                              <td class="text-center">{{additionalCost.quantityRequested}}</td>
                              <td class="text-center">-</td>
                            </ng-container>
                            <ng-container *ngIf="additionalCost.id === 9 || additionalCost.id === 10">
                              <td>{{getadditionalCostLabel(additionalCost.name)}}</td>
                              <td class="text-center">{{additionalCost.quantityRequested}}</td>
                              <td class="text-center">-</td>
                              <td class="text-center">{{additionalCost.quantityRequested}}</td>
                            </ng-container>
                          </tr>
                        </ng-container>
                      </tbody>
                  </table>
              </div>
          </div>
          <div class="row">
              <div class="col">
                  <table class="table table-bordered">
                      <thead>
                          <th>{{'event.delivery-paper.routerName' | translate}}</th>
                          <th>{{'event.delivery-paper.networkName' | translate}}</th>
                          <th>{{'event.delivery-paper.networkPassword' | translate}}</th>
                          <th>{{'event.delivery-paper.routerType' | translate}}</th>
                      </thead>
                      <tbody>
                          <tr *ngFor="let router of event.routers"
                              style="page-break-inside: avoid; page-break-after: auto">
                              <td>{{router.name}}</td>
                              <td>{{router.networkName}}</td>
                              <td>{{router.networkPassword}}</td>
                              <td>{{(router.master === 0 ? 'event.delivery-paper.mainRouter' : 'event.delivery-paper.slaveRouter') | translate}}
                              </td>
                          </tr>
                      </tbody>
                  </table>
                  <table class="table table-bordered">
                      <thead>
                          <th>{{'event.delivery-paper.printerName' | translate}}</th>
                          <th>{{'event.delivery-paper.printerCategory' | translate}}</th>
                          <th>{{'event.delivery-paper.deviceName' | translate}}</th>
                      </thead>
                      <tbody>
                          <tr *ngFor="let printerCat of printersCategoriesDeliveryPaper">
                              <td>{{printerCat.printerName}}</td>
                              <td>{{printerCat.categoryName}}</td>
                              <td>{{printerCat.deviceName}}</td>
                          </tr>
                      </tbody>
                  </table>
                  <table class="table table-bordered">
                      <thead>
                          <th>{{'event.delivery-paper.name' | translate}}</th>
                          <th>{{'event.delivery-paper.rule' | translate}}</th>
                          <th>{{'event.delivery-paper.username' | translate}}</th>
                          <th>{{'event.delivery-paper.accessCode' | translate}}</th>
                          <th>{{'event.delivery-paper.lvl1Code' | translate}}</th>
                          <th>{{'event.delivery-paper.deviceName' | translate}}</th>
                      </thead>
                      <tbody>
                          <tr *ngFor="let deviceUser of event.deviceUsers"
                              style="page-break-inside: avoid; page-break-after: auto">
                              <td>{{deviceUser.name}}</td>
                              <td>{{deviceUser.deviceUserType.name | deviceTypeTranslation}}</td>
                              <td>{{deviceUser.username}}</td>
                              <td>{{deviceUser.pinCode}}</td>
                              <td>{{deviceUser.lvlOnePin}}</td>
                              <td>{{ (!deviceUser.device) ? 'N/D' : deviceUser.device.name }}</td>
                          </tr>
                      </tbody>
                  </table>
                  <blockquote class="blockquote mt-5">
                      <h4 class="mb-0 mt-0 text-center" [innerHTML]="'event.delivery-paper.warningMessage' | translate">
                      </h4>
                  </blockquote>
                  <blockquote class="blockquote mt-5">
                    <h4 class="mb-0 mt-0 text-center" [innerHTML]="'event.delivery-paper.videoQrCodeMessage' | translate"> </h4>
                    <img *ngIf="this.currentLanguage === 'de'" src="../../../../assets/img/qrcode_famulus_video_de.png" style="display:block; width:100px;height: auto; margin:0 auto;"/>
                    <img *ngIf="this.currentLanguage === 'it'" src="../../../../assets/img/qrcode_famulus_video_it.png" style="display:block; width:100px;height: auto; margin:0 auto;"/>
                </blockquote>
              </div>
          </div>
        </div>
    </div>
</div>
