import { Event } from './Event';
import { Ups } from './Ups';

export class RequestedUps {
    public id: number;
    public quantity: number;
    public parentRequestId: number;
    public events: Event;
    public ups: Ups;

    static fromDto(r): RequestedUps {
        const requestedUps = new RequestedUps();

        if (!r) {
            return null;
        }

        requestedUps.id = r.id;
        requestedUps.quantity = r.quantity;
        requestedUps.parentRequestId = r.parentRequestId;
        requestedUps.events = r.events;
        requestedUps.ups = r.ups;

        return r;
    }
}
