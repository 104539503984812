import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

export interface Option {
  value: any;
  label?: string;
  labelKey?: string;
}

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css']
})
export class SelectComponent implements OnInit, OnChanges {

  @Input()
  label: string;

  @Input()
  model: any;

  @Input()
  options: Option[] = [];

  @Input()
  disabled = false;

  @Output()
  modelChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled && this.disabled) {
      this.model = undefined;
      this.change();
    }
  }

  change() {
    this.modelChange.emit(this.model);
  }

}
