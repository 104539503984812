<kendo-grid
  kendoGridFocusable
  scrollable="none"
  [navigable]="true"
  [kendoGridBinding]="orderProducts"
  [kendoGridExpandDetailsBy]="expandDetailsBy"
  [(expandedDetailKeys)]="expandedDetailKeys"
  (cellClick)="onCellClick($event)"
>
  <kendo-grid-column field="name" title="{{'event.statistic.productNameLabel' | translate}}"></kendo-grid-column>
  <kendo-grid-column field="extraInfo" title="{{'event.statistic.extraInfo' | translate}}"></kendo-grid-column>
  <kendo-grid-column field="description" title="{{'event.statistic.notes' | translate}}"></kendo-grid-column>
  <kendo-grid-column field="price" title="{{'event.statistic.unitPrice' | translate}}" format="{0} {{eventCurrency}}"></kendo-grid-column>
  <kendo-grid-column field="freePaid" title="{{'event.statistic.freeQty' | translate}}"></kendo-grid-column>
  <kendo-grid-column field="quantity" title="{{'event.statistic.totalQty' | translate}}"></kendo-grid-column>
  <kendo-grid-column field="amount" title="{{'event.statistic.amountLabel' | translate}}" format="{0} {{eventCurrency}}"></kendo-grid-column>
  <ng-template kendoGridDetailTemplate let-dataItem [kendoGridDetailTemplateShowIf]="showOptionDetailsCondition">
    <app-order-options [orderOptions]="dataItem.orderOption" [eventCurrency]="eventCurrency"></app-order-options>
  </ng-template>
</kendo-grid>
