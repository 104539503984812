import { Province } from './Province';

export class Village {
    public id: number;
    public name: string;
    public province: Province;

    public static fromDto(v): Village {
        const village = new Village();
        if (!v) {
            return null;
        }

        village.id = v.id;
        village.name = v.name;
        village.province = Province.fromDto(v.province);

        return village;
    }
}
