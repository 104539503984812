import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { IDatePickerDirectiveConfig } from 'ng2-date-picker';

@Component({
  selector: 'app-data-filter-datepicker',
  templateUrl: './data-filter-datepicker.component.html',
  styleUrls: ['./data-filter-datepicker.component.css']
})
export class DataFilterDatepickerComponent implements OnInit, OnChanges {

  datePickerConfig: IDatePickerDirectiveConfig = {
    format: 'DD-MM-YYYY',
    firstDayOfWeek: 'mo'
  };

  @Input()
  label: string;

  @Input()
  model: any;

  @Input()
  disabled = false;

  @Output()
  modelChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled && this.disabled) {
      this.model = null;
      this.change();
    }
  }

  change() {
    this.modelChange.emit(this.model);
  }

}
