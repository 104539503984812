import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from '../auth.service';
import { NavigationService } from 'src/app/shared/navigation.service';
import { ActivatedRoute, Router as AgularRouter } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.css']
})
export class RecoveryComponent implements OnInit {

  public recoveryForm: FormGroup;
  public errorArray = new Array();
  public showRegisterSuccessMsg = false;
  public userEmail;

  constructor(private _router: AgularRouter, private _formBuilder: FormBuilder, private _authService: AuthService,
    private _translate: TranslateService, private _toastr: ToastrService) {
    this.recoveryForm = this._formBuilder.group({
      email: ['', [<any>Validators.required, <any>Validators.email]],
    });
  }

  ngOnInit() {
    if (this._authService.isAuthenticated()) {
      this._router.navigate(['/dashboard']);
    }
  }

  onRecovery() {
    if (this.recoveryForm.valid) {
      this.userEmail = this.recoveryForm.get('email').value;
      this._authService.recoverClientPassword(this.userEmail).subscribe({
        next: (result) => {
        }, error: (error) => {
          if (error.status === 200) {
            this._toastr.success(this._translate.instant('recover.recoverySuccessMessage'));
            this._router.navigate(['/login']);
          }
          if (error.status === 602) {
            this.errorArray['email'] = this._translate.instant('recover.emailNotFoundError');
          }
        }
      });
    }
  }
}
