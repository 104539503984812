import { PrinterType } from './PrinterType';

export class PrinterManufacturer {
    public id: number;
    public name: string;
    public printerTypes: PrinterType[] = [];

    static fromDto(p): PrinterManufacturer {
        const printerManufacturer = new PrinterManufacturer();

        if (!p) {
            return null;
        }

        printerManufacturer.id = p.id;
        printerManufacturer.name = p.name;
        printerManufacturer.printerTypes = p.printerTypes;

        return p;
    }
}
