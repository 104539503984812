import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../auth.service';
import { NavigationService } from '../../shared/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  constructor(private authService: AuthService, private navigation: NavigationService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isAuthenticated()) {
      return true;
    } else {
      this.navigation.login().go();
    }
    return false;
  }
}

export const AuthGuard = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(PermissionService).canActivate(next, state);
};
