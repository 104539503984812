<div class="row">
  <div class="col-auto">
    <div class="btn-group dropdown">
      <button type="button" class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
        aria-expanded="false">
        {{'price-list.manage.bulkActions' | translate}}
      </button>
      <div class="dropdown-menu">
        <!-- <button class="dropdown-item btn-sm" type="button" (click)="selectAllEventEmitter.emit()"><i class="fa fa-check-square"></i>
          Select all</button>
        <button class="dropdown-item btn-sm" type="button" (click)="unselectAllEventEmitter.emit()"><i class="fa fa-square"></i>
          Unselect all</button>
        <div class="dropdown-divider"></div> -->
        <button class="dropdown-item btn-sm" type="button"
          (click)="(productIdsArray.length > 0 ? hideChangeCategory = false : hideChangeCategory = true)"><i
            class="fa fa-edit"></i>
          {{'price-list.manage.changeCategorySelection' | translate}}</button>
        <button class="dropdown-item btn-sm" type="button" (click)="duplicateSelection()"><i class="fa fa-copy"></i>
          {{'price-list.manage.duplicateSelection' | translate}}</button>
        <div class="dropdown-divider"></div>
        <button class="dropdown-item btn-sm" type="button" (click)="deleteSelection()"><i class="fa fa-trash-alt"></i>
          {{'price-list.manage.deleteSelected' | translate}}</button>
      </div>
    </div>
  </div>
  <div class="col-auto" [hidden]="hideChangeCategory">
    <div class="input-group input-group-sm">
      <select class="form-control" [(ngModel)]="selectedCategory">
        <option *ngFor="let option of productCategoryOptions" [value]="option.value">{{option.label}}</option>
      </select>
      <div class="input-group-append" id="button-addon4">
        <button type="button" class="btn btn-primary btn-sm" placement="top"
          [ngbTooltip]="'price-list.manage.update' | translate" (click)="changeProductsCategory()"><i
            class="fa fa-check"></i></button>
        <button type="button" class="btn btn-danger btn-sm" (click)="hideChangeCategory = true" placement="top"
          [ngbTooltip]="'price-list.manage.cancel' | translate"><i class="fa fa-times"></i></button>
      </div>
    </div>
  </div>
</div>