<div class="container mt-3" id="container">
    <div class="row mb-3 d-print-none">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-2">
                            <button type="button" class="btn btn-primary btn-lg" (click)="printPriceListLayout()">
                                <i class="fa fa-print"></i><br />{{'price-list.preview.print' | translate}}
                            </button>
                        </div>
                        <div class="col text-center">

                        </div>
                        <div class="col-2 text-right">
                            <!-- <button type="button" class="btn btn-primary btn-lg" (click)="downloadPdfPriceListLayout()">
                                <i class="fa fa-file-pdf"></i><br />{{'price-list.preview.pdf' | translate}}
                            </button> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div data-size="A4" id="paper-container" #container class="card" style="padding-left: 34%;">
        <div id="main-container" class="card-body" style="width: 90mm; min-height: 200mm;">
            <div class="text-center">
                {{today.toString() | date:'d-MM-yyyy HH:mm:ss'}}
            </div>
            <h2 style="margin: 15px 0;">{{'price-list.preview.productList' | translate}}</h2>
            <ng-container *ngFor="let category of priceList.productCategories">
                <h3 style="margin: 0px;">{{category.name}}</h3>
                <ul class="list-unstyled" style="margin: 0px;">
                    <li *ngFor="let product of category.products">
                        <div class="row">
                            <div class="col-9">
                                <span>1 x {{product.name}} {{product.extraInfo}}</span>
                            </div>
                            <div class="col-3 text-right" style="font-size: 10px;">
                                {{product.price | currency: eventCurrency + ' '}}
                            </div>
                        </div>
                        <div class="row" *ngIf="product.productOptions.length > 0">
                            <div class="col">
                                <ul class="list-unstyled" style="margin: 0px;">
                                    <li *ngFor="let option of product.productOptions">
                                        <div class="row">
                                            <div class="col-9">
                                                <strong>
                                                    {{option.name}}
                                                </strong>
                                            </div>
                                            <div class="col-3 text-right" style="font-size: 10px;">
                                                {{option.price | currency: eventCurrency + ' '}}
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </ng-container>
            <div class="mt-3 text-center">
                <span>{{'price-list.preview.thanks' | translate}}</span> <br />
                <span>{{'price-list.preview.printedFrom' | translate}} www.famulus.app</span>
            </div>
        </div>
    </div>
</div>
