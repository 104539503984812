<kendo-grid
  [data]="orderOptions"
  [navigable]="true"
  scrollable="none"
  kendoGridFocusable
>
  <kendo-grid-column field="name" title="{{'event.statistic.option' | translate}}"></kendo-grid-column>
  <kendo-grid-column field="extraInfo" title="{{'event.statistic.extraInfo' | translate}}"></kendo-grid-column>
  <kendo-grid-column field="price" title="{{'event.statistic.unitPrice' | translate}}" format="{0} {{eventCurrency}}"></kendo-grid-column>
  <kendo-grid-column field="freePaid" title="{{'event.statistic.freeQty' | translate}}"></kendo-grid-column>
  <kendo-grid-column field="quantity" title="{{'event.statistic.totalQty' | translate}}"></kendo-grid-column>
  <kendo-grid-column field="amount" title="{{'event.statistic.amountLabel' | translate}}" format="{0} {{eventCurrency}}"></kendo-grid-column>
</kendo-grid>
