import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Page} from '../models/Page';
import {PaginationService} from '../pagination.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit, OnChanges {

  @Input()
  page = 1;

  @Input()
  pageSize = 10;

  @Input()
  lastPage = 1;

  @Output()
  pageChange = new EventEmitter<number>();

  pages: Page[] = [{ value: 1, canClick: true }];

  constructor(private paginationService: PaginationService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('lastPage') || changes.hasOwnProperty('pageSize')) {
      this.setPages();
    }
  }

  changePage(page: number) {
    this.page = page;
    this.pageChange.emit(page);
    this.setPages();
  }

  previousPage() {
    if (this.page <= 1) {
      return;
    }

    this.changePage(this.page - 1);
  }

  nextPage() {
    if (this.page >= this.lastPage) {
      return;
    }

    this.changePage(this.page + 1);
  }

  setPages() {
    this.pages = this.paginationService.getPages(this.page, this.lastPage);
  }


}
