import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'deviceTypeTranslation',
  pure: false
})
export class DeviceTypeTranslationPipe implements PipeTransform {
  constructor(private _translate: TranslateService) {
  }
  transform(value: string): any {
    switch (value.toLowerCase()) {
      case 'cash register':
        return this._translate.instant('event.delivery-paper.cashRegisters');
      case 'main device':
        return this._translate.instant('event.delivery-paper.mainDevices');
      default:
        return this._translate.instant('event.delivery-paper.waiters');
    }
  }

}
