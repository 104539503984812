import { Client } from './Client';
import { Country } from './Country';
import { Village } from './Village';
import { Printer } from './Printer';

export class Company {
    public id: number;
    public name: string;
    public firstEmail: string;
    public secondEmail: string;
    public firstPhone: string;
    public secondPhone: string;
    public fiscalCode: string;
    public vat: string;
    public pecEmail: string;
    public isdNumber: string;
    public address: string;
    public postalCode: string;
    public city: string;
    public district: string;
    public notes: string;
    public status: number;
    public clientUsers: Client[] = [];
    public country: Country;
    public village: Village;
    public printers: Printer[];

    static fromDto(c, existingCompany?: Company): Company {
        const company = new Company();

        if (!c) {
            return null;
        }

        if (existingCompany) {
            company.id = (c.id === undefined ? existingCompany.id : c.id);
            company.name = (c.name === undefined ? existingCompany.name : c.name);
            company.firstEmail = (c.firstEmail === undefined ? existingCompany.firstEmail : c.firstEmail);
            company.secondEmail = (c.secondEmail === undefined ? existingCompany.secondEmail : c.secondEmail);
            company.firstPhone = (c.firstPhone === undefined ? existingCompany.firstPhone : c.firstPhone);
            company.secondPhone = (c.secondPhone === undefined ? existingCompany.secondPhone : c.secondPhone);
            company.fiscalCode = (c.fiscalCode === undefined ? existingCompany.fiscalCode : c.fiscalCode);
            company.vat = (c.vat === undefined ? existingCompany.vat : c.vat);
            company.pecEmail = (c.pecEmail === undefined ? existingCompany.pecEmail : c.pecEmail);
            company.isdNumber = (c.isdNumber === undefined ? existingCompany.isdNumber : c.isdNumber);
            company.address = (c.address === undefined ? existingCompany.address : c.address);
            company.postalCode = (c.postalCode === undefined ? existingCompany.postalCode : c.postalCode);
            company.city = (c.city === undefined ? existingCompany.city : c.city);
            company.village = (c.village === undefined ? existingCompany.village : c.village);
            company.district = (c.district === undefined ? existingCompany.district : c.district);
            company.notes = (c.notes === undefined ? existingCompany.notes : c.notes);
            company.status = (c.status === undefined ? existingCompany.status : c.status);
            company.clientUsers = (c.clientUsers === undefined ? existingCompany.clientUsers : c.clientUsers);
            company.country = (c.country === undefined ? existingCompany.country : c.country);
        } else {
            company.id = c.id;
            company.name = c.name;
            company.firstEmail = c.firstEmail;
            company.secondEmail = c.secondEmail;
            company.firstPhone = c.firstPhone;
            company.secondPhone = c.secondPhone;
            company.fiscalCode = c.fiscalCode;
            company.vat = c.vat;
            company.pecEmail = c.pecEmail;
            company.isdNumber = c.isdNumber;
            company.address = c.address;
            company.postalCode = c.postalCode;
            company.city = c.city;
            company.district = c.district;
            company.notes = c.notes;
            company.status = c.status;
            if (c.clientUsers) {
                company.clientUsers = c.clientUsers.map(client => Client.fromDto(client));
            }
            company.country = Country.fromDto(c.country);
            company.village = Village.fromDto(c.village);
            if (c.printers) {
                company.printers = c.printers.map(printer => Printer.fromDto(printer));
            }
        }

        return company;
    }
}
