import { Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { EventService } from '../../shared/event.service';
import { Event } from 'src/app/shared/models/Event';
import { TranslateService } from '@ngx-translate/core';
import { PriceListService } from 'src/app/price-list/shared/price-list.service';
import { LOCAL_STORAGE } from 'src/app/core/constants';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AccountService } from 'src/app/account/shared/account.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { PriceList } from 'src/app/shared/models/PriceList';
import { saveAs } from 'file-saver';
import { Client } from 'src/app/shared/models/Client';
import { Router } from '@angular/router';

@Component({
  selector: 'app-price-list-event',
  templateUrl: './price-list-event.component.html',
  styleUrls: ['./price-list-event.component.css']
})
export class PriceListEventComponent implements OnInit {

  public event: Event = new Event();
  public priceListUploadError = undefined;
  public newPriceListToUpload: File = null;
  public priceListName: string;
  public uploadedPriceListName: string;
  public priceListNameError = '';
  public newPriceListNameError = '';
  public newPriceListEventError = '';
  public todaysDate = moment().format('YYYY-MM-DD');
  public demoPriceListaName: string;
  public mode = '';
  public allPriceLists: any[] = [];
  public priceLists: string[] = [];

  private _currentClientId: number;
  private _companyId: number;
  private _client: Client;
  private _selectedPriceListId: number;
  private _demoPriceListNames = { 'de': 'Preisliste.xlsx', 'it': 'Listino_Prezzi.xlsx', 'en': 'Pricelist.xlsx' };

  @ViewChild('confirmDuplicatePriceListDialog', { static: true })
  confirmDuplicatePriceListDialog;

  @ViewChild('confirmDeletePriceListDialog', { static: true })
  confirmDeletePriceListDialog;

  @ViewChild('priceListInput', { static: true })
  priceListInput: ElementRef;

  constructor(private _eventService: EventService, private _translate: TranslateService, private _priceListService: PriceListService,
    public jwtHelper: JwtHelperService, private _accountService: AccountService, private _toastr: ToastrService, private router: Router) { }

  ngOnInit() {
    this._eventService.updateCurrentActiveSubPage('pricelist');
    this.prepareDemoPriceListFile();
    this._eventService.currentEventObject.subscribe(eventResult => {
      if (eventResult) {
        this.event = eventResult;
        this.event.deviceUsers.forEach(deviceUser => {
          this.priceLists.push(deviceUser.priceList?.name);
        });
        const accessToken = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
        const tokenData = this.jwtHelper.decodeToken(accessToken).data;
        this._currentClientId = tokenData.id;
        this._accountService.getSpecificClientById(this._currentClientId).subscribe(result => {
          this._companyId = result.company.id;
          this._client = result;
          this._accountService.getPriceListsByCompanyId(result.company.id, true).subscribe(rsPriceLists => {
            this.allPriceLists = rsPriceLists;
            console.log('rsPriceLists', rsPriceLists);
          });
        }, error => {
          console.log(error);
        });
        console.log(this.event);
      }
    });
  }

  handlePriceListFileInput(event: any) {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    this.newPriceListToUpload = files.item(0);
    this.uploadedPriceListName = this.newPriceListToUpload.name;
  }

  uploadNewPriceListFile() {
    this.priceListNameError = '';
    this.priceListUploadError = undefined;
    if (this.newPriceListToUpload === null) {
      this.priceListNameError = this._translate.instant('price-list.dashboard.fileUploadError');
      return;
    }
    const priceListName = this.newPriceListToUpload.name.replace('.xlsx', '');
    this._priceListService.uploadNewPriceListToEvent(
      this.newPriceListToUpload, this._currentClientId.toString(), this._companyId.toString(), priceListName,
      this.event.id.toString()).subscribe(result => {
        this.event.priceLists.push(PriceList.fromDto(result));
        this._toastr.success(this._translate.instant('price-list.dashboard.priceListUploadSuccessMsg'));
        this._eventService.updateWarningMissingPriceList(false);
        this.priceListInput.nativeElement.value = '';
        this.newPriceListToUpload = null;
        this.uploadedPriceListName = undefined;
      }, error => {
        console.log(error);
        let msgError = '';
        if (error.status === 603) {
          msgError = this._translate.instant('price-list.dashboard.pricelistUploadErrorOne') + ' <strong>' +
            error.error.index + ' ' + this._translate.instant('price-list.dashboard.column') + ' ' + error.error.col + '</strong>';
        }
        if (error.status === 604) {
          msgError = this._translate.instant('price-list.dashboard.pricelistUploadErrorTwo') + ' <strong>' +
            error.error.index + ' ' + this._translate.instant('price-list.dashboard.column') + ' ' + error.error.col + '</strong>';
        }
        if (error.status === 605) {
          msgError = this._translate.instant('price-list.dashboard.pricelistUploadErrorThree') + ' <strong>' +
            error.error.index + ' ' + this._translate.instant('price-list.dashboard.column') + ' ' + error.error.col + '</strong>';
        }
        if (error.status === 606) {
          msgError = this._translate.instant('price-list.dashboard.pricelistUploadErrorFour') + ' <strong>' +
            error.error.index + ' ' + this._translate.instant('price-list.dashboard.column') + ' ' + error.error.col + '</strong>';
        }
        if (error.status === 607) {
          msgError = this._translate.instant('price-list.dashboard.pricelistUploadErrorFive') + ' <strong>' +
            error.error.index + ' ' + this._translate.instant('price-list.dashboard.column') + ' ' + error.error.col + '</strong>';
        }
        if (error.status === 608) {
          msgError = this._translate.instant('price-list.dashboard.pricelistUploadErrorSix') + ' <strong>' +
            error.error.index + ' ' + this._translate.instant('price-list.dashboard.column') + ' ' + error.error.col + '</strong>';
        }
        this.priceListUploadError = msgError;
        this.newPriceListToUpload = null;
        this.uploadedPriceListName = undefined;
      });
  }

  createNewPriceList() {
    this.newPriceListNameError = '';
    if (this.priceListName == null) {
      this.newPriceListNameError = this._translate.instant('price-list.dashboard.priceListNameError');
      return;
    }
    this._priceListService.insertPriceListWithEvent(this.priceListName, this._currentClientId,
      this.event.id.toString(), this._companyId.toString()).subscribe(result => {
        // this._navigation.editPriceList(result.id).go();
      }, error => {
        console.log(error);
        if (error.status === 606) {
          this.newPriceListNameError = this._translate.instant('price-list.dashboard.priceListNameAlreadyExist');
        }
      });
  }

  duplicatePriceListClickEvent(priceListId: number) {
    this._selectedPriceListId = priceListId;
    this.confirmDuplicatePriceListDialog.openDialog();
  }

  assignPriceListToEventClick(priceListId: number) {
    console.log('assignPriceListToEventClick_priceListId', priceListId);
    this._priceListService.assignPriceListToEvent(priceListId, this.event.id)
        .subscribe(result => {
          this.event.deviceUsers.forEach(user => {
            user.priceList = this.event.priceLists.filter(pl => pl.id === priceListId)[0];
          });
          this._toastr.success(this._translate.instant('price-list.dashboard.priceListAssignedSuccessMsg'));
          this.router.navigateByUrl('event/details/' + this.event.id + '/assign-printer-category');
        }, error => {
          console.log(error);
        });
  }

  getDuplicatePriceListDecision(decision: boolean) {
    if (decision) {
      this._priceListService.duplicatePriceList(this._selectedPriceListId, this._currentClientId, this._companyId, this.event.id)
        .subscribe(result => {
          this.event.priceLists.push(result);
          this._toastr.success(this._translate.instant('price-list.dashboard.priceListDuplicateSuccessMsg'));
        }, error => {
          console.log(error);
        });
    }
    this._selectedPriceListId = undefined;
  }

  showDeleteConfermationDialog(priceListId: number) {
    this._selectedPriceListId = priceListId;
    this.confirmDeletePriceListDialog.openDialog();
  }

  getDeletePriceListDecision(decision: boolean) {
    if (decision) {
      this._priceListService.deletePriceList(this._selectedPriceListId).subscribe(result => {
        if (this.event.priceLists.length === 1) {
          this.event.priceLists = [];
          this._eventService.updateWarningMissingPriceList(true);
        } else {
          const index = this.event.priceLists.findIndex(pricel => pricel.id === this._selectedPriceListId);
          this.event.priceLists.splice(index, 1);
        }
        this._selectedPriceListId = undefined;
      }, error => {
        console.log(error);
        this._selectedPriceListId = undefined;
      });
    }
  }

  prepareDemoPriceListFile() {
    this.demoPriceListaName = this._demoPriceListNames[this._translate.currentLang];
    this._translate.onLangChange.subscribe(result => {
      this.demoPriceListaName = this._demoPriceListNames[result.lang];
    });
  }

  changeMode(mode) {
    this.mode = mode;
  }

  exportPriceList(priceListId: number, priceListName: string) {
    priceListName = priceListName.replace('.xlsx', '').replace('.xls', '');
    this._accountService.exportPriceList(priceListId, this._client.language).subscribe(result => {
      saveAs(result, 'export_' + priceListName + '.xlsx');
    });
  }

  duplicatePriceList(priceListId: number) {
    this._priceListService.duplicatePriceList(priceListId, this._companyId, this._companyId, this.event.id)
        .subscribe(result => {
          this.event.priceLists.push(result);
          this._toastr.success(this._translate.instant('price-list.dashboard.priceListDuplicateSuccessMsg'));
          window.location.reload();
        }, error => {
          console.log(error);
        });
  }

  saveSettings() {
    let hasError = false;
    for (let i = 0; i < this.event.deviceUsers.length; i++) {
      if (this.priceLists[i]) {
        this.event.deviceUsers[i].priceList = this.event.priceLists.filter(priceList => priceList.name === this.priceLists[i])[0];
        this._eventService.updateDeviceUser(this.event.deviceUsers[i], this.event.id).subscribe(result => {
          hasError = false;
          this._eventService.insertPrintersCategories([], this.event.id.toString()).subscribe(result => {
            window.location.reload();
          })
        }, error => {
          hasError = true;
          console.log(error);
        });
      }
    }
    if (!hasError) {
      this._toastr.success('Price List assigned successfully');
    }
  }
}
