<div class="row">
    <div class="col">
        <div class="form-group">
            <label>{{'event.details.latitude' | translate}}</label>
            <input type="text" class="form-control form-control-sm" [(ngModel)]="latitude" (change)="onInputLatLngChange($event)"/>
        </div>
    </div>
    <div class="col">
        <div class="form-group">
            <label>{{'event.details.longitude' | translate}}</label>
            <input type="text" class="form-control form-control-sm" [(ngModel)]="longitude" (change)="onInputLatLngChange($event)"/>
        </div>
    </div>
</div>
<div class="row" *ngIf="!disableEdit">
    <div class="col text-right">
        <div class="form-group">
            <button type="button" [disabled]="disableEdit" class="btn btn-danger btn-sm mr-1"
                (click)="resetDefaultLocation()">{{'event.details.reset' | translate}}</button>
        </div>
    </div>
</div>
<div class="row">
    <div class="col">
        <div class="my-leaflet-map-container" style="height: 500px;" leaflet [leafletOptions]="options" [leafletLayers]="layers"
            (leafletClick)="onMapClick($event)">
        </div>
    </div>
</div>
