import { Injectable } from '@angular/core';
import { MessageService } from '@progress/kendo-angular-l10n';


const data = {
  en: {
    rtl: false,
    messages: {
      // standard message from kendo-ui
    },
  },
  it: {
    rtl: false,
    messages: {
      'kendo.grid.groupPanelEmpty': 'Trascina l\'header di una colonna e rilascialo qui per raggruppare secondo tale colonna',
      'kendo.grid.noRecords': 'Nessun record disponibile.',
      'kendo.grid.pagerFirstPage': 'Vai alla prima pagina',
      'kendo.grid.pagerPreviousPage': 'Vai alla pagina precedente',
      'kendo.grid.pagerNextPage': 'Vai alla prossima pagina',
      'kendo.grid.pagerLastPage': 'Vai all\'ultima pagina',
      'kendo.grid.pagerPage': 'Pagina',
      'kendo.grid.pagerOf': 'di',
      'kendo.grid.pagerItems': 'elementi',
      'kendo.grid.pagerItemsPerPage': 'elementi per pagina',
      'kendo.grid.pagerPageNumberInputTitle': 'Pagina Nr.',
      'kendo.grid.filter': 'Filter',
      'kendo.grid.filterEqOperator': 'È uguale a',
      'kendo.grid.filterNotEqOperator': 'Non è uguale a',
      'kendo.grid.filterIsNullOperator': 'È zero',
      'kendo.grid.filterIsNotNullOperator': 'Non è zero',
      'kendo.grid.filterIsEmptyOperator': 'È vuoto',
      'kendo.grid.filterIsNotEmptyOperator': 'Non è vuoto',
      'kendo.grid.filterStartsWithOperator': 'Inizia con',
      'kendo.grid.filterContainsOperator': 'Contiene',
      'kendo.grid.filterNotContainsOperator': 'Non contiene',
      'kendo.grid.filterEndsWithOperator': 'Finisce con',
      'kendo.grid.filterGteOperator': 'È più grande o uguale a',
      'kendo.grid.filterGtOperator': 'È più grande di',
      'kendo.grid.filterLteOperator': 'È più piccolo o uguale a',
      'kendo.grid.filterLtOperator': 'È più piccolo o uguale a',
      'kendo.grid.filterIsTrue': 'È vero',
      'kendo.grid.filterIsFalse': 'È falso',
      'kendo.grid.filterBooleanAll': '(Tutti)',
      'kendo.grid.filterAfterOrEqualOperator': 'È dopo o uguale a',
      'kendo.grid.filterAfterOperator': 'È dopo',
      'kendo.grid.filterBeforeOperator': 'È prima',
      'kendo.grid.filterBeforeOrEqualOperator': 'È prima o uguale a',
      'kendo.grid.filterFilterButton': 'Filtro',
      'kendo.grid.filterClearButton': 'Rimuovi',
      'kendo.grid.filterAndLogic': 'E',
      'kendo.grid.filterOrLogic': 'O',
      'kendo.grid.filterDateToggle': 'Mostra calendario',
      'kendo.grid.filterDateToday': 'Oggi',
      'kendo.grid.filterNumericDecrement': 'Decrementa',
      'kendo.grid.filterNumericIncrement': 'Aumenta',
      'kendo.grid.loading': 'Caricamento',
      'kendo.grid.sort': 'Ordina',
      'kendo.grid.columns': 'Colonne',
      'kendo.grid.lock': 'Blocca',
      'kendo.grid.unlock': 'Sblocca',
      'kendo.grid.stick': 'Stick',
      'kendo.grid.unstick': 'Unstick',
      'kendo.grid.setColumnPosition': 'Imposta posizione colonna',
      'kendo.grid.sortAscending': 'Ordina ascendente',
      'kendo.grid.sortDescending': 'Ordina discendente',
      'kendo.grid.columnsApply': 'Applica',
      'kendo.grid.columnsReset': 'Reset',
      'kendo.grid.sortable': 'Ordinabile',
      'kendo.grid.sortedAscending': 'Ordinato ascendente',
      'kendo.grid.sortedDescending': 'Ordinato discendente',
      'kendo.grid.sortedDefault': 'Non ordinato',
      'kendo.grid.filterInputLabel': '{columnName} Filtra',
      'kendo.grid.filterMenuTitle': '{columnName} Menu filtri',
      'kendo.grid.filterMenuOperatorsDropDownLabel': '{columnName} Operatori di filtro',
      'kendo.grid.filterMenuLogicDropDownLabel': '{columnName} Logica di filtro',
      'kendo.grid.columnMenu': '{columnName} Menu colonne',
      'kendo.grid.selectionCheckboxLabel': 'Seleziona riga',
      'kendo.grid.selectAllCheckboxLabel': 'Seleziona tutte le righe',
      'kendo.grid.pagerLabel': 'Navigazione pagine, pagina {currentPage} di {totalPages}',
      'kendo.grid.gridLabel': 'Dati griglia'
    },
  },
  de: {
    rtl: false,
    messages: {
      'kendo.grid.groupPanelEmpty': 'Ziehen Sie die Überschrift einer Spalte (Datum, Kategorie, Artikel...) auf dieses Feld und legen Sie sie hier ab um die Inhalte zu gruppieren.',
      'kendo.grid.noRecords': 'Kein Eintrag verfügbar.',
      'kendo.grid.pagerFirstPage': 'Zur ersten Seite gehen',
      'kendo.grid.pagerPreviousPage': 'Zur vorherigen Seite gehen',
      'kendo.grid.pagerNextPage': 'Zur nächsten Seite gehen',
      'kendo.grid.pagerLastPage': 'Zur letzten Seite gehen',
      'kendo.grid.pagerPage': 'Seite',
      'kendo.grid.pagerOf': 'von',
      'kendo.grid.pagerItems': 'Elemente',
      'kendo.grid.pagerItemsPerPage': 'Elemente pro Seite',
      'kendo.grid.pagerPageNumberInputTitle': 'Seite Nr.',
      'kendo.grid.filter': 'Filter',
      'kendo.grid.filterEqOperator': 'Gleich',
      'kendo.grid.filterNotEqOperator': 'Nicht gleich',
      'kendo.grid.filterIsNullOperator': 'Gleich Null',
      'kendo.grid.filterIsNotNullOperator': 'Nicht gleich Null',
      'kendo.grid.filterIsEmptyOperator': 'Leer',
      'kendo.grid.filterIsNotEmptyOperator': 'Nicht leer',
      'kendo.grid.filterStartsWithOperator': 'Beginnt mit',
      'kendo.grid.filterContainsOperator': 'Enthält',
      'kendo.grid.filterNotContainsOperator': 'Enthält nicht',
      'kendo.grid.filterEndsWithOperator': 'Endet mit',
      'kendo.grid.filterGteOperator': 'Größer oder gleich',
      'kendo.grid.filterGtOperator': 'Größer',
      'kendo.grid.filterLteOperator': 'Kleiner oder gleich',
      'kendo.grid.filterLtOperator': 'Kleiner',
      'kendo.grid.filterIsTrue': 'Wahr',
      'kendo.grid.filterIsFalse': 'Falsch',
      'kendo.grid.filterBooleanAll': '(Alle)',
      'kendo.grid.filterAfterOrEqualOperator': 'Nach oder gleich',
      'kendo.grid.filterAfterOperator': 'Nach',
      'kendo.grid.filterBeforeOperator': 'Vor',
      'kendo.grid.filterBeforeOrEqualOperator': 'Vor oder gleich',
      'kendo.grid.filterFilterButton': 'Filter',
      'kendo.grid.filterClearButton': 'Entfernen',
      'kendo.grid.filterAndLogic': 'Und',
      'kendo.grid.filterOrLogic': 'Oder',
      'kendo.grid.filterDateToggle': 'Kalender anzeigen',
      'kendo.grid.filterDateToday': 'Heute',
      'kendo.grid.filterNumericDecrement': 'Wertverminderung',
      'kendo.grid.filterNumericIncrement': 'Wertsteigerung',
      'kendo.grid.loading': 'Laden',
      'kendo.grid.sort': 'Sortieren',
      'kendo.grid.columns': 'Spalten',
      'kendo.grid.lock': 'Sperren',
      'kendo.grid.unlock': 'Entsperren',
      'kendo.grid.stick': 'Stick',
      'kendo.grid.unstick': 'Unstick',
      'kendo.grid.setColumnPosition': 'Position der Spalte festlegen',
      'kendo.grid.sortAscending': 'Aufsteigend sortieren',
      'kendo.grid.sortDescending': 'Absteigend sortieren',
      'kendo.grid.columnsApply': 'Absteigend sortieren',
      'kendo.grid.columnsReset': 'Reset',
      'kendo.grid.sortable': 'Sortierbar',
      'kendo.grid.sortedAscending': 'Aufsteigend sortiert',
      'kendo.grid.sortedDescending': 'Absteigend sortiert',
      'kendo.grid.sortedDefault': 'Nicht sortiert',
      'kendo.grid.filterInputLabel': '{columnName} Filtern',
      'kendo.grid.filterMenuTitle': '{columnName} Filter menu',
      'kendo.grid.filterMenuOperatorsDropDownLabel': '{columnName} Filter Menü Operatoren',
      'kendo.grid.filterMenuLogicDropDownLabel': '{columnName} Filter Menü Logik',
      'kendo.grid.columnMenu': '{columnName} Spalte Menü',
      'kendo.grid.selectionCheckboxLabel': 'Zeile auswählen',
      'kendo.grid.selectAllCheckboxLabel': 'Alle Zeilen auswählen',
      'kendo.grid.pagerLabel': 'Seitennavigation pagine, Seite  {currentPage} von {totalPages}',
      'kendo.grid.gridLabel': 'Tabelle Etikett'
    },
  },
};
@Injectable({
  providedIn: 'root'
})
export class CustomMessageService  extends MessageService {

  private localeId = 'it';

  public setLocaleId(localeId: string): string {
    return this.localeId = localeId;
  }

  public set language(value: string) {
    const lang = data[value];
    if (lang) {
      this.localeId = value;
      this.notify(lang.rtl);
    }
  }

  public get language(): string {
    return this.localeId;
  }

  public get messages(): any {
    const lang = data[this.localeId];
    if (lang) {
      return lang.messages;
    }
  }

  public get(key: string): string {
    return this.messages[key];
  }
}
