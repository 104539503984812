import { TurnOver } from './TurnOver';
import { TurnOverProduct } from './TurnOverProduct';
import { TurnOverOption } from './TurnOverOption';

export class TurnOverOrderDate {
    public id: number;
    public firstOrderAt: string;
    public lastOrderAt: string;
    public lastSyncOrderId: number;
    public ordersCount: number;
    public turnOver: TurnOver;
    public turnOverProduct: TurnOverProduct;
    public turnOverOptions: TurnOverOption;

    static fromDto(t): TurnOverOrderDate {
        const turnoverOrderDate = new TurnOverOrderDate();
        if (!t) {
            return null;
        }

        turnoverOrderDate.id = t.id;
        turnoverOrderDate.firstOrderAt = t.firstOrderAt;
        turnoverOrderDate.lastOrderAt = t.lastOrderAt;
        turnoverOrderDate.lastSyncOrderId = t.lastSyncOrderId;
        turnoverOrderDate.ordersCount = t.ordersCount;
        turnoverOrderDate.turnOver = TurnOver.fromDTo(t.turnOver);
        turnoverOrderDate.turnOverProduct = TurnOverProduct.fromDto(t.turnOverProduct);
        if (t.turnOverOptions) {
            turnoverOrderDate.turnOverOptions = t.turnOverOptions.map(option => TurnOverOption.fromDto(option));
        }

        return turnoverOrderDate;
    }
}
