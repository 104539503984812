import { Routes } from '@angular/router';
import { AuthGuard } from '../core/guards/auth.guard';
import { PriceListComponent } from './price-list.component';
import { PriceListDashboardComponent } from './price-list-dashboard/price-list-dashboard.component';
import { ManagePriceListComponent } from './manage-price-list/manage-price-list.component';
import { PriceListSummeryPaperComponent } from './price-list-summery-paper/price-list-summery-paper.component';
import { MyPricelistsComponent } from './my-pricelists/my-pricelists.component';

export const PRICELIST_ROUTES: Routes = [
  {
    path: 'price-list', component: PriceListComponent, canActivate: [AuthGuard], children: [
      { path: 'dashboard', component: PriceListDashboardComponent },
      { path: 'create', component: ManagePriceListComponent },
      { path: 'edit/:priceListId', component: ManagePriceListComponent },
      { path: 'preview/:priceListId', component: PriceListSummeryPaperComponent },
      { path: 'my-pricelists', component: MyPricelistsComponent },
      { path: '**', redirectTo: 'price-list/dashboard' },
    ]
  }
];
