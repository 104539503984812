<div class="wrapper">
  <div class="sidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div class="main-panel">
    <app-top-navbar title="{{'event.request.eventRequest' | translate}}"></app-top-navbar>
    <div class="main-content container-fluid">
      <div class="card">
        <div class="row">
          <div class="col-8">
            <div class="header">
              <h4 style="margin: 0;">{{'event.request.eventInformations' | translate}}</h4>
            </div>
            <div class="content">
              <form class="needs-validation">
                <div class="row">
                  <div class="col-6">
                    <app-simple-form-group-input [label]="'event.request.eventTitle' | translate"
                      [helpText]="'event.request.eventTitleHelpText' | translate" [errorText]="eventTitleError"
                      [(model)]="eventName"></app-simple-form-group-input>
                  </div>
                  <div class="col-3">
                    <app-form-group-datepicker [label]="'event.request.startDate' | translate"
                      [helpText]="'event.request.startDateHelpText' | translate" [errorText]="startDateError"
                      [(model)]="startDate" (modelChange)="onStartDateChange($event)">
                    </app-form-group-datepicker>
                  </div>
                  <div class="col-3">
                    <app-form-group-datepicker [label]="'event.request.endDate' | translate"
                      [helpText]="'event.request.endDateHelpText' | translate" [errorText]="endDateError"
                      [(model)]="endDate" (modelChange)="onEndDateChange($event)">
                    </app-form-group-datepicker>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="row">
                      <div class="col-6">
                        <app-form-group-datepicker [label]="'event.request.pickUpDate' | translate"
                          [helpText]="'event.request.pickupDateHelpText' | translate" [errorText]="pickUpError"
                          [(model)]="pickUpDate"></app-form-group-datepicker>
                      </div>
                      <div class="col-6">
                        <app-time-range-select [label]="'event.request.pickUpTime' | translate" [(model)]="pickUpTime">
                        </app-time-range-select>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="row">
                      <div class="col-6">
                        <app-form-group-datepicker [label]="'event.request.returnDate' | translate"
                          [helpText]="'event.request.returnDateHelpText' | translate" [errorText]="returnDateError"
                          [(model)]="returnDate"></app-form-group-datepicker>
                      </div>
                      <div class="col-6">
                        <app-time-range-select [label]="'event.request.returnTime' | translate" [(model)]="returnTime">
                        </app-time-range-select>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="header">
              <h4 style="margin: 0;">{{'event.request.softwares' | translate}}</h4>
            </div>
            <div class="content">
              <div class="row">
                <div class="col">
                  <app-form-group-number-input [label]="'event.request.waiters' | translate" [errorText]="softwareError"
                    [(model)]="waiterQuantity" (modelChange)="updateEventValues()">
                    <!--(modelChange)="phoneQuantity = waiterQuantity; updateEventValues()">-->
                  </app-form-group-number-input>
                </div>
                <div class="col">
                  <app-form-group-number-input [label]="'event.request.cashRegisters' | translate"
                    [errorText]="softwareError" [(model)]="cashRegisterQuantity" (modelChange)="updateEventValues()">
                    <!--(modelChange)="tabletQuantity = (mainDeviceQuantity + cashRegisterQuantity); updateEventValues()">-->
                  </app-form-group-number-input>
                </div>
                <div class="col">
                  <app-form-group-number-input [label]="'event.request.mainDevices' | translate"
                    [(model)]="mainDeviceQuantity" (modelChange)="updateEventValues()">
                    <!--(modelChange)="tabletQuantity = (mainDeviceQuantity + cashRegisterQuantity); updateEventValues()">-->
                  </app-form-group-number-input>
                </div>
                <div class="col">
                  <app-form-group-number-input [label]="'event.request.printers' | translate"
                    [errorText]="softwareError" [(model)]="printerSoftwareQuantity" (modelChange)="updateEventValues()">
                    <!--(modelChange)="printerQuantity = printerSoftwareQuantity; updateEventValues()">-->
                  </app-form-group-number-input>
                </div>
              </div>
              <div class="row" [hidden]="softwareError === ''">
                <div class="col">
                  <div class="invalid-feedback" style="display: block;">
                    {{softwareError | translate}}
                  </div>
                </div>
              </div>
            </div>
            <div class="header">
              <h4 style="margin: 0;">{{'event.request.hardwares' | translate}}</h4>
            </div>
            <div class="content">
              <div class="row">
                <div class="col">
                  <app-form-group-number-input [label]="'event.request.phones' | translate" [(model)]="phoneQuantity"
                    (modelChange)="updateEventValues()"></app-form-group-number-input>
                </div>
                <div class="col">
                  <app-form-group-number-input [label]="'event.request.tablets' | translate" [(model)]="tabletQuantity"
                    (modelChange)="updateEventValues()"></app-form-group-number-input>
                </div>
                <div class="col">
                  <app-form-group-number-input [label]="'event.request.printers' | translate"
                    [(model)]="printerQuantity" (modelChange)="updateEventValues()"></app-form-group-number-input>
                </div>
                <div class="col">
                  <app-form-group-number-input [label]="'event.request.routers' | translate" [(model)]="routerQuantity"
                    (modelChange)="updateEventValues()"></app-form-group-number-input>
                </div>
                <div class="col">
                  <app-form-group-number-input [label]="'event.request.ups' | translate" [(model)]="upsQuantity"
                    (modelChange)="updateEventValues()"></app-form-group-number-input>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <app-form-group-number-input [label]="'event.request.cable-1m' | translate"
                    [(model)]="cableQuantity.one" (modelChange)="updateEventValues()"></app-form-group-number-input>
                </div>
                <div class="col">
                  <app-form-group-number-input [label]="'event.request.cable-5m' | translate"
                    [(model)]="cableQuantity.five" (modelChange)="updateEventValues()"></app-form-group-number-input>
                </div>
                <div class="col">
                  <app-form-group-number-input [label]="'event.request.cable-10m' | translate"
                    [(model)]="cableQuantity.ten" (modelChange)="updateEventValues()"></app-form-group-number-input>
                </div>
                <div class="col">
                  <app-form-group-number-input [label]="'event.request.cable-20m' | translate"
                    [(model)]="cableQuantity.twenty" (modelChange)="updateEventValues()"></app-form-group-number-input>
                </div>
                <div class="col">
                  <app-form-group-number-input [label]="'event.request.cable-50m' | translate"
                    [(model)]="cableQuantity.fifty" (modelChange)="updateEventValues()"></app-form-group-number-input>
                </div>
              </div>
            </div>
            <div class="content">
              <blockquote class="blockquote">
                <h4 class="mb-0"><strong>{{'event.request.totalPrice' | translate}} {{totalPrice | currency:'€'}} +
                    {{'event.request.vat' | translate}}</strong></h4>
              </blockquote>
            </div>
            <div class="content">
              <button type="submit" class="btn btn-info btn-fill pull-right"
                (click)="submitNewEventRequest()">{{'event.request.request' | translate}}</button>
            </div>
          </div>
          <div class="col-4">
            <div class="content">
              <div class="card">
                <div class="card-body">
                  <h4>{{'event.request.softwares' | translate}}</h4>
                  <p><strong>{{'event.request.sidebar.waiter' | translate}}:</strong>
                    {{'event.request.sidebar.waiterDesc' | translate}}</p>
                  <p><strong>{{'event.request.sidebar.cashRegister' | translate}}:</strong>
                    {{'event.request.sidebar.cashRegisterDesc' | translate}}</p>
                  <p><strong>{{'event.request.sidebar.mainDevice' | translate}}:</strong>
                    {{'event.request.sidebar.mainDeviceDesc' | translate}}</p>
                  <p><strong>{{'event.request.sidebar.printers' | translate}}:</strong>
                    {{'event.request.sidebar.printersDesc' | translate}}</p>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <h4>{{'event.request.hardwares' | translate}}</h4>
                  <p>{{'event.request.sidebar.hardwareDesc' | translate}}</p>
                  <p><strong>{{'event.request.sidebar.smartphone' | translate}}:</strong>
                    {{'event.request.sidebar.smartphoneDesc' | translate}}</p>
                  <p><strong>{{'event.request.sidebar.tablets' | translate}}:</strong>
                    {{'event.request.sidebar.tabletsDesc' | translate}}</p>
                  <p><strong>{{'event.request.sidebar.printer' | translate}}:</strong>
                    {{'event.request.sidebar.printerDesc' | translate}}</p>
                  <p><strong>{{'event.request.sidebar.router' | translate}}:</strong>
                    {{'event.request.sidebar.routerDesc' | translate}}</p>
                  <p><strong>{{'event.request.sidebar.cables' | translate}}:</strong>
                    {{'event.request.sidebar.cablesDesc' | translate}}</p>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <p><strong>{{'event.request.sidebar.warningTitle' | translate}}</strong>
                    {{'event.request.sidebar.warningDesc' | translate}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #missingCompanyModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" style="margin: 0 auto;">
      {{'event.request.missingCompanyModalTitle' | translate}}</h4>
  </div>
  <div class="modal-body">
    <p class="text-center">
      {{'event.request.missingCompanyModalContent' | translate}}
    </p>
  </div>
  <div class="modal-footer">
    <a class="btn btn-outline-dark btn-block" href="/account">{{'event.request.missingCompanyButton' | translate}}</a>
  </div>
</ng-template>
