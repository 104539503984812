<div class="wrapper">
    <div class="sidebar">
        <app-sidebar></app-sidebar>
    </div>
    <div class="main-panel">
        <app-top-navbar title="{{'price-list.dashboard.priceListDashboard' | translate}}"></app-top-navbar>
        <div class="main-content container-fluid">
            <div class="row">
                <div class="col">
                    <div class="card">
                        <div class="row">
                            <div class="col-lg-6 col-sm-12 col-md-12">
                                <div class="card-body">
                                    <div class="card-title">
                                        <h4 style="margin-top: 0px;">
                                            {{'price-list.dashboard.uploadNewPriceList' | translate}}</h4>
                                    </div>
                                    <div class="card-text">
                                        <div class="row" *ngIf="priceListUploadError">
                                            <div class="col">
                                                <div class="alert alert-warning" role="alert">
                                                    {{priceListUploadError}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <app-simple-form-group-input
                                                    [label]="'price-list.dashboard.priceListName' | translate"
                                                    [errorText]="priceListNameError" placeHolder="Price List Name"
                                                    [disabled]="true" [(model)]="uploadPriceListName">
                                                </app-simple-form-group-input>
                                            </div>
                                            <div class="col">
                                                <app-simple-form-group-select
                                                    [label]="'price-list.dashboard.event' | translate"
                                                    [options]="unAssignedApprovedEventsArray"
                                                    [errorText]="priceListEventError" [(model)]="uploadPriceListEventId"
                                                    (modelChange)="getPriceListNameUpload(null)">
                                                </app-simple-form-group-select>
                                            </div>
                                        </div>
                                        <div class="form-group" style="margin: 0;">
                                            <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="customFile"
                                                    (change)="handlePriceListFileInput($event)">
                                                <label class="custom-file-label {{'shared.langCode' | translate}}" for="customFile"
                                                    data-browse="Bestand kiezen">{{'price-list.dashboard.uploadPriceList' | translate}}</label>
                                            </div>
                                            <small class="form-text text-muted">
                                                {{'price-list.dashboard.uploadPriceList' | translate}}
                                            </small>
                                        </div>
                                        <button class="float-right btn btn-primary btn-sm"
                                            (click)="uploadNewPriceListFile()">{{'price-list.dashboard.upload' | translate}}</button>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-12 col-md-12">
                                <div class="card-body">
                                    <div class="card-title">
                                        <h4 style="margin-top: 0px;">
                                            {{'price-list.dashboard.createNewPriceList' | translate}}</h4>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <app-simple-form-group-input
                                                [label]="'price-list.dashboard.priceListName' | translate"
                                                [errorText]="newPriceListNameError" [disabled]="false"
                                                [(model)]="priceListName"></app-simple-form-group-input>
                                        </div>
                                        <div class="col">
                                            <app-simple-form-group-select
                                                [label]="'price-list.dashboard.event' | translate"
                                                [options]="unAssignedApprovedEventsArray"
                                                [errorText]="newPriceListEventError" [(model)]="priceListEventId"
                                                (modelChange)="getPriceListNameNew(null)">
                                            </app-simple-form-group-select>
                                        </div>
                                    </div>
                                    <button class="float-right btn btn-primary btn-sm" (click)="createNewPriceList()">{{'price-list.dashboard.create'
                    | translate}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="card-title">
                                <h4 style="margin-top: 0px;">{{'price-list.dashboard.allYoutPriceLists' | translate}}
                                </h4>
                            </div>
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">{{'price-list.dashboard.priceListName' | translate}}</th>
                                            <th scope="col">{{'price-list.dashboard.createdAt' | translate}}</th>
                                            <th scope="col">{{'price-list.dashboard.associatedEvent' | translate}}</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container
                                            *ngIf="priceLists && priceLists.length > 0; else emptyPriceListsTemplate">
                                            <tr *ngFor="let priceList of priceLists">
                                                <td>{{priceList.name}}</td>
                                                <td>{{priceList.createdAt | date:'dd-MM-yyyy'}}</td>
                                                <td>{{ (priceList.event ? priceList.event.eventName : 'price-list.dashboard.notAssiciatedEvent' | translate) }}
                                                </td>
                                                <td class="text-right">
                                                    <div class="btn-group btn-group-sm" role="group">
                                                        <a href="price-list/summery/{{priceList.id}}" target="_blank"
                                                            class="btn btn-secondary"><i class="fa fa-eye"></i>
                                                            View</a>
                                                        <ng-container *ngIf="priceList.event; else elseTemplate">
                                                            <ng-container *ngIf="priceList.event.endDate > todaysDate">
                                                                <a href="/price-list/edit/{{priceList.id}}"
                                                                    class="btn btn-secondary" data-toggle="tooltip"
                                                                    title="Edit"><i class="fa fa-edit"></i></a>
                                                            </ng-container>
                                                            <button type="button" class="btn btn-secondary"
                                                                (click)="showDeleteConfermationDialog(priceList.id)"
                                                                data-toggle="tooltip" title="Delete"><i
                                                                    class="fa fa-trash"></i></button>
                                                        </ng-container>
                                                        <ng-template #elseTemplate>
                                                            <a href="/price-list/edit/{{priceList.id}}"
                                                                class="btn btn-secondary"><i class="fa fa-edit"></i></a>
                                                            <button type="button" class="btn btn-secondary"
                                                                (click)="showDeleteConfermationDialog(priceList.id)"><i
                                                                    class="fa fa-trash"></i></button>
                                                        </ng-template>
                                                        <button type="button" class="btn btn-link"
                                                            (click)="duplicatePriceListClickEvent(priceList.id)"
                                                            data-toggle="tooltip" title="Duplicate"><i
                                                                class="fa fa-copy"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                        <ng-template #emptyPriceListsTemplate>
                                            <tr>
                                                <td colspan="4" class="text-center">
                                                    {{'price-list.dashboard.noPriceListsErrorMsg' | translate}}
                                                </td>
                                            </tr>
                                        </ng-template>

                                    </tbody>
                                </table>
                                <div class="d-flex justify-content-between p-2">
                                    <ngb-pagination [collectionSize]="priceListCollectionSize" [(page)]="page"
                                        [pageSize]="pageSize">
                                    </ngb-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-confirm-dialog-model #confirmDuplicatePriceListDialog (getResult)="getDuplicatePriceListDecision($event)"
    [modalTitle]="'price-list.dashboard.duplicatePriceListWarningTitle' | translate"
    [modalContent]="'price-list.dashboard.duplicatePriceListWarningMsg' | translate"
    [denyText]="'price-list.dashboard.no' | translate" [acceptText]="'price-list.dashboard.yes' | translate">
</app-confirm-dialog-model>
<app-confirm-dialog-model #confirmDeletePriceListDialog (getResult)="getDeletePriceListDecision($event)"
    [modalTitle]="'price-list.dashboard.deletePriceListWarningTitle' | translate"
    [modalContent]="'price-list.dashboard.deletePriceListWarningMsg' | translate"
    [denyText]="'price-list.dashboard.no' | translate" [acceptText]="'price-list.dashboard.yes' | translate">
</app-confirm-dialog-model>
