<div class="row">
    <div class="col-12 data-table-header">
        <ng-content select="app-data-filters"></ng-content>

        <ng-content select="app-data-buttons"></ng-content>

    </div>
</div>

<div class="row">
    <div class="col-12">
        <div class="table-responsive">
            <table class="table table-sm text-left">
                <thead>
                    <tr>
                        <ng-template ngFor [ngForOf]="columns" let-column>
                            <th class="{{column.trAddidionalClass}}">{{column.translationKey | translate}}</th>
                        </ng-template>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let i of items" (click)="onTableRowClickEvent(i)">
                        <ng-template ngFor [ngForOf]="columns" let-column>
                            <td>
                                <app-data-table-column [template]="column.template" [additionalClass]="column.additionalClass" [item]="i"></app-data-table-column>
                            </td>
                        </ng-template>
                    </tr>
                    <tr *ngIf="items.length === 0">
                        <td [attr.colspan]="columns.length" class="force-text-center"><em [translate]="'data-table.no-result'"></em>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="table-footer">
                <span class="inner-space-left-10">
          <app-pagination [page]="page" [pageSize]="pageSize" [lastPage]="lastPage" (pageChange)="changePage($event)">
          </app-pagination>
        </span>
                <span class="inner-space-right-10" [translate]="'data-table.count'" [translateParams]="{ count: totalCount }"></span>
            </div>
        </div>

    </div>
</div>