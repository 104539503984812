import { Company } from './Company';

export class Country {
    public id: number;
    public name: string;
    public vat: string;
    public companies: Company[] = [];

    static fromDto(c): Country {
        const country = new Country();

        if (!c) {
            return null;
        }

        country.name = c.name;
        country.id = c.id;
        country.vat = c.vat;
        country.companies = c.companies;

        return c;
    }
}
