import { TurnOver } from './TurnOver';
import { TurnOverOrderDate } from './TurnOverOrderDate';

export class TurnOverProduct {
    public id: number;
    public quantity: number;
    public categoryId: number;
    public categoryName: string;
    public productId: number;
    public productName: string;
    public extraInfo: string;
    public price: number;
    public amount: number;
    public turnOver: TurnOver;
    public turnOverOrderDate: TurnOverOrderDate;

    public static fromDto(t): TurnOverProduct {
        const turnoverproduct = new TurnOverProduct();
        if (!t) {
            return null;
        }
        turnoverproduct.id = t.id;
        turnoverproduct.quantity = t.quantity;
        turnoverproduct.categoryId = t.categoryId;
        turnoverproduct.categoryName = t.categoryName;
        turnoverproduct.productId = t.productId;
        turnoverproduct.productName = t.productName;
        turnoverproduct.extraInfo = t.extraInfo;
        turnoverproduct.price = t.price;
        turnoverproduct.amount = t.amount;
        turnoverproduct.turnOver = TurnOver.fromDTo(t.turnOver);
        turnoverproduct.turnOverOrderDate = TurnOverOrderDate.fromDto(t.turnOverOrderDate);

        return turnoverproduct;
    }
}
