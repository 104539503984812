import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-data-filters',
  templateUrl: './data-filters.component.html',
  styleUrls: ['./data-filters.component.css']
})
export class DataFiltersComponent implements OnInit {

  @Input()
  opened = false;

  @Input()
  fullWidth = false;

  resetEmitter = new Subject<void>();
  resetObservable = this.resetEmitter.asObservable();

  applyEmitter = new Subject<void>();
  applyObservable = this.applyEmitter.asObservable();

  constructor() { }

  ngOnInit() {
  }

  showFilters() {
    this.opened = !this.opened;
  }

  reset() {
    this.resetEmitter.next();
  }

  apply() {
    console.log('testemit');
    this.applyEmitter.next();
  }

  getResetObservable() {
    return this.resetObservable;
  }

  getApplyObservable() {
    return this.applyObservable;
  }


}
