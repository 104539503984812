import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-filter-group',
  templateUrl: './data-filter-group.component.html',
  styleUrls: ['./data-filter-group.component.css']
})
export class DataFilterGroupComponent implements OnInit {

  @Input()
  spaceBetween = false;

  @Input()
  useAvailableWidth = false;

  constructor() { }

  ngOnInit() {
  }

}
