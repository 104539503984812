import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpUtils } from 'src/app/shared/http-utils';
import { PriceList } from 'src/app/shared/models/PriceList';
import { URL_BASE } from 'src/app/core/constants';
import { map } from 'rxjs/operators';
import { ProductCategory } from 'src/app/shared/models/ProductCategory';
import { Product } from 'src/app/shared/models/Product';
import { ProductOption } from 'src/app/shared/models/ProductOption';
import { Event } from 'src/app/shared/models/Event';

@Injectable({
  providedIn: 'root',
})
export class PriceListService {
  private _headers: any;

  constructor(private _http: HttpClient) {
    this._headers = HttpUtils.createHeaders();
  }

  /**
   * getParentPriceLists
   */
  public getParentPriceLists(
    clientId: number,
    companyId: number
  ): Observable<PriceList[]> {
    return this._http
      .get(URL_BASE + '/priceList/getParentPriceLists', {
        headers: this._headers,
        params: {
          clientId: clientId.toString(),
          companyId: companyId.toString(),
        },
      })
      .pipe(
        map((result: any[]) => result.map((res) => PriceList.fromDto(res)))
      );
  }

  /**
   * getPriceList
   */
  public getPriceList(
    priceListId: number,
    clientId: number
  ): Observable<PriceList> {
    return this._http
      .get(URL_BASE + '/priceList/get', {
        headers: this._headers,
        params: { id: priceListId.toString(), clientId: clientId.toString() },
      })
      .pipe(map((result: any[]) => PriceList.fromDto(result)));
  }
  /**
   * getPriceListById
   */

  public getPriceListById(
    priceListId: number,
    clientId: string
  ): Observable<PriceList> {
    return this._http
      .get(URL_BASE + '/priceList/getByIdAndClientUserId', {
        headers: this._headers,
        params: { id: priceListId.toString(), clientId },
      })
      .pipe(map((result: any[]) => PriceList.fromDto(result)));
  }

  /**
   * uploadNewPriceListToEvent
   */
  public uploadNewPriceListToEvent(
    fileToUpload: File,
    clientId: string,
    companyId: string,
    priceListName: string,
    eventId?: string
  ): Observable<any> {
    const formData = new FormData();
    formData.append('clientId', clientId);
    formData.append('companyId', companyId);
    formData.append('eventId', eventId);
    formData.append('priceListName', priceListName);
    formData.append('file', fileToUpload, fileToUpload.name);
    const headers = HttpUtils.createHeaders();
    return this._http
      .post(URL_BASE + '/priceList/upload', formData, { headers })
      .pipe(map((res: any) => res));
  }

  /**
   * insertPriceListWithEvent
   */
  public insertPriceListWithEvent(
    priceListName: string,
    clientId: number,
    eventId: string,
    companyId: string
  ): Observable<PriceList> {
    return this._http
      .put(
        URL_BASE + '/priceList/insertPriceListWithEvent',
        {
          name: priceListName,
          clientId: clientId.toString(),
          eventId,
          companyId: companyId,
        },
        { headers: this._headers }
      )
      .pipe(map((res: any) => PriceList.fromDto(res)));
  }

  /**
   * duplicatePriceList
   */
  public duplicatePriceList(
    priceListId: number,
    clientId: number,
    companyId: number,
    eventId?: number
  ): Observable<PriceList> {
    return this._http
      .post(
        URL_BASE + '/priceList/duplicate',
        { priceListId, clientId, companyId, eventId },
        { headers: this._headers }
      )
      .pipe(map((res: any) => PriceList.fromDto(res)));
  }

  /**
   * duplicatePriceList
   */
  public deletePriceList(priceListId: number): Observable<PriceList> {
    return this._http
      .delete(URL_BASE + '/priceList/delete', {
        headers: this._headers,
        params: { priceListId: priceListId.toString() },
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  /**
   * updateProductCategory
   */
  public updateProductCategory(
    productCategory: ProductCategory
  ): Observable<ProductCategory> {
    return this._http
      .put(
        URL_BASE + '/productCategory/update',
        { productCategoryObject: productCategory },
        { headers: this._headers }
      )
      .pipe(map((res: any) => ProductCategory.fromDto(res)));
  }

  /**
   * insertNewProductCategory
   */
  public insertNewProductCategory(
    productCategory: ProductCategory
  ): Observable<ProductCategory> {
    return this._http
      .put(
        URL_BASE + '/productCategory/insert',
        { productCategory: productCategory },
        { headers: this._headers }
      )
      .pipe(map((res: any) => ProductCategory.fromDto(res)));
  }

  /**
   * updatePriceList
   */
  public updatePriceList(id: number, name: string): Observable<PriceList> {
    return this._http
      .put(
        URL_BASE + '/priceList/update',
        { id, name },
        { headers: this._headers }
      )
      .pipe(map((res: any) => PriceList.fromDto(res)));
  }

  /**
   * changeProductCategoryPosition
   */
  public changeProductCategoryPosition(
    categoryId: number,
    itemOrder: number,
    priceListId: number
  ): Observable<ProductCategory[]> {
    return this._http
      .put(
        URL_BASE + '/productCategory/changeItemOrder',
        { categoryId, itemOrder, priceListId },
        { headers: this._headers }
      )
      .pipe(
        map((result: any[]) =>
          result.map((res) => ProductCategory.fromDto(res))
        )
      );
  }

  /**
   * deleteProductCategory
   */
  public deleteProductCategory(
    categoryId: number
  ): Observable<ProductCategory[]> {
    return this._http
      .delete(URL_BASE + '/productCategory/delete', {
        headers: this._headers,
        params: { id: categoryId.toString(), force: '1' },
      })
      .pipe(
        map((result: any[]) =>
          result.map((res) => ProductCategory.fromDto(res))
        )
      );
  }

  /**
   * duplicateProductCategory
   */
  public duplicateProductCategory(
    categoryId: number,
    priceListId: number
  ): Observable<ProductCategory[]> {
    return this._http
      .put(
        URL_BASE + '/productCategory/duplicate',
        { categoryId, priceListId },
        { headers: this._headers }
      )
      .pipe(
        map((result: any[]) =>
          result.map((res) => ProductCategory.fromDto(res))
        )
      );
  }

  /**
   * duplicateProduct
   */
  public duplicateProduct(productId: number): Observable<ProductCategory> {
    return this._http
      .put(
        URL_BASE + '/product/duplicate',
        { productId },
        { headers: this._headers }
      )
      .pipe(map((res: any) => ProductCategory.fromDto(res)));
  }

  /**
   * insertNewProduct
   */
  public insertNewProduct(product: Product): Observable<ProductCategory[]> {
    return this._http
      .put(
        URL_BASE + '/product/insert',
        { product: product },
        { headers: this._headers }
      )
      .pipe(
        map((result: any[]) =>
          result.map((res) => ProductCategory.fromDto(res))
        )
      );
  }

  /**
   * updateProduct
   */
  public updateProduct(product: Product): Observable<ProductCategory[]> {
    return this._http
      .put(
        URL_BASE + '/product/update',
        { product: product },
        { headers: this._headers }
      )
      .pipe(
        map((result: any[]) =>
          result.map((res) => ProductCategory.fromDto(res))
        )
      );
  }

  /**
   * deleteProduct
   */
  public deleteProduct(productId: number): Observable<ProductCategory> {
    return this._http
      .delete(URL_BASE + '/product/delete', {
        headers: this._headers,
        params: { id: productId.toString(), force: '1' },
      })
      .pipe(map((res: any) => ProductCategory.fromDto(res)));
  }

  /**
   * changeProductPosition
   */
  public changeProductPosition(
    productId: number,
    itemOrder: number,
    categoryId: number
  ): Observable<ProductCategory> {
    return this._http
      .put(
        URL_BASE + '/product/changeItemOrder',
        { productId, itemOrder, categoryId },
        { headers: this._headers }
      )
      .pipe(
        map((res: any) => {
          console.log(res);
          return ProductCategory.fromDto(res);
        })
      );
  }

  /**
   * insertNewProductOption
   */
  public insertNewProductOption(
    productOption: ProductOption
  ): Observable<Product> {
    return this._http
      .put(
        URL_BASE + '/productOption/insert',
        { productOption },
        { headers: this._headers }
      )
      .pipe(map((res: any) => Product.fromDto(res)));
  }

  /**
   * updateProductOption
   */
  public updateProductOption(
    productOption: ProductOption
  ): Observable<Product> {
    return this._http
      .put(
        URL_BASE + '/productOption/update',
        { productOption },
        { headers: this._headers }
      )
      .pipe(map((res: any) => Product.fromDto(res)));
  }

  /**
   * duplicateProductOption
   */
  public duplicateProductOption(productOptionId: number): Observable<Product> {
    return this._http
      .put(
        URL_BASE + '/productOption/duplicate',
        { productOptionId },
        { headers: this._headers }
      )
      .pipe(map((res: any) => Product.fromDto(res)));
  }

  /**
   * deleteProductOption
   */
  public deleteProductOption(productOptionId: number): Observable<any> {
    return this._http
      .delete(URL_BASE + '/productOption/delete', {
        headers: this._headers,
        params: { id: productOptionId.toString(), force: '1' },
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  /**
   * changeProductOptionItemOrder
   */
  public changeProductOptionItemOrder(
    productOptionId: number,
    itemOrder: number,
    productId: number
  ): Observable<Product> {
    return this._http
      .put(
        URL_BASE + '/productOption/changeItemOrder',
        { productOptionId, itemOrder, productId },
        { headers: this._headers }
      )
      .pipe(map((res: any) => Product.fromDto(res)));
  }

  /**
   * getUnAssignedApprovedEvents
   */
  public getUnAssignedApprovedEvents(companyId: number): Observable<Event[]> {
    return this._http
      .get(URL_BASE + '/event/getUnAssignedApprovedEvents', {
        headers: this._headers,
        params: { companyId: companyId.toString() },
      })
      .pipe(map((result: any[]) => result.map((res) => Event.fromDto(res))));
  }

  /**
   * assignPriceListToEvent
   */
  public assignPriceListToEvent(
    priceListId: number,
    eventId: number
  ): Observable<PriceList> {
    return this._http
      .put(
        URL_BASE + '/priceList/assignPriceList',
        { priceListId, eventId },
        { headers: this._headers }
      )
      .pipe(map((res: any) => PriceList.fromDto(res)));
  }

  /**
   * duplicateMultipleProducts
   */
  public duplicateMultipleProducts(
    products: Array<string>
  ): Observable<ProductCategory[]> {
    return this._http
      .put(
        URL_BASE + '/product/duplicates',
        { products },
        { headers: this._headers }
      )
      .pipe(
        map((result: any[]) =>
          result.map((res) => ProductCategory.fromDto(res))
        )
      );
  }

  /**
   * deleteMultipleProducts
   */
  public deleteMultipleProducts(
    products: Array<string>
  ): Observable<ProductCategory[]> {
    return this._http
      .request('delete', URL_BASE + '/product/deletes', {
        headers: this._headers,
        body: { products: { ids: products, force: 1 } },
      })
      .pipe(
        map((result: any[]) =>
          result.map((res) => ProductCategory.fromDto(res))
        )
      );
  }

  /**
   * changeMultipleProductsCategory
   */
  public changeMultipleProductsCategory(
    products: Array<string>,
    productCategory: ProductCategory
  ): Observable<ProductCategory[]> {
    console.log({ products: { ids: products, productCategory } });
    return this._http
      .put(
        URL_BASE + '/product/changeProductsCategory',
        { products: { ids: products, productCategory } },
        { headers: this._headers }
      )
      .pipe(
        map((result: any[]) =>
          result.map((res) => ProductCategory.fromDto(res))
        )
      );
  }

  public getPriceListsByEventId(eventId: string): Observable<PriceList[]> {
    const headers = HttpUtils.createHeaders();
    return this._http
      .get(URL_BASE + '/priceList/getByEventId', {
        headers: headers,
        params: { eventId },
      })
      .pipe(
        map((result: any[]) => result.map((res) => PriceList.fromDto(res)))
      );
  }
}
