<nav class="navbar navbar-expand-lg navbar-dark bg-dark" style="padding: 10.4px; background-color: #57c6f2 !important;">
  <a class="navbar-brand" href="javascript:window.close();">Close</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true"
          aria-expanded="false" style="padding: 7px;">
          <img [src]="'/assets/flag/' + currentLanguage.flag +'.png'">
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown" style="min-width: 0px;">
          <a class="dropdown-item" *ngFor="let l of languages" (click)="changeLanguage(l)">
            <img [src]="'/assets/flag/' + l.flag +'.png'">
          </a>
        </div>
      </li>
    </ul>
  </div>
</nav>
<div class="jumbotron">
    <h1 class="display-4" style="text-align:center">{{'privacy.title' | translate}}</h1>
    <h3 class="display-5">{{'privacy.h1' | translate}}</h3>
    <p class="lead">{{'privacy.p1' | translate}}</p>
    <hr class="my-4">

  <h3 class="display-5">{{'privacy.h2' | translate}}</h3>
    <p class="lead">{{'privacy.p2' | translate}}</p>
    <ul>
      <li>{{'privacy.ul1li1' | translate}}</li>
      <li>{{'privacy.ul1li2' | translate}}</li>
      <li>{{'privacy.ul1li3' | translate}}</li>
      <li>{{'privacy.ul1li4' | translate}}</li>
      <li>{{'privacy.ul1li5' | translate}}</li>
    </ul>
    <hr class="my-4">
    <p>{{'privacy.p3' | translate}}</p>

  <h3 class="display-5">{{'privacy.h3' | translate}}</h3>
    <p class="lead">{{'privacy.p4' | translate}}</p>
    <hr class="my-4">

  <h3 class="display-5">{{'privacy.h4' | translate}}</h3>
  <ul>
    <li>Google Analytics, Google-IP-Locator, Google Maps, Google Maps Distance Api Eigentümer: Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA</li>
    <li>YouTube-Inhalte, Eigentümer: Google Inc., Amphitheatre Parkway, Mountain View, CA 94043, USA.</li>
    <li>Soziales Netzwerk Facebook Eigentümer: Facebook Inc., Menlo Park, California, USA</li>
    <li>Soziales Netzwerk Google Plus Eigentümer: Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA</li>
    <li>Soziales Netzwerk Instagram, Eigentümer: Facebook Inc., Menlo Park, California, USA</li>
    <li>Soziales Netzwerk Twitter, Eigentümer: Twitter International Company, One Cumberland Place, Fenian Street, Dublin 2 D02 AX07, Irland</li>
    <li>Vimeo Eigentümer: Vimeo Inc., 555 West 18th Street, New York, New York 10011</li>
    <li>Juicer.io Eigentümer: Juicer.io, 1515 7th Street, #424, Santa Monica, CA 90403</li>
  </ul>
  <hr class="my-4">

  <h3 class="display-5">{{'privacy.h5' | translate}}</h3>
    <p class="lead">{{'privacy.p5' | translate}}</p>
    <hr class="my-4">

  <h3 class="display-5">{{'privacy.h6' | translate}}</h3>
    <p class="lead">{{'privacy.p6' | translate}}</p>
    <hr class="my-4">

  <h3 class="display-5">{{'privacy.h7' | translate}}</h3>
    <p class="lead">{{'privacy.p7' | translate}}</p>
    <hr class="my-4">

  <h3 class="display-5">{{'privacy.h8' | translate}}</h3>
    <p class="lead">{{'privacy.p8' | translate}}</p>
  <ul>
    <li>{{'privacy.ul3li1' | translate}}</li>
    <li>{{'privacy.ul3li2' | translate}}</li>
    <li>{{'privacy.ul3li3' | translate}}</li>
    <li>{{'privacy.ul3li4' | translate}}</li>
  </ul>
  <hr class="my-4">
</div>
