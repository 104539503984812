import { Product } from './Product';
import { Event } from './Event';
import { Client } from './Client';
import { ProductCategory } from './ProductCategory';
import { DeviceUser } from './DeviceUser';

export class PriceList {
    public id: number;
    public name: string;
    public createdAt: string;
    public status: number;
    public productCategories: ProductCategory[];
    public event: Event;
    public deviceUser: DeviceUser;
    public clientUser: Client = new Client();

    static fromDto(p): PriceList {
        const priceList = new PriceList();

        if (!p) {
            return null;
        }

        priceList.id = p.id;
        priceList.name = p.name;
        priceList.createdAt = p.createdAt;
        priceList.status = p.status;
        priceList.productCategories = p.productCategories;
        priceList.event = p.event;
        priceList.clientUser = p.clientUser;
        priceList.deviceUser = p.deviceUser;

        return priceList;
    }
}
