import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Option } from '../../shared/form-group-select/form-group-select.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PrinterManufacturer } from 'src/app/shared/models/PrinterManufacturers';
import { Printer } from 'src/app/shared/models/Printer';
import { PrinterType } from 'src/app/shared/models/PrinterType';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { PrintersService } from '../shared/printers.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@progress/kendo-angular-notification';
import { ValidationUtils } from 'src/app/shared/validation.utils';

@Component({
  selector: 'app-printers-form',
  templateUrl: './printers-form.component.html',
  styleUrls: ['./printers-form.component.css']
})
export class PrintersFormComponent implements OnInit {

  @ViewChild('notificationContainer', {read: ViewContainerRef})
  public notificationContainer: ViewContainerRef;

  @Input()
  typeOptions: Option[] = [];

  brandOptions: Option[] = [{value: 1, label: 'EPSON'}];

  nrPrinters = 1;
  steps: any[];
  currentStep = 0;
  forms: FormGroup[] = [];

  errorArray = new Array();

  constructor(private formBuilder: FormBuilder,
    private dialogRef: DialogRef,
    private printersService: PrintersService,
    private translateService: TranslateService,
    private notificationService: NotificationService) {
    this.generateSteps();
    this.generateForms();
  }

  private generateSteps() {
    this.steps = Array.from({length: this.nrPrinters}, (_, i) => ({
      label: `${i + 1}`
    }));
  }

  private generateForms() {
    const brand = new FormControl({value: this.brandOptions[0].value, disabled: true});
    this.forms = Array.from({length: this.nrPrinters}, () => {
      return this.formBuilder.group({
        brand: brand,
        type: new FormControl(),
        name: ['', Validators.required],
        macAddress: ['', Validators.required]
      });
    });
  }

  ngOnInit() {
  }

  onNrPrinterChange($event: Event) {
    const target = $event.target as HTMLInputElement;
    if (!target.value || target.value && isNaN(Number(target.value))) {
      return;
    }

    let currentNr = Number(target.value);

    // Force max number of printers: 4
    if (currentNr > 4) {
      currentNr = 4;
      alert(this.translateService.instant('printers.message.maxPrinters'));
    }

    // When printer number is increased
    if (this.nrPrinters < currentNr) {

      const newSteps = Array.from({length: currentNr - this.nrPrinters}, (_, i) => ({
        label: `${i + 1 + this.nrPrinters}`
      }));

      const brand = new FormControl({value: this.brandOptions[0].value, disabled: true});

      const newForms = Array.from({length: currentNr - this.nrPrinters}, () => {
        return this.formBuilder.group({
          brand: brand,
          type: new FormControl(),
          name: ['', Validators.required],
          macAddress: ['', Validators.required]
        });
      });

      for (let i = 0; i < newSteps.length; i++) {
        this.steps.push(newSteps[i]);
        this.forms.push(newForms[i]);
      }

    }

    // When printer number is decreased
    if (this.nrPrinters > currentNr) {
      for (let i = 0; i < this.nrPrinters - currentNr ; i++) {
        this.steps.splice(this.steps.length - 1);
        this.forms.splice(this.forms.length - 1);
      }
    }

    this.nrPrinters = currentNr;
    this.currentStep = 0;
  }

  onNextClick() {
    if (this.currentStep < this.nrPrinters - 1) {
      this.currentStep += 1;
    }
  }

  onBackClick() {
    if (this.currentStep > 0) {
      this.currentStep -= 1;
    }
  }

  public createPrinters() {
    let isValid = true;
    const specialChars = /[`!@#$%^&*()_\+=\[\]{};'"\\|,.<>\/?~ ]/;

    for (let i = 0; i < this.forms.length; i++) {
      if (!this.forms[i].valid || specialChars.test(this.forms[i].get('macAddress').value)) {
        isValid = false;
        if (i + 1 === this.forms.length) {
          this.errorArray = ValidationUtils.getFormValidationErrors(this.forms[i] , 'printers');
        }
      }
    }

    if (!isValid) {
      if (this.errorArray['macAdress'] !== null || this.errorArray['name'] !== null || this.errorArray['type'] !== null) {
        return;
      } else {
        return alert(this.translateService.instant('printers.message.addError'));
      }
    }

    const newPrinters = [];
    for (let i = 0; i < this.forms.length; i++) {
      const printerManufacturer = new PrinterManufacturer();
      printerManufacturer.id = 1; // EPSON
      const newPrinter = new Printer();
      newPrinter.printerType = new PrinterType();
      newPrinter.printerType.printerManufacturer = printerManufacturer;
      newPrinter.printerType.id = Number(this.forms[i].get('type').value);
      newPrinter.name = this.forms[i].get('name').value;
      newPrinter.identifier = this.forms[i].get('macAddress').value.replaceAll('-', ':');
      this.dialogRef.close();
      newPrinters.push(newPrinter);
    }

    this.printersService.insert(newPrinters).subscribe({
      next: () => {
        this.showNotification(this.translateService.instant('printers.message.added'), 'success');
        setTimeout(() => window.location.reload(), 1000);
      },
      error: () => {
        this.showNotification(this.translateService.instant('printers.message.addError'), 'error');
      },
    });

  }

  private showNotification(message: string, type: 'success' | 'error' | 'info' | 'warning') {
      this.notificationService.show({
        content: message,
        appendTo: this.notificationContainer,
        position: {horizontal: 'center', vertical: 'bottom'},
        type: {style: type, icon: false},
      });
    }

}
