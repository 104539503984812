<form [formGroup]="productForm" (ngSubmit)="onProductSubmit()" class="needs-validation mt-1 form-row">
    <div class="col-12 col-md-6 col-xl-2">
        <div class="form-group" [formGroup]="productForm">
            <label class="control-label col-form-label-sm">{{'price-list.manage.category' | translate}}</label>
            <select class="form-control form-control-sm {{errorArray['categoryId'] ? 'is-invalid' : ''}}" name="categoryId"
                id="categoryId" [required]="true" formControlName="categoryId">
                <option *ngFor="let category of productCategoryOptions" value="{{category.value}}">{{category.label}}</option>
            </select>
            <div class="invalid-feedback">
                {{errorArray['categoryId'] | translate}}
            </div>
        </div>
    </div>
    <div class="col-12 col-md-6 col-xl-2">
        <div class="form-group" [formGroup]="productForm">
            <label class="control-label col-form-label-sm">{{'price-list.manage.productName' | translate}}</label>
            <div class="input-group input-group-sm">
                <input type="text" class="form-control {{errorArray['name'] ? 'is-invalid' : ''}}" placeholder="{{'price-list.manage.productName' | translate}}"
                    name="name" id="name" [required]="true" formControlName="name" />
                <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2"> {{(productForm.controls['name'].value ?
                        (productForm.controls['name'].value.length + productForm.controls['extraInfo'].value.length) :
                        0)}} /
                        32</span>
                </div>
                <div class="invalid-feedback">
                    {{errorArray['name'] | translate}}
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-6 col-xl-2">
        <div class="form-group" [formGroup]="productForm">
            <label class="control-label col-form-label-sm">{{'price-list.manage.extraInfo' | translate}}</label>
            <div class="input-group input-group-sm">
                <input type="text" class="form-control {{errorArray['extraInfo'] ? 'is-invalid' : ''}}" placeholder="{{'price-list.manage.extraInfo' | translate}}"
                    name="extraInfo" id="extraInfo" formControlName="extraInfo">
                <div class="input-group-append">
                    <span class="input-group-text"> {{(productForm.controls['name'].value ?
                        (productForm.controls['name'].value.length + productForm.controls['extraInfo'].value.length) :
                        0)}} /
                        32</span>
                </div>
                <div class="invalid-feedback">
                    {{errorArray['extraInfo'] | translate}}
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-6 col-xl-2">
        <div class="form-group" [formGroup]="productForm">
            <label class="control-label col-form-label-sm">{{'price-list.manage.price' | translate}}</label>
            <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">€</span>
                </div>
                <input type="text" class="form-control {{errorArray['price'] ? 'is-invalid' : ''}}" placeholder="00.00"
                    name="price" id="price" [required]="true" formControlName="price" decimalCurrencyInput>
                <div class="invalid-feedback">
                    {{errorArray['price'] | translate}}
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-6 col-xl-2" *ngIf="priceList.event.useStock">
      <div class="form-group" [formGroup]="productForm">
          <label class="control-label col-form-label-sm">{{'price-list.manage.stock' | translate}}</label>
          <div class="input-group input-group-sm">
              <input type="number" class="form-control {{errorArray['stockPerDay'] ? 'is-invalid' : ''}}" placeholder="0"
                  name="stockPerDay" id="stockPerDay" [required]="true" formControlName="stockPerDay">
              <div class="invalid-feedback">
                  {{errorArray['stockPerDay'] | translate}}
              </div>
          </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-2 text-right">
        <button type="submit" class="btn btn-primary btn-sm" style="margin-top: 27px;" [disabled]="disableAddProductBtn">{{'price-list.manage.add' |
            translate}}</button>
    </div>
</form>
