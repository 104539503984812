<div class="row">
  <div class="col">
    <div class="jumbotron" style="padding-top: 0px; padding-bottom: 0px;">
      <h1 class="display-4">{{'event.statistic.pageTitle' | translate}}</h1>
      <p class="lead">{{'event.statistic.pageSubTitle' | translate}}</p>
      <hr class="my-4">
    </div>
  </div>
</div>

<div class="searchbar">
  <div class="title h4" style="color: #2ba8e3!important; font-weight:bold; text-align: center;">
    {{'event.statistic.filter' | translate}}
  </div>
  <div class="searchcontent">
    <div class="row">
      <div class="col-3">
        <div class="row" style="margin:0; min-height:95px; padding:5px 0 12px 0; background-color: #fff; border: 1px solid rgba(0,0,0,.125);">
          <div class="col-12 box-title" style="font-weight:bold; text-align:center; font-size: 15px; padding: 7px 10px">{{'event.statistic.selectionDateRange' | translate}}</div>
          <div class="col-12 input">
            <kendo-daterange>
              <kendo-dateinput
                kendoDateRangeStartInput
                autoCorrectOn="blur"
                [(value)]="selectionDateRange.start"
                [format]="'dd-MM-yyyy'"
                [min]="eventStartDate"
                [readonly]="true"
                style="width:46%; margin:0 2%;"
              ></kendo-dateinput>
              <kendo-dateinput
                kendoDateRangeEndInput
                autoCorrectOn="blur"
                [(value)]="selectionDateRange.end"
                [format]="'dd-MM-yyyy'"
                [max]="eventEndDate"
                [readonly]="true"
                style="width:46%; margin:0 2%;"
              ></kendo-dateinput>
              <kendo-daterange-popup
                [animate]="true"
              ></kendo-daterange-popup>
            </kendo-daterange>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="row" style="margin:0; min-height:95px; padding:5px 0 12px 0; background-color: #fff; border: 1px solid rgba(0,0,0,.125);">
          <div class="col-12 box-title" style="font-weight:bold; text-align:center; font-size: 15px; padding: 7px 10px">{{'event.statistic.selectPricelists' | translate}}</div>
          <div class="col-12 input">
            <kendo-dropdownlist
              [data]="allPriceLists"
              textField="name"
              valueField="id"
              [(ngModel)]="selectedPriceList"
            ></kendo-dropdownlist>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="row" style="margin:0; min-height:95px; padding:5px 0 12px 0; background-color: #fff; border: 1px solid rgba(0,0,0,.125);">
          <div class="col-12 box-title" style="font-weight:bold; text-align:center; font-size: 15px; padding: 7px 10px">{{'event.statistic.showChart' | translate}}</div>
          <div class="col-12 input" style="text-align:center">
            <kendo-switch
              [(ngModel)]="showChartChecked"
              onLabel= "{{'event.statistic.showChartYes' | translate}}"
              offLabel= "{{'event.statistic.showChartNo' | translate}}"
            ></kendo-switch>
          </div>
        </div>
      </div>
      <div class="col-3" *ngIf="showStatistic">
        <div class="row" style="margin:0; min-height:95px; padding:5px 0 12px 0; background-color: #fff; border: 1px solid rgba(0,0,0,.125);">
          <div class="col-12 box-title" style="font-weight:bold; text-align:center; font-size: 15px; padding: 7px 10px">{{'event.statistic.deleteDataSwitcherLabel' | translate}}</div>
          <div class="col-12 input" style="text-align:center">
            <button kendoButton (click)="onDeleteButtonClick()"
                    size="medium"
                    shape="rectangle"
                    rounded="medium"
                    fillMode="solid"
                    themeColor="error"
                    style="margin:0 10px"
            >{{'event.statistic.deleteDataSwitcherLabel' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="buttons row" style="margin:20px 0">
      <div class="col" style="text-align: center;">
        <button kendoButton (click)="onSearchButtonClick()"
                size="medium"
                shape="rectangle"
                rounded="medium"
                fillMode="solid"
                themeColor="primary"
                style="margin:0 10px"
        >{{'event.statistic.search' | translate}}</button>
        <button kendoButton (click)="onResetButtonClick()"
                size="medium"
                shape="rectangle"
                rounded="medium"
                fillMode="solid"
                themeColor="secondary"
                style="margin:0 10px"
        >{{'event.statistic.reset' | translate}}</button>
      </div>
    </div>
  </div>
</div>
<div class="row" style="margin:50px 0 0 0" *ngIf="showStatistic">
  <div class="col-12" *ngIf="showChartChecked">
    <!--Chart-->
    <kendo-chart>
      <kendo-chart-legend position="bottom" [padding]="15" [spacing]="30"></kendo-chart-legend>
      <kendo-chart-series>
        <kendo-chart-series-item
          type="pie"
          [data]="chartData"
          field="amount"
          [padding]="80"
          categoryField="categoryName"
          [labels]="{ visible: true, content: labelContent }"
        >
        </kendo-chart-series-item>
      </kendo-chart-series>
    </kendo-chart>
  </div>
  <div class="col-12">
    <div class="card-group">
      <div class="card border" style="margin:0 10px;">
        <div class="card-body text-center">
          <h5 class="card-title"><strong>{{'event.statistic.salesLabel' | translate}}</strong></h5>
          <p class="card-text form-text text-muted">{{  grandTotal }} {{eventCurrency}}</p>
        </div>
      </div>
      <div class="card border" style="margin:0 10px;">
        <div class="card-body text-center">
          <h5 class="card-title"><strong>{{'event.statistic.ordersLabel' | translate}}</strong></h5>
          <p class="card-text form-text text-muted">{{noOfOrders}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
<hr />
<div class="row mb-4" *ngIf="showStatistic">
  <div class="col">
    <kendo-grid
      [data]="gridData"
      [pageSize]="state.take"
      [skip]="state.skip"
      [sort]="state.sort"
      [filter]="state.filter"
      [group]="group"
      [sortable]="sortingSettings"
      [pageable]="{
        info: pagingSettings.info,
        type: pagingSettings.type,
        pageSizes: pagingSettings.pageSizes,
        previousNext: pagingSettings.previousNext,
        position: pagingSettings.position
      }"
      [reorderable]="false"
      [resizable]="false"
      filterable="menu"
      [columnMenu]="{ filter: true }"
      (dataStateChange)="dataStateChange($event)"
      [groupable]="false"
      (groupChange)="onGroupChange($event)"
    >
      <ng-template kendoGridToolbarTemplate>
        <kendo-grid-spacer></kendo-grid-spacer>
        <button kendoGridExcelCommand type="button" icon="file-excel">
          Export to Excel
        </button>
        <button kendoGridPDFCommand icon="file-pdf">Export to PDF</button>
      </ng-template>
      <kendo-grid-column field="firstOrderAtD" title="{{'event.statistic.firstOrderAtLabel' | translate}}" hidden="true">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.firstOrderAt}}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="lastOrderAtD" title="{{'event.statistic.lastOrderAtLabel' | translate}}" hidden="true">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.lastOrderAt}}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="dateD" title="{{'event.statistic.dateLabel' | translate}}" [filterable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.date}}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="categoryName" title="{{'event.statistic.categoryNameLabel' | translate}}"></kendo-grid-column>
      <kendo-grid-column field="productName" title="{{'event.statistic.productNameLabel' | translate}}"> </kendo-grid-column>
      <kendo-grid-column field="productPrice" title="{{'event.statistic.productPriceLabel' | translate}}" filter="numeric">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.productPrice.toFixed(2)}} {{eventCurrency}}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="quantity" title="{{'event.statistic.quantityLabel' | translate}}" filter="numeric">
        <ng-template kendoGridGroupFooterTemplate let-aggregates="aggregates" let-group="group">
        <span *ngIf="aggregates['quantity']">
          {{ aggregates["quantity"].sum }}
        </span>
        </ng-template>
        <ng-template kendoGridFooterTemplate let-column="column">
          <span class="cell sum total" *ngIf="total && total['quantity']">{{total["quantity"].sum}}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="amount" title="{{'event.statistic.amountLabel' | translate}}" filter="numeric">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.amount.toFixed(2)}} {{eventCurrency}}
        </ng-template>
        <ng-template kendoGridGroupFooterTemplate let-aggregates="aggregates" let-group="group" style="background-color: red;">
        <span *ngIf="aggregates['amount']">
          {{ aggregates["amount"].sum.toFixed(2) }} {{eventCurrency}}
        </span>
        </ng-template>
        <ng-template kendoGridFooterTemplate let-column="column">
          <span class="cell sum total" *ngIf="total && total['amount']">{{total["amount"].sum}} {{eventCurrency}}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-excel
        fileName="statistic.xlsx"
        [fetchData]="excelExportData">
      </kendo-grid-excel>
      <kendo-grid-pdf
        class="kendo-grid-pdf"
        fileName="statistic.pdf"
        [allPages]="true"
        paperSize="A4"
        [repeatHeaders]="true"
        [landscape]="true"
        [scale]="0.6"
      >
        <kendo-grid-pdf-margin
          top="1cm"
          left="0.5cm"
          right="0.5cm"
          bottom="1cm">
        </kendo-grid-pdf-margin>
        <ng-template
          class="pdf-template"
          kendoGridPDFTemplate
          let-pageNum="pageNum"
          let-totalPages="totalPages">
          <div class="page-template">
            <div class="footer">Page {{ pageNum }} of {{ totalPages }}</div>
          </div>
        </ng-template>
      </kendo-grid-pdf>
    </kendo-grid>
  </div>
</div>
<kendo-dialog
  title="{{'event.statistic.deleteDataDialogTitle' | translate}}"
  *ngIf="dialogOpenened"
  (close)="closeDialog(false)"
  [minWidth]="250"
  [width]="450"
>
  <p style="margin: 30px; text-align: center;">
    {{'event.statistic.deleteDataMessage' | translate}}
  </p>
  <kendo-dialog-actions>
    <button kendoButton (click)="closeDialog(false)">{{'event.statistic.deleteDataNo' | translate}}</button>
    <button kendoButton (click)="closeDialog(true)" themeColor="primary">{{'event.statistic.deleteDataYes' | translate}}</button>
  </kendo-dialog-actions>
</kendo-dialog>
