<div class="container">
    <div class="row">
        <div class="col-sm-9 col-md-7 col-lg-6 mx-auto">
            <div class="text-center mt-5 mb-3">
                <img class="logo" src="/assets/Famulus_logo_circle_new.png" alt="Famulus" height="100">
            </div>
            <div class="card card-signin">
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <form [formGroup]="registerForm" (ngSubmit)="onRegistration()" class="needs-validation">
                                <div class="card card-plain">
                                    <div class="content">
                                        <div class="row">
                                            <div class="col-4">
                                                <app-form-group-select name="title" id="title"
                                                    label="{{'register.title' | translate}} *"
                                                    formControlNameValue="title" [formGroup]="registerForm"
                                                    [error]="errorArray['title']" required="true" ngDefaultControl
                                                    [options]="titleOptions">
                                                </app-form-group-select>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <app-form-group-input inputType="text"
                                                    label="{{'register.firstName' | translate}} *" name="firstName"
                                                    id="firstName" placeHolder="{{'register.firstName' | translate}}"
                                                    formControlNameValue="firstName" [formGroup]="registerForm"
                                                    [error]="errorArray['firstName']" required="true" ngDefaultControl>
                                                </app-form-group-input>
                                                <div class="invalid" *ngIf="missingFirstNameErrorMsg" style="margin-top:-7px; margin-bottom:15px; padding-left: 5px;">{{missingFirstNameErrorMsg}}</div>
                                            </div>
                                            <div class="col">
                                                <app-form-group-input inputType="text"
                                                    label="{{'register.lastName' | translate}} *" name="lastName"
                                                    id="lastName" formControlNameValue="lastName"
                                                    [formGroup]="registerForm"
                                                    placeHolder="{{'register.lastName' | translate}}"
                                                    [error]="errorArray['lastName']" required="true" ngDefaultControl>
                                                </app-form-group-input>
                                                <div class="invalid" *ngIf="missingLastNameErrorMsg" style="margin-top:-7px; margin-bottom:15px; padding-left: 5px;">{{missingLastNameErrorMsg}}</div>
                                            </div>
                                        </div>
                                        <app-form-group-input inputType="email"
                                            label="{{'register.email' | translate}} *" name="firstEmail" id="firstEmail"
                                            placeHolder="{{'register.email' | translate}}" [required]="true"
                                            formControlNameValue="firstEmail" [error]="errorArray['firstEmail']"
                                            [formGroup]="registerForm" ngDefaultControl></app-form-group-input>
                                            <div class="invalid" *ngIf="missingEmailErrorMsg" style="margin-top:-7px; margin-bottom:15px; padding-left: 5px;">{{missingEmailErrorMsg}}</div>
                                        <div class="row">
                                          <div class="col">
                                            <app-form-group-input inputType="password" label="{{'reset.password' | translate}}"
                                                name="password" id="password" placeHolder="{{'reset.password' | translate}}"
                                                [required]="true" formControlNameValue="password" [error]="errorArray['password']"
                                                [formGroup]="registerForm" ngDefaultControl>
                                            </app-form-group-input>
                                            <div class="invalid" *ngIf="missingPasswordErrorMsg" style="margin-top:-7px; margin-bottom:15px; padding-left: 5px;">{{missingPasswordErrorMsg}}</div>
                                          </div>
                                          <div class="col">
                                            <app-form-group-input inputType="password" label="{{'reset.retypePassword' | translate}}"
                                                name="retypePassword" id="retypePassword"
                                                placeHolder="{{'reset.retypePassword' | translate}}" [required]="true"
                                                formControlNameValue="retypePassword" [error]="errorArray['retypePassword']"
                                                [formGroup]="registerForm" ngDefaultControl>
                                            </app-form-group-input>
                                            <div class="invalid" *ngIf="retypePasswordErrorMsg" style="margin-top:-7px; margin-bottom:15px; padding-left: 5px;">{{retypePasswordErrorMsg}}</div>
                                          </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <app-form-group-input inputType="text"
                                                    label="{{'register.phone' | translate}} *" name="firstPhone"
                                                    id="firstPhone" [error]="errorArray['firstPhone']"
                                                    placeHolder="{{'register.phone' | translate}}"
                                                    formControlNameValue="firstPhone" [formGroup]="registerForm"
                                                    ngDefaultControl>
                                                </app-form-group-input>
                                                <div class="invalid" *ngIf="missingPhoneNumberErrorMsg" style="margin-top:-7px; margin-bottom:15px; padding-left: 5px;">{{missingPhoneNumberErrorMsg}}</div>
                                            </div>
                                            <div class="col">
                                                <div class="form-group">
                                                    <app-form-group-select (change)="onLanguageChange($event.target)"
                                                        label="{{'register.languageCorrespondence' | translate}}"
                                                        name="language" id="language" formControlNameValue="language"
                                                        [options]="languageOptions" [formGroup]="registerForm"
                                                        ngDefaultControl></app-form-group-select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <app-form-group-input inputType="text"
                                                    label="{{'register.organization' | translate}} *" name="companyName"
                                                    id="companyName" [error]="companyErrorArray['name']"
                                                    placeHolder="{{'register.organization' | translate}}"
                                                    formControlNameValue="name" [formGroup]="companyRegisterForm"
                                                    ngDefaultControl>
                                                </app-form-group-input>
                                                <div class="invalid" *ngIf="missingOrganizationErrorMsg" style="margin-top:-7px; margin-bottom:15px; padding-left: 5px;">{{missingOrganizationErrorMsg}}</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div [class]="locationGroupClass">
                                                <app-select-location-group
                                                    (onVillageChosen)="chosenVillage = $event"
                                                    (onCountryChosen)="chosenCountry = $event">
                                                </app-select-location-group>
                                                <div class="invalid" *ngIf="missingVillageErrorMsg">{{missingVillageErrorMsg}}</div>
                                            </div>
                                        </div>
                                        <div [class]="surveyGroupClass">
                                          <div class="survey-title col-12"
                                            style="margin-top: 15px; padding:5px 0; text-align: center; background-color: #eff1f2; text-transform: uppercase; font-weight: bold;">
                                            {{'register.surveyQuestionLabel' | translate}}
                                          </div>
                                          <div class="survey-content col-12"
                                            style="margin-bottom: 15px; padding: 5px 50px;border:2px solid #eff1f2;">
                                            <div class="row" style="padding: 5px 0;" *ngFor="let survey of surveys">
                                              <input
                                                class="form-check-input {{(errorArray['survey'] ? 'is-invalid' : '')}}"
                                                type="radio" name="survey" id="survey"
                                                formControlName="survey" [formGroup]="registerForm" [value]="survey.id"
                                                ngDefaultControl (click)="onCheckBoxClick(survey.id)">
                                              <label class="form-check-label" for="survey" style="margin-top: 3px;">
                                                  {{survey.name}}
                                              </label>
                                              <input type="text" maxlength="200" name="description" formControlName="surveyDescription" [attr.disabled]="inputActive ? null : ''"
                                                [formGroup]="registerForm" [value]="registerForm.controls['surveyDescription'].value" *ngIf="survey.id === 7"
                                                style="margin: 0 10px; width:150px; border:2px solid #E0E0E0">
                                            </div>
                                          </div>
                                          <div class="invalid" *ngIf="missingSurveyErrorMsg" style="margin-top:-7px; margin-bottom:15px; padding-left: 5px;">{{missingSurveyErrorMsg}}</div>
                                        </div>
                                        <div class="form-check" style="margin-bottom: 1rem;">
                                            <input
                                                class="form-check-input {{(errorArray['termsConditionsCheck'] ? 'is-invalid' : '')}}"
                                                type="checkbox" name="termsConditionsCheck" id="termsConditionsCheck"
                                                formControlName="termsConditionsCheck" [formGroup]="registerForm"
                                                ngDefaultControl>
                                            <label class="form-check-label" for="termsConditionsCheck"
                                                style="margin-top: 3px;">
                                                {{'register.termsString1' | translate}}
                                                <a href="../../../assets/downloads/Privacy_{{this.currentLanguage}}.pdf" target="_blank" download="Privacy.pdf">
                                                  {{'register.termsString2' | translate}}
                                                </a>
                                                {{'register.termsString3' | translate}}
                                                <a href="../../../assets/downloads/AGB_Famulus_{{this.currentLanguage}}.pdf" target="_blank" download="AGBFamulus.pdf">
                                                  {{'register.termsString4' | translate}}
                                                </a>
                                                {{'register.termsString5' | translate}}.
                                            </label>
                                            <div class="invalid" *ngIf="missingTermsErrorMsg" style="margin-bottom:15px; padding-left: 5px;">{{missingTermsErrorMsg}}</div>
                                        </div>
                                        <!--
                                        <div class="form-check" style="margin-bottom: 1rem;">
                                            <input class="form-check-input {{(errorArray['termsConditionsCheckPoint4'] ? 'is-invalid' : '')}}"
                                            type="checkbox" name="termsConditionsCheckPoint4"
                                                id="termsConditionsCheckPoint4" formControlName="termsConditionsCheckPoint4" [formGroup]="registerForm"
                                                ngDefaultControl>
                                            <label class="form-check-label" for="termsConditionsCheckPoint4" style="margin-top: 3px;">
                                              {{'register.termsPoint4' | translate}}.
                                            </label>
                                            <div class="invalid-feedback">
                                              {{errorArray['termsConditionsCheckPoint4'] | translate}}
                                            </div>
                                        </div>
                                        <div class="form-check" style="margin-bottom: 1rem;">
                                            <input class="form-check-input {{(errorArray['termsConditionsCheckPoint6'] ? 'is-invalid' : '')}}"
                                            type="checkbox" name="termsConditionsCheckPoint6"
                                                id="termsConditionsCheckPoint6" formControlName="termsConditionsCheckPoint6" [formGroup]="registerForm"
                                                ngDefaultControl>
                                            <label class="form-check-label" for="termsConditionsCheckPoint6" style="margin-top: 3px;">
                                              {{'register.termsPoint6' | translate}}.
                                            </label>
                                            <div class="invalid-feedback">
                                              {{errorArray['termsConditionsCheckPoint6'] | translate}}
                                            </div>
                                        </div>
                                        <div class="form-check" style="margin-bottom: 1rem;">
                                            <input class="form-check-input {{(errorArray['termsConditionsCheckPoint9'] ? 'is-invalid' : '')}}"
                                            type="checkbox" name="termsConditionsCheckPoint9"
                                                id="termsConditionsCheckPoint9" formControlName="termsConditionsCheckPoint9" [formGroup]="registerForm"
                                                ngDefaultControl>
                                            <label class="form-check-label" for="termsConditionsCheckPoint9" style="margin-top: 3px;">
                                              {{'register.termsPoint9' | translate}}.
                                            </label>
                                            <div class="invalid-feedback">
                                              {{errorArray['termsConditionsCheckPoint9'] | translate}}
                                            </div>
                                        </div>
                                        -->
                                        <div class="form-check" style="margin-bottom: 1rem;">
                                            <input class="form-check-input" type="checkbox" name="newsletter"
                                                id="newsletter" formControlName="newsletter" [formGroup]="registerForm"
                                                ngDefaultControl>
                                            <label class="form-check-label" for="newsletter" style="margin-top: 3px;">
                                                Newsletter
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group text-center"><button class="btn btn-primary btn-sm btn-block"
                                            type="submit">{{'register.createAccountButton' | translate}}</button></div>
                                </div>
                            </form>
                            <div class="text-center">
                                <p class="text-muted text-center">
                                    <small>{{'register.existingAccountQuestion' | translate}}</small></p>
                            </div>
                            <a href="/login"
                                class="btn btn-secondery btn-signin btn-block btn-sm">{{'register.login' | translate}}</a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <p class="text-muted text-center mt-5" style="margin-bottom: 0px;"><small>Copyright
                                    RR-Solutions
                                    {{currentDate | date: 'yyyy'}}</small></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
