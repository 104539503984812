import { NgModule } from '@angular/core';
import { OffersComponent } from './offers.component';
import { OffersService } from './shared/offers.service';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { OFFERS_ROUTES } from './offers.routes';
import { RequestOfferComponent } from './request-offer/request-offer.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    RouterModule.forChild(OFFERS_ROUTES),
    SharedModule,
    CoreModule,
    NgSelectModule
  ],
  declarations: [
    OffersComponent,
    RequestOfferComponent
  ],
  providers: [
    OffersService
  ]
})
export class OffersModule { }
