<form [formGroup]="editProductForm" (ngSubmit)="onProductSubmit()" class="needs-validation mt-1 form-row">
    <div class="col">
        <div class="form-group" [formGroup]="editProductForm">
            <label class="control-label col-form-label-sm">{{'price-list.manage.category' | translate}}</label>
            <select class="form-control form-control-sm {{editErrorArray['categoryId'] ? 'is-invalid' : ''}}"
                name="categoryId" id="categoryId" [required]="true" formControlName="categoryId">
                <option *ngFor="let category of productCategoryOptions" value="{{category.value}}">{{category.label}}
                </option>
            </select>
            <div class="invalid-feedback">
                {{editErrorArray['categoryId'] | translate}}
            </div>
        </div>
    </div>
    <div class="col">
        <div class="form-group" [formGroup]="editProductForm">
            <label class="control-label col-form-label-sm">{{'price-list.manage.productName' | translate}}</label>
            <div class="input-group input-group-sm">
                <input type="text" class="form-control {{editErrorArray['name'] ? 'is-invalid' : ''}}"
                    placeholder="{{'price-list.manage.productName' | translate}}" name="name" id="name"
                    [required]="true" formControlName="name" />
                <div class="input-group-append">
                    <span class="input-group-text"> {{(editProductForm.controls['name'].value ?
                        (editProductForm.controls['name'].value.length + editProductForm.controls['extraInfo'].value.length) :
                        0)}} /
                        32</span>
                </div>
                <div class="invalid-feedback">
                    {{editErrorArray['name'] | translate}}
                </div>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="form-group" [formGroup]="editProductForm">
            <label class="control-label col-form-label-sm">{{'price-list.manage.extraInfo' | translate}}</label>
            <div class="input-group input-group-sm">
                <input type="text" class="form-control {{editErrorArray['extraInfo'] ? 'is-invalid' : ''}}"
                    placeholder="{{'price-list.manage.extraInfo' | translate}}" name="extraInfo" id="extraInfo"
                    formControlName="extraInfo">
                <div class="input-group-append">
                    <span class="input-group-text"> {{(editProductForm.controls['name'].value ?
                        (editProductForm.controls['name'].value.length + editProductForm.controls['extraInfo'].value.length) :
                        0)}} /
                        32</span>
                </div>
                <div class="invalid-feedback">
                    {{editErrorArray['extraInfo'] | translate}}
                </div>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="form-group" [formGroup]="editProductForm">
            <label class="control-label col-form-label-sm">{{'price-list.manage.price' | translate}}</label>
            <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">€</span>
                </div>
                <input type="text" class="form-control {{editErrorArray['price'] ? 'is-invalid' : ''}}"
                    placeholder="00.00" name="price" id="price" [required]="true" formControlName="price" decimalCurrencyInput>
                <div class="invalid-feedback">
                    {{editErrorArray['price'] | translate}}
                </div>
            </div>
        </div>
    </div>
    <div class="col" *ngIf="priceList.event.useStock">
        <div class="form-group" [formGroup]="editProductForm">
            <label class="control-label col-form-label-sm">{{'price-list.manage.stock' | translate}}</label>
            <div class="input-group input-group-sm">
                <input type="number" class="form-control"
                    placeholder="0" name="stockPerDay" id="stockPerDay" [required]="true" formControlName="stockPerDay">
            </div>
        </div>
    </div>
    <div class="col-auto text-right">
        <div class="btn-group btn-group-sm" role="group">
            <button type="submit" class="btn btn-primary btn-sm" style="margin-top: 27px;" placement="top"
                [ngbTooltip]="'price-list.manage.update' | translate"><i class="fa fa-check"></i></button>
            <button type="button" class="btn btn-danger btn-sm" style="margin-top: 27px;"
                (click)="cancelEditButtonClick()" placement="top"
                [ngbTooltip]="'price-list.manage.cancel' | translate"><i class="fa fa-times"></i></button>
        </div>
    </div>
</form>
