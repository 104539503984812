import { Event } from './Event';

export class OnlineEvent {
    public id: number;
    public eventNameDe: string;
    public eventNameIt: string;
    public eventDescriptionDe: string;
    public eventDescriptionIt: string;
    public startDate: string;
    public endDate: string;
    public latitude: string;
    public longitude: string;
    public onlinePublish: false | boolean;
    public pictureName: string;
    public startHour: string;
    public event: Event;

    public static fromDto(e): OnlineEvent {
        const onlineEvent = new OnlineEvent();
        if (!e) {
            return null;
        }

        onlineEvent.id = e.id;
        onlineEvent.eventNameDe = e.eventNameDe;
        onlineEvent.eventNameIt = e.eventNameIt;
        onlineEvent.eventDescriptionDe = e.eventDescriptionDe;
        onlineEvent.eventDescriptionIt = e.eventDescriptionIt;
        onlineEvent.startDate = e.startDate;
        onlineEvent.endDate = e.endDate;
        onlineEvent.latitude = e.latitude;
        onlineEvent.longitude = e.longitude;
        onlineEvent.onlinePublish = e.onlinePublish;
        onlineEvent.pictureName = e.pictureName;
        onlineEvent.startHour = e.startHour;
        onlineEvent.event = Event.fromDto(e.event);

        return onlineEvent;
    }
}
