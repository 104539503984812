import { RequestedUps } from './RequestedUps';

export class Ups {
    public id: number;
    public name: string;
    public quantity: number;
    public price: string;
    public status: number;
    public requestedUps: RequestedUps;

    static fromDto(u): Ups {
        const ups = new Ups();

        if (!u) {
            return null;
        }

        ups.id = u.id;
        ups.name = u.name;
        ups.quantity = u.quantity;
        ups.price = u.price;
        ups.status = u.status;
        ups.requestedUps = u.requestedUps;

        return ups;
    }
}
