import { environment } from '../../environments/environment';

export const URL_BASE = environment.URL_BASE;
export const URL_IMAGES = environment.URL_IMAGES;
export const COOKIE_DOMAIN = environment.COOKIE_DOMAIN;
// const { version: appVersion } = require('../../../package.json');
export const APP_VERSION = '4.5.8';

export const LOCAL_STORAGE = {
  ACCESS_TOKEN: 'access_token',
  FIRST_LOGIN: 'first_login'
};
