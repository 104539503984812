import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CORE_ROUTES } from './core.routes';
import { NavigationService } from '../shared/navigation.service';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { RegisterConfirmComponent } from './register/register-confirm/register-confirm.component';
import { TopNavbarComponent } from './top-navbar/top-navbar.component';
import { RecoveryComponent } from './recovery/recovery.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { ActivateLoginComponent } from './activate-login/activate-login.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(CORE_ROUTES),
    SharedModule,

  ],
  declarations: [
    SidebarComponent,
    DashboardComponent,
    LoginComponent,
    RegisterComponent,
    RegisterConfirmComponent,
    TopNavbarComponent,
    RecoveryComponent,
    ResetPasswordComponent,
    PrivacyComponent,
    TermsComponent,
    ActivateLoginComponent
  ],
  exports: [
    TopNavbarComponent,
    SidebarComponent
  ],
  providers: [NavigationService],
})
export class CoreModule {
}
