import { TurnOver } from './TurnOver';
import { TurnOverOrderDate } from './TurnOverOrderDate';

export class TurnOverOption {
    public id: number;
    public quantity: number;
    public optionId: number;
    public optionName: string;
    public extraInfo: string;
    public price: number;
    public amount: number;
    public turnOver: TurnOver;
    public turnOverOrderDate: TurnOverOrderDate;

    public static fromDto(t): TurnOverOption {
        const turnoveroption = new TurnOverOption();
        if (!t) {
            return null;
        }
        turnoveroption.id = t.id;
        turnoveroption.quantity = t.quantity;
        turnoveroption.optionId = t.optionId;
        turnoveroption.optionName = t.optionName;
        turnoveroption.extraInfo = t.extraInfo;
        turnoveroption.price = t.price;
        turnoveroption.amount = t.amount;
        turnoveroption.turnOver = TurnOver.fromDTo(t.turnOver);
        turnoveroption.turnOverOrderDate = TurnOverOrderDate.fromDto(t.turnOverOrderDate);

        return turnoveroption;
    }
}
