<div class="form-group">
  <label class="control-label col-form-label-sm" *ngIf="label !== ''">{{label}}</label>
  <select class="form-control form-control-sm {{additionalClass}} {{ (errorText !== '' ? 'is-invalid' : '') }}"
    [(ngModel)]="model" (change)="change()" [disabled]="disabled">
    <option *ngFor="let option of options" [value]="option.value">{{option.label}}</option>
  </select>
  <small class="form-text text-muted" [hidden]="helpText === ''">
    {{helpText}}
  </small>
  <div class="invalid-feedback" *ngIf="errorText !== ''">
    {{errorText | translate}}
  </div>
</div>