import { Component, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { AccountService } from 'src/app/account/shared/account.service';
import { LOCAL_STORAGE } from 'src/app/core/constants';
import { HttpUtils } from 'src/app/shared/http-utils';

@Component({
  selector: 'app-my-pricelists',
  templateUrl: './my-pricelists.component.html',
  styleUrls: ['./my-pricelists.component.css']
})
export class MyPricelistsComponent implements OnInit {

  public allPriceLists: any[] = [];

  private _currentClientId: number;
  private _companyId: number;

  constructor(public jwtHelper: JwtHelperService, private _accountService: AccountService) { }

  ngOnInit(): void {

    const accessToken = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
    const tokenData = this.jwtHelper.decodeToken(accessToken).data;
    this._currentClientId = tokenData.id;
    this._accountService.getSpecificClientById(this._currentClientId).subscribe(result => {
      this._companyId = result.company.id;
      this._accountService.getPriceListsByCompanyId(result.company.id, false).subscribe(rsPriceLists => {
        console.log(rsPriceLists);
        this.allPriceLists = rsPriceLists;
        this.allPriceLists.forEach(element => {
          if (element.event?.endDate) {
            element.event.endDate = moment(element.event.endDate).add(-1, 'days');
          }
        });
      });
    }, error => {
      console.log(error);
    });

  }

  exportPriceList(priceListId: number, priceListName: string) {
    priceListName = priceListName.replace('.xlsx', '').replace('.xls', '');
    this._accountService.exportPriceList(priceListId, 'de').subscribe(result => {
      saveAs(result, 'export_' + priceListName + '.xlsx');
    });
  }

}
