import { Country } from './Country';
import { Province } from './Province';

export class Region {
    public id: number;
    public name: string;
    public country: Country;
    public provinces: Province[];

    public static fromDto(r): Region {
        const region = new Region();
        if (!r) {
            return null;
        }

        region.id = r.id;
        region.name = r.name;
        region.country = Country.fromDto(r.country);
        if (r.provinces) {
            region.provinces = r.provinces.map(province => Province.fromDto(province));
        }

        return region;
    }
}
