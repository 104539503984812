import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ACCOUNT_ROUTES } from './account.routes';
import { SharedModule } from '../shared/shared.module';
import { AccountComponent } from './account.component';
import { AccountService } from './shared/account.service';
import { DetailsComponent } from './details/details.component';
import { CoreModule } from '../core/core.module';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    RouterModule.forChild(ACCOUNT_ROUTES),
    SharedModule,
    CoreModule,
    NgSelectModule
  ],
  declarations: [
    AccountComponent,
    DetailsComponent
  ],
  providers: [
    AccountService
  ]
})
export class AccountModule { }
