export class Survey {
    public id: number;
    public name: string;
    public nameIt: string;
    public nameDe: string;
    public nameEn: string;

    static fromDto(c): Survey {
        const survey = new Survey();

        if (!c) {
            return null;
        }

        survey.id = c.id;
        survey.name = c.name;
        survey.nameIt = c.nameIt;
        survey.nameDe = c.nameDe;
        survey.nameEn = c.nameEn;
        return c;
    }
}
