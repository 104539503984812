import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

export class Option {
  value: string;
  label: string;
}

@Component({
  selector: 'app-simple-form-group-select',
  templateUrl: './simple-form-group-select.component.html',
  styleUrls: ['./simple-form-group-select.component.css']
})
export class SimpleFormGroupSelectComponent implements OnInit {

  @Input()
  label: string;

  @Input()
  additionalClass: string;

  @Input()
  options: Option[];

  @Input()
  model: string;

  @Input()
  disabled = false;

  @Input()
  helpText = '';

  @Input()
  errorText = '';

  @Output()
  modelChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  change() {
    this.modelChange.emit(this.model);
  }


}
